import { Component, OnInit } from '@angular/core';
import {
  DashboardItemComponent,
  DashboardService,
} from 'proleis-web-dashboard';
import { Store } from '@ngrx/store';
import { ProleisForMobileState } from '../../../../store/store.index';
import { DashboardActions } from '../../../../store/dashboard/dashboard.index';
import { selectDashboardHeatmapData } from '../../../../store/dashboard/dashboard.selectors';
import { ProleisObject } from 'proleis-rest-client';

import * as Highcharts from 'highcharts';
import { Options } from 'highcharts';
import Heatmap from 'highcharts/modules/heatmap';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CapacityHeatmapOptionsComponent } from './capacity.heatmap.options.component';

Heatmap(Highcharts);

@Component({
  selector: 'p4m-dashboard-capacity-heatmap',
  templateUrl: 'capacity.heatmap.component.html',
})
export class CapacityHeatmapComponent
  extends DashboardItemComponent
  implements OnInit
{
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Options;
  data: ProleisObject[];

  constructor(
    private store: Store<ProleisForMobileState>,
    public ds: DashboardService,
    private bottomSheet: MatBottomSheet,
  ) {
    super();
  }

  ngOnInit(): void {
    const date = new Date();
    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    this.store.dispatch(
      DashboardActions.loadCapacityHeatmap({
        calendarId: '732355',
        startDate: start,
        endDate: end,
        excludeFinished: this.item.options.excludeFinished,
        includePR: this.item.options.includePR,
        includeVP: this.item.options.includeVP,
        // filter ids werden scheinbar aktuell im report noch nicht ausgewertet
        filterIds: '',
        groupField: 'RES_PLAN_ID',
        timeUnit: '853524',
        onlyCapacityActive: this.item.options.onlyActive,
        queryParentId: '241',
      }),
    );

    this.store.select(selectDashboardHeatmapData).subscribe((data) => {
      if (data) {
        this.data = data;
        this.buildChart(data);
      }
    });
  }

  buildChart(reportData: ProleisObject[]) {
    const timeLabels = [...new Set(reportData.map((value) => value.TIMELABEL))];
    const groupLabels = [...new Set(reportData.map((value) => value.GROUP))];

    const chartData = [];
    reportData.forEach((reportValue) => {
      const vals: number[] = [
        timeLabels.indexOf(reportValue.TIMELABEL),
        groupLabels.indexOf(reportValue.GROUP),
        reportValue.WORK > 0 ? Math.round(Number(reportValue.WORK)) : 0,
      ];
      chartData.push(vals);
    });

    this.chartOptions = {
      title: {
        text: null,
      },
      xAxis: {
        categories: timeLabels,
      },
      yAxis: {
        categories: groupLabels,
        title: null,
      },
      colorAxis: {
        min: 1,
        minColor: '#ffffff',
        maxColor: '#b71c1c',
      },
      series: [
        {
          type: 'heatmap',
          name: 'Auslastung in Stunden',
          borderWidth: 1,
          data: chartData,
          dataLabels: {
            enabled: true,
            color: '#000000',
          },
        },
      ],
      credits: {
        enabled: false,
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 400,
            },
            chartOptions: {
              yAxis: {
                labels: {
                  formatter() {
                    return String(this.value).substring(0, 3);
                  },
                },
              },
            },
          },
        ],
      },
    };
  }

  openOptionsBottomSheet() {
    this.bottomSheet.open(CapacityHeatmapOptionsComponent, {
      data: {
        item: this.item,
        dashboard: this.item.dashboardName,
      },
    });
  }

  delete() {
    this.ds.removeDashboardItem(this.item.dashboardName, this.item);
  }
}
