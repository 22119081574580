import { Component, Inject, ViewChild } from '@angular/core';
import {
  DashboardItemOptionsComponent,
  DashboardService,
} from 'proleis-web-dashboard';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { ProleisObject } from 'proleis-rest-client';
import { GUID_TYPE_PSE } from '../../../../../p4m/guids';

@Component({
  selector: 'p4m-dashboard-delivery-info-bottomsheet',
  templateUrl: 'dashboard.delivery.info.options.html',
})
export class DashboardDeliveryInfoOptionsComponent {
  @ViewChild(DashboardItemOptionsComponent, { static: false })
  itemOptions: DashboardItemOptionsComponent;

  allowedTypes: string[] = [GUID_TYPE_PSE];
  selectedObject: ProleisObject;

  constructor(
    public ds: DashboardService,
    private bottomSheetRef: MatBottomSheetRef,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data,
  ) {}

  dismiss() {
    this.bottomSheetRef.dismiss();
  }

  update() {
    this.ds.editDashboardItem(this.data.item.dashboardName, {
      itemSize: this.itemOptions.selectedItemSize,
      itemName: this.itemOptions.selectedName,
      dashboardName: this.data.item.dashboardName,
      componentTypeName: this.itemOptions.selectedComponentType,
      options: {
        pseId: this.selectedObject.OBJECT_ID,
      },
    });
  }

  onSelectionChanged(value: ProleisObject) {
    this.selectedObject = value;
  }
}
