<pw-view-title>{{ pseViewModel?.pse?.TEXT }}</pw-view-title>

<div class="p4m-grid-3-col">
  <div pwInViewport (inViewportChange)="onVisible($event)">
    <mat-card>
      <mat-card-header>
        <mat-card-title>PSE</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <section>
          <mat-form-field>
            <input
              matInput
              placeholder="Name"
              value="{{ pseViewModel?.pse?.NAME }}" />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="Version"
              value="{{ pseViewModel?.pse?.VERSIONNR }}" />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="Beschreibung"
              value="{{ pseViewModel?.pse?.TEXT }}" />
          </mat-form-field>
          <mat-form-field>
            <mat-icon matPrefix
              ><img src="{{ pseViewModel?.pseType?.ICON_URL }}"
            /></mat-icon>
            <input
              matInput
              placeholder="Projektstatus"
              value="{{ pseViewModel?.pseType?.TEXT }}" />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="Erstellt"
              value="{{ pseViewModel?.pse?.DATUMERSTELLUNG | date }}" />
          </mat-form-field>
          <mat-form-field>
            <mat-icon matPrefix
              ><img src="{{ pseViewModel?.projectState?.ICON_URL }}"
            /></mat-icon>
            <input
              matInput
              placeholder="Projektstatus"
              value="{{ pseViewModel?.projectState?.TEXT }}" />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="Planungsstatus"
              value="{{ pseViewModel?.planState?.TEXT }}" />
          </mat-form-field>
          <mat-form-field>
            <textarea
              matInput
              placeholder="Bemerkung"
              value="{{ pseViewModel?.pse?.BEMERKUNG }}"></textarea>
          </mat-form-field>
        </section>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="p4m-grid-4-col">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Übersicht Stunden</mat-card-title>
      </mat-card-header>

      <mat-card-content style="width: 100%; height: 100%">
        <highcharts-chart
          [Highcharts]="Highcharts"
          [constructorType]=""
          [oneToOne]="true"
          [options]="chartOptions"
          *ngIf="pseViewModel"></highcharts-chart>
      </mat-card-content>
    </mat-card>
  </div>

  <div>
    <mat-card>
      <mat-card-header>
        <mat-card-title>Meilensteine</mat-card-title>
      </mat-card-header>

      <mat-card-content *ngIf="pseViewModel">
        <mat-list>
          <mat-list-item *ngFor="let ms of displayedMs">
            <img
              mat-list-avatar
              src="{{ ms.ICON_URL }}"
              alt="{{ ms.ICON_URL }}" />
            <span mat-line>
              {{ ms.NAME }}
            </span>
            <span mat-line>
              {{ ms.MS_DATUM | date }}
            </span>
          </mat-list-item>
        </mat-list>
        <mat-paginator #msPaginator></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>

  <div>
    <mat-card>
      <mat-card-header>
        <mat-card-title>Vorgänge</mat-card-title>
      </mat-card-header>

      <mat-card-content *ngIf="pseViewModel">
        <mat-nav-list>
          <mat-list-item *ngFor="let task of displayedTasks">
            <img
              mat-list-avatar
              src="{{ task.ICON_URL }}"
              alt="{{ task.ICON_URL }}" />
            <span mat-line>
              {{ task.TEXT }}
            </span>
          </mat-list-item>
        </mat-nav-list>
        <mat-paginator
          #taskPaginator
          (page)="onTaskPaginatorEvent($event)"></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
