import { GeneralState } from './general.state';
import { createReducer, on } from '@ngrx/store';
import { GeneralActions } from './general.index';

export const generalFeatureKey = 'general';

const initialState: GeneralState = {
  pseViewModel: null,
  incidentViewModel: null,
  rootPseViewModel: null,
  positionViewModel: null,
  taskViewModel: null,
  ms: null,
  cartViewModel: null,
};

const reducer = createReducer(
  initialState,
  on(GeneralActions.initializePseView, (state) => state),
  on(GeneralActions.onInitializePseViewSuccess, (state, action) => ({
    ...state,
    pseViewModel: action.viewModel,
  })),
  on(GeneralActions.initializeIncidentView, (state) => state),
  on(GeneralActions.onInitializeIncidentViewSuccess, (state, action) => ({
    ...state,
    incidentViewModel: action.viewModel,
  })),
  on(GeneralActions.onLoadRootPseView, (state) => state),
  on(GeneralActions.onRootPseViewLoaded, (state, action) => ({
    ...state,
    rootPseViewModel: action.viewModel,
  })),
  on(GeneralActions.onLoadPositionViewModel, (state) => state),
  on(GeneralActions.onPositionViewModelLoaded, (state, action) => ({
    ...state,
    positionViewModel: action.viewModel,
  })),
  on(GeneralActions.onLoadTaskViewModel, (state) => state),
  on(GeneralActions.onTaskViewModelLoaded, (state, action) => ({
    ...state,
    taskViewModel: action.viewModel,
  })),
  on(GeneralActions.onLoadMs, (state) => state),
  on(GeneralActions.onMsLoaded, (state, action) => ({
    ...state,
    ms: action.ms,
  })),
  on(GeneralActions.onLoadCartViewModel, (state) => state),
  on(GeneralActions.onCartViewModelLoaded, (state, action) => {
    return {
      ...state,
      cartViewModel: action.viewModel,
    };
  }),
);

export function generalReducer(state: GeneralState, action) {
  return reducer(state, action);
}
