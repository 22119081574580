// views
export const GUID_VIEWDEF_AFOS = '7983048e-28a3-11b2-8015-7f6506b291be';
export const GUID_VIEWDEF_MS = 'c5ad56fe-28ba-11b2-808a-215b84dbd073';

// types
export const GUID_TYPE_MACHINE = 'b8f188e0-2801-11b2-80d1-416c2860a608';
export const GUID_TYPE_INCIDENT = 'bd434f86-293d-11b2-8063-c7ed09266728';
export const GUID_TYPE_PSE = 'b514cb1c-27fa-11b2-800a-b390cd5bbe84';
export const GUID_TYPE_PROJECT = 'b514d7ec-27fa-11b2-800a-b390cd5bbe84';
export const GUID_TYPE_PART = 'b514dea4-27fa-11b2-800a-b390cd5bbe84';
export const GUID_TYPE_TASK = 'a1ab26e6-281f-11b2-8018-4fa800893fe8';
export const GUID_TYPE_VC = '0199d0bc-285b-11b2-8045-95e4fda6b4dc';
export const GUID_TYPE_MS = '6b48d6f0-285d-11b2-806e-abd681c759c1';
export const GUID_TYPE_CLAMPING = 'b5151630-27fa-11b2-800a-b390cd5bbe84';
export const GUID_TYPE_CART = '21bcdf00-2852-11b2-80c4-65901820c185';

// paths
export const GUID_PATH_VIEW = 'b5159b3c-27fa-11b2-800a-b390cd5bbe84';
export const GUID_PATH_PLAN = 'b72b9396-2867-11b2-804f-53b4d6413335';
export const GUID_PATH_PREDECESSORS = '709b8815-2a6a-11b2-80d1-01a18f2a013d';
export const GUID_PATH_SUCCESSORS = '709bfc0e-2a6a-11b2-80d1-01a18f2a013d';
export const GUID_PATH_ACTIVITIES = '54ab611a-29cf-11b2-80fe-4b71560296f1';
export const GUID_PATH_OPERATIONS = 'b49022b6-2afe-11b2-806a-4d544b4e2c55';
export const GUID_PATH_COMMENTS = 'ad086f06-2d49-11b2-8003-e376f3b9d50e';

// actions
export const GUID_ACTION_EXECREPORT = '3cd315c0-2b31-11b2-807e-cd4a9be829b5';
export const GUID_ACTION_LOGISTICS_RELOCATE =
  'd9b955ad-2a91-11b2-8001-31de5552eab8';

// directories
export const GUID_DIR_INCIDENT_MAINCATEGORIES =
  'bc0751b2-29f1-11b2-80dd-53737a1d5efc';
export const GUID_DIR_WERTUNGSARTEN = '85607b34-29c4-11b2-803a-8913f60615bc';

// objects
export const GUID_OBJECT_PLAN_ROOT = 'a1ad09ac-281f-11b2-8018-4fa800893fe8';
