import { Component, OnInit } from '@angular/core';
import { MachineService, ProleisObject } from 'proleis-rest-client';
import { Options } from 'highcharts';
import {
  DashboardItemComponent,
  DashboardService,
} from 'proleis-web-dashboard';
import { MachineMainIdleTimesOptionsComponent } from './machine.main.idle.times.options.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Store } from '@ngrx/store';
import { ProleisForMobileState } from '../../../../store/store.index';
import { DashboardActions } from '../../../../store/dashboard/dashboard.index';
import { selectDashboardMdeInfo } from '../../../../store/dashboard/dashboard.selectors';

import * as Highcharts from 'highcharts';
import { highchartsColorPalette } from '../chartcolors';

@Component({
  selector: 'p4m-dashboard-machine-main-idle-times',
  templateUrl: 'machine.main.idle.times.component.html',
})
export class MachineMainIdleTimesComponent
  extends DashboardItemComponent
  implements OnInit
{
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Options;
  data: ProleisObject[];
  machine: ProleisObject;

  constructor(
    private ms: MachineService,
    public ds: DashboardService,
    private bottomSheet: MatBottomSheet,
    private store: Store<ProleisForMobileState>,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.item.options.machineId) {
      // daten laden
      this.store.dispatch(
        DashboardActions.loadeMdeData({
          machineId: this.item.options.machineId,
        }),
      );

      // daten selektieren und chart bauen
      this.store
        .select(selectDashboardMdeInfo, this.item.options.machineId)
        .subscribe((data) => {
          if (data) {
            this.data = data;
            this.machine = data.machine;
            this.buildChart(data.machineData);
          }
        });
    }
  }

  buildChart(values: ProleisObject[]) {
    const summedValues: Map<string, number> = new Map();
    values.forEach((val) => {
      if (summedValues.has(val.SUBCATEGORY)) {
        summedValues.set(
          val.SUBCATEGORY,
          summedValues.get(val.SUBCATEGORY) + Number(val.HOURS),
        );
      } else {
        summedValues.set(val.SUBCATEGORY, Number(val.HOURS));
      }
    });
    const chartValues: any[] = [];
    summedValues.forEach((value, key, map) => {
      chartValues.push({
        name: key,
        y: Number(value),
      });
    });

    this.chartOptions = {
      chart: {
        type: 'pie',
      },
      title: {
        text: null,
      },
      plotOptions: {
        pie: {
          showInLegend: true,
          dataLabels: {
            enabled: false,
          },
        },
      },
      series: [
        {
          type: 'pie',
          name: 'MDE Daten',
          data: chartValues,
        },
      ],
      credits: {
        enabled: false,
      },
    };
  }

  openOptionsBottomSheet() {
    this.bottomSheet.open(MachineMainIdleTimesOptionsComponent, {
      data: {
        item: this.item,
        dashboard: this.item.dashboardName,
      },
    });
  }

  delete() {
    this.ds.removeDashboardItem(this.item.dashboardName, this.item);
  }
}
