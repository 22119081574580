import { Component, OnInit } from '@angular/core';
import { exhaustMap, Observable } from 'rxjs';
import {
  CoreService,
  DocumentService,
  ProleisObject,
} from 'proleis-rest-client';
import { ActivatedRoute } from '@angular/router';
import { RedirectService } from '../../../services/redirect.service';

@Component({
  selector: 'app-clamping',
  templateUrl: './clamping.component.html',
  styleUrls: ['./clamping.component.scss'],
})
export class ClampingComponent implements OnInit {
  clamping$: Observable<ProleisObject>;
  documents$: Observable<ProleisObject[]>;
  ncFileObjects$: Observable<ProleisObject[]>;

  constructor(
    private cs: CoreService,
    private ds: DocumentService,
    private ar: ActivatedRoute,
    private rs: RedirectService,
  ) {}

  ngOnInit() {
    this.loadClamping();
    this.loadDocuments();
    this.loadNcFileObjects();
  }

  loadClamping() {
    const aliasFields =
      'NCPROGS=$JOIN(NCFILES, ",")' +
      ';POS_ID=PARENT[$ISPROJECTNODE=false].OBJECT_ID;POS_NAME=PARENT[$ISPROJECTNODE=false].TEXT' +
      ';BEMI_ID=PARENT[$ISPROJECTNODE=true].OBJECT_ID;BEMI_NAME=PARENT[$ISPROJECTNODE=true].TEXT;' +
      'PART_ID=PARENT[TYP_ID.GUID=b514dea4-27fa-11b2-800a-b390cd5bbe84].OBJECT_ID;PART_NAME=PARENT[TYP_ID.GUID=b514dea4-27fa-11b2-800a-b390cd5bbe84].TEXT;' +
      'PROJECT_ID=PARENT[TYP_ID.GUID=b514d7ec-27fa-11b2-800a-b390cd5bbe84];PROJECT_NAME=PARENT[TYP_ID.GUID=b514d7ec-27fa-11b2-800a-b390cd5bbe84].TEXT';
    this.clamping$ = this.cs.getObjectById(
      this.ar.snapshot.params.clampingId,
      aliasFields,
    );
  }

  loadDocuments() {
    this.documents$ = this.clamping$.pipe(
      exhaustMap((clamping) => {
        return this.ds.getDocumentsInContainer(clamping.DOKUMENTCONTAINER_ID);
      }),
    );
  }

  loadNcFileObjects() {
    this.ncFileObjects$ = this.clamping$.pipe(
      exhaustMap((clamping) => {
        const params = {
          IDS: clamping.NCPROGS,
        };
        return this.cs.getObjectsQuery<ProleisObject[]>(
          'getObjectsById',
          params,
        );
      }),
    );
  }

  redirect(objectId: string) {
    this.rs.redirectToInfoPanel(objectId);
  }
}
