import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CoreService, ProleisObject, UserService } from 'proleis-rest-client';
import { FormControl, Validators } from '@angular/forms';
import { ProleisForMobileState } from './modules/store/store.index';
import { Store } from '@ngrx/store';
import { DashboardItemsService } from './modules/dashboard/services/dashboard.items.service';
import { Dashboard } from './modules/dashboard/models/dashboard';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CreateDashboardComponent } from './modules/dashboard/components/views/create-dashboard/create.dashboard.component';
import { exhaustMap, forkJoin, Observable } from 'rxjs';
import { PlanningActions } from './modules/store/planning/planning.index';
import { selectUserBookings } from './modules/store/planning/planning.selectors';
import { RedirectService } from './modules/general/services/redirect.service';
import { map } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import {
  GUID_TYPE_INCIDENT,
  GUID_TYPE_PART,
  GUID_TYPE_PROJECT,
  GUID_TYPE_PSE,
  GUID_TYPE_TASK,
} from './p4m/guids';

@Component({
  selector: 'p4m-home-view',
  templateUrl: 'home.view.component.html',
  styleUrls: ['home.view.component.scss'],
})
export class HomeViewComponent implements OnInit, AfterViewInit {
  password: string;
  name: string;
  description: string;
  icon: string;
  url: string;

  allowedSearchTypes: string[] = [
    GUID_TYPE_PSE,
    GUID_TYPE_INCIDENT,
    GUID_TYPE_TASK,
    GUID_TYPE_PART,
    GUID_TYPE_PROJECT,
  ];

  user$: Observable<ProleisObject>;
  bookings$: Observable<ProleisObject[]>;
  visibleBookings$: Observable<ProleisObject[]>;
  tasks$: Observable<Task[]>;
  visibleTasks$: Observable<Task[]>;
  favorites$: Observable<ProleisObject[]>;
  dashboards: Dashboard[];
  lastSearches: ProleisObject[] = [];

  userNameFormControl = new FormControl('', [Validators.required]);

  constructor(
    private us: UserService,
    private store: Store<ProleisForMobileState>,
    private ds: DashboardItemsService,
    private bs: MatBottomSheet,
    private rs: RedirectService,
    private cs: CoreService,
  ) {}

  ngOnInit(): void {
    this.lastSearches = JSON.parse(localStorage.getItem('lastSearches'));
    if (!this.lastSearches) {
      this.lastSearches = [];
    }
    this.ds
      .getDashboards()
      .subscribe(
        (dashboards) => (this.dashboards = Array.from(dashboards.values())),
      );
    this.user$ = this.us.getCurrentUser();
    this.favorites$ = this.us.getUserFavorites();
    this.loadBookings();
    this.loadTasks();
  }

  ngAfterViewInit() {}

  loadBookings() {
    const aliasFields = 'AFOBEZ=OBJECT_ID.AFO_ID.TEXT;';
    this.store.dispatch(
      PlanningActions.loadUserBookings({
        start: new Date(new Date().setHours(0, 0, 0)),
        end: new Date(),
        aliasFields,
      }),
    );
    this.bookings$ = this.store.select(selectUserBookings);
    this.visibleBookings$ = this.bookings$.pipe(
      map((bookings) => bookings.slice(0, 5)),
    );
  }

  loadTasks() {
    this.tasks$ = this.bookings$.pipe(
      map((bookings) => {
        return [...new Set(bookings.map((booking) => booking.OBJECT_ID))];
      }),
      exhaustMap((taskIds) => {
        const aliasFields =
          'AFOARTNAME=AFO_ART_ID.NAME;ORDERNAME=PARENT.AUFTRAG_ID.TEXT;POSTEXT=LINK_ID.TEXT;' +
          'PARTNAME=LINK_ID.ROOT_ID.PARENT[TYP_ID.GUID=b514dea4-27fa-11b2-800a-b390cd5bbe84].TEXT;' +
          'PROJECTNAME=LINK_ID.ROOT_ID.PARENT[TYP_ID.GUID=b514d7ec-27fa-11b2-800a-b390cd5bbe84].TEXT' +
          ';LINKROOT=LINK_ID.ROOT_ID;LINKHEAD=LINK_ID.HEAD_ID;ROOTNAME=LINK_ID.ROOT_ID.NAME;' +
          'ROOTTEXT=LINK_ID.ROOT_ID.TEXT;';
        const requests = [];
        taskIds.forEach((taskId) =>
          requests.push(this.cs.getObjectById<Task>(taskId, aliasFields)),
        );
        return forkJoin(requests).pipe(
          map((result) => {
            return result;
          }),
        );
      }),
    );

    this.visibleTasks$ = this.tasks$.pipe(map((tasks) => tasks.slice(0, 5)));
  }

  createDashboard() {
    this.bs.open(CreateDashboardComponent);
  }

  onSearchSelected(obj: ProleisObject) {
    this.saveSearch(obj);
    this.rs.redirectToInfoPanel(obj.OBJECT_ID);
  }

  saveSearch(obj: ProleisObject) {
    if (this.lastSearches.length < 3) {
      this.lastSearches.push(obj);
    } else {
      this.lastSearches.pop();
      this.lastSearches.push(obj);
    }

    localStorage.setItem('lastSearches', JSON.stringify(this.lastSearches));
  }

  redirect(obj: ProleisObject) {
    this.rs.redirectToInfoPanel(obj.OBJECT_ID);
  }

  bookingPageEvent(event: PageEvent) {
    this.visibleBookings$ = this.visibleBookings$.pipe(
      map((bookings) =>
        bookings.slice(
          event.pageSize * event.pageIndex,
          event.pageSize * event.pageSize + event.pageSize,
        ),
      ),
    );
  }

  taskPageEvent(event: PageEvent) {
    this.visibleTasks$ = this.tasks$.pipe(
      map((tasks) =>
        tasks.slice(
          event.pageSize * event.pageIndex,
          event.pageSize * event.pageSize + event.pageSize,
        ),
      ),
    );
  }
}
