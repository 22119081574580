import { createFeatureSelector, createSelector } from '@ngrx/store';
import { generalFeatureKey } from './general.reducer';
import { GeneralState } from './general.state';

const generalSelector = createFeatureSelector<GeneralState>(generalFeatureKey);

export const selectPseViewModel = createSelector(
  generalSelector,
  (state) => state.pseViewModel,
);

export const selectIncidentViewModel = createSelector(
  generalSelector,
  (state) => state.incidentViewModel,
);

export const selectRootPseViewModel = createSelector(
  generalSelector,
  (state) => state.rootPseViewModel,
);

export const selectPositionViewModel = createSelector(
  generalSelector,
  (state) => state.positionViewModel,
);

export const selectTaskViewModel = createSelector(
  generalSelector,
  (state) => state.taskViewModel,
);

export const selectMs = createSelector(generalSelector, (state) => state.ms);

export const selectCartViewModel = createSelector(
  generalSelector,
  (state) => state.cartViewModel,
);
