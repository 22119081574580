import { Component, OnInit } from '@angular/core';
import {
  DashboardItemComponent,
  DashboardService,
} from 'proleis-web-dashboard';
import { Store } from '@ngrx/store';
import { ProleisForMobileState } from '../../../../store/store.index';
import { DashboardActions } from '../../../../store/dashboard/dashboard.index';
import { selectDashboardIncidentInfo } from '../../../../store/dashboard/dashboard.selectors';
import { Observable } from 'rxjs';
import { ProleisObject } from 'proleis-rest-client';

import * as Highcharts from 'highcharts';
import { Options, SeriesOptionsType } from 'highcharts';
import { highchartsColorPalette } from '../chartcolors';

@Component({
  selector: 'p4m-dashboard-incidents',
  templateUrl: 'dashboard.incidents.component.html',
})
export class DashboardIncidentsComponent
  extends DashboardItemComponent
  implements OnInit
{
  Highcharts: typeof Highcharts = Highcharts;
  incidents$: Observable<ProleisObject[]>;
  chartOptions: Options;

  constructor(
    private store: Store<ProleisForMobileState>,
    public ds: DashboardService,
  ) {
    super();
  }

  ngOnInit(): void {
    const date = new Date();
    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.store.dispatch(
      DashboardActions.loadIncidentInfo({
        startDate: start,
        endDate: end,
        groupBy: 'HAUPTKATEGORIE_ID',
        timeInterval: '853524',
      }),
    );

    this.incidents$ = this.store.select(selectDashboardIncidentInfo);
    this.buildCharts();
  }

  buildCharts() {
    this.incidents$.subscribe((incidents) => {
      if (incidents && incidents.length > 0) {
        const categories = this.getTimelabels(incidents);

        this.chartOptions = {
          title: {
            text: null,
          },
          xAxis: {
            categories,
          },
          yAxis: {
            title: {
              text: 'Anzahl',
            },
          },
          colors: highchartsColorPalette,
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: false,
              },
            },
          },
          series: this.getSeries(incidents),
          credits: {
            enabled: false,
          },
        };
      }
    });
  }

  getTimelabels(incidents: ProleisObject[]): string[] {
    return [...new Set(incidents.map((val) => val.TIMELABEL))];
  }

  getCategories(incidents: ProleisObject[]): string[] {
    return [...new Set(incidents.map((val) => val.HAUPTKATEGORIE_ID))];
  }

  getSeries(incidents: ProleisObject[]): SeriesOptionsType[] {
    const series: SeriesOptionsType[] = [];
    series.push(this.getPieSeries(incidents));
    series.push(...this.getLineSeries(incidents));
    return series;
  }

  getPieSeries(incidents: ProleisObject[]): SeriesOptionsType {
    const data = [];
    incidents.forEach((incident) =>
      data.push({
        name: incident.HAUPTKATEGORIE_ID,
        y: Number(incident.COUNT),
      }),
    );

    return {
      type: 'pie',
      name: 'Gesamt',
      data,
      center: [50, 50],
      size: 100,
      showInLegend: false,
    };
  }

  getLineSeries(incidents: ProleisObject[]): SeriesOptionsType[] {
    const result: SeriesOptionsType[] = [];
    const categories = this.getCategories(incidents);

    categories.forEach((category) => {
      const filteredIncidents = incidents.filter(
        (value) => value.HAUPTKATEGORIE_ID === category,
      );
      const data = filteredIncidents.map((val) => {
        return {
          name: val.HAUPTKATEGORIE_ID,
          y: Number(val.COUNT),
        };
      });

      result.push({
        type: 'column',
        data,
        name: category,
      });
    });

    return result;
  }

  delete() {
    this.ds.removeDashboardItem(this.item.dashboardName, this.item);
  }
}
