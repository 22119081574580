<ng-container *ngIf="clamping$ | async as clamping">
  <pw-view-title>{{ clamping.TEXT }}</pw-view-title>

  <div class="p4m-grid-4-col">
    <mat-card>
      <mat-card-header>
        <mat-card-title> Allgemein </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div
          style="display: grid; grid-template-columns: auto 1fr; grid-gap: 8px">
          <div style="font-weight: bold">Projekt</div>
          <div>{{ clamping.PROJECT_NAME }}</div>
          <div style="font-weight: bold">Teil</div>
          <div>{{ clamping.PART_NAME }}</div>
          <div style="font-weight: bold">PSE</div>
          <a (click)="redirect(clamping.BEMI_ID)">{{ clamping.BEMI_NAME }}</a>
          <div style="font-weight: bold">Position</div>
          <a (click)="redirect(clamping.POS_ID)">{{ clamping.POS_NAME }}</a>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="ncFileObjects$ | async as ncFiles">
      <mat-card-header>
        <mat-card-title> NC-Programme </mat-card-title>
      </mat-card-header>
      <mat-card-content *ngIf="ncFiles.length > 0; else noNcFiles">
        <app-document-list [documents]="ncFiles"></app-document-list>
      </mat-card-content>
      <ng-template #noNcFiles>
        <mat-card-content> Keine NC-Programme gefunden </mat-card-content>
      </ng-template>
    </mat-card>

    <mat-card *ngIf="documents$ | async as docs">
      <mat-card-header>
        <mat-card-title> Dokumente </mat-card-title>
      </mat-card-header>
      <mat-card-content *ngIf="docs.length > 0; else noDocuments">
        <app-document-list [documents]="docs"></app-document-list>
      </mat-card-content>
      <ng-template #noDocuments>
        <mat-card-content> Keine Dokumente gefunden </mat-card-content>
      </ng-template>
    </mat-card>
  </div>
</ng-container>
