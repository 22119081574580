import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'proleis-web-app';

@Component({
  selector: 'p4m-error-page',
  templateUrl: 'error.page.component.html',
  styleUrls: ['error.page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  error: Error;

  constructor(public errorService: NotificationService) {}

  ngOnInit(): void {
    this.errorService.error.subscribe((error) => (this.error = error));
  }
}
