import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private error$: Subject<Error> = new BehaviorSubject(null);

  constructor(
    private snackBar: MatSnackBar,
    private zone: NgZone,
    private router: Router,
  ) {}

  showError(error: Error) {
    this.zone.run(() => {
      this.error$.next(error);

      const snackBarRef: MatSnackBarRef<any> = this.snackBar.open(
        error.message,
        'Details',
        {
          duration: 3000,
          verticalPosition: 'bottom',
          panelClass: 'notification-error',
        },
      );

      snackBarRef.onAction().subscribe(() => {
        this.router.navigate(['/error']).then();
      });
    });
  }

  showNotification(msg: string, type: NotificationType = 'default') {
    this.zone.run(() => {
      this.snackBar.open(msg, 'Ok', {
        duration: 3000,
        verticalPosition: 'bottom',
        panelClass: this.getPanelClass(type),
      });
    });
  }

  get error(): Observable<Error> {
    return this.error$.asObservable();
  }

  getPanelClass(type: NotificationType) {
    switch (type) {
      case 'warning':
        return 'notification-warning';
      case 'error':
        return 'notification-error';
      case 'success':
        return 'notification-success';
      case 'default':
        return 'notification-default';
    }
  }
}

export type NotificationType = 'success' | 'error' | 'warning' | 'default';
