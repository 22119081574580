import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ProleisForMobileState } from '../../../../store/store.index';
import {
  CoreService,
  DocumentService,
  ProleisObject,
  RequestParam,
  UserService,
} from 'proleis-rest-client';
import { exhaustMap, Observable, tap } from 'rxjs';
import { RedirectService } from '../../../services/redirect.service';
import { GUID_PATH_COMMENTS } from '../../../../../p4m/guids';
import { map } from 'rxjs/operators';
import { BreakpointService } from 'proleis-web-app';

@Component({
  selector: 'p4m-incident-info',
  templateUrl: 'incident.info.panel.component.html',
  styleUrls: ['incident.info.panel.component.scss'],
})
export class IncidentInfoPanelComponent implements OnInit {
  incident$: Observable<ProleisObject>;
  documents$: Observable<ProleisObject[]>;
  comments$: Observable<ProleisObject[]>;
  displayedComments$: Observable<ProleisObject[]>;
  user$: Observable<ProleisObject>;

  length = 0;
  pageSize = 3;
  incrementStep = 3;

  newComment: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<ProleisForMobileState>,
    private coreService: CoreService,
    private redirectService: RedirectService,
    private documentsSerivce: DocumentService,
    private us: UserService,
    public bps: BreakpointService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.loadIncident();
    this.loadDocuments();
    this.loadComments();
    this.user$ = this.us.getCurrentUser();
  }

  loadIncident() {
    const aliasFields =
      'HAUPTKATEGORIE=HAUPTKATEGORIE_ID.TEXT;' +
      'KATEGORIE=KATEGORIE_ID.TEXT;STATUSTEXT=PROBLEM_STATUS_ID.TEXT;BEARBEITER=BEARBEITER_ID.TEXT' +
      ';VERURSACHER_EXT=FIRMA_ID.TEXT;VERURSACHER_INT=AUSLOESER_ID.TEXT;ERSTELLER=ERSTELLER_ID.TEXT;BEZUG=LINK_ID.TEXT;' +
      'DOKCONTAINER=OBJECT_ID.DOKUMENTCONTAINER_ID';
    this.incident$ = this.coreService.getObjectById<ProleisObject>(
      this.activatedRoute.snapshot.params.id,
      aliasFields,
    );
  }

  loadComments() {
    this.comments$ = this.coreService
      .getChildren<
        ProleisObject[]
      >(this.activatedRoute.snapshot.params.id, '§' + GUID_PATH_COMMENTS, 'USERNAME=ERSTELLER_ID.TEXT;COMMENT=COMMENT')
      .pipe(
        map((comments) =>
          comments.sort((a, b) => {
            if (new Date(a.DATUMERSTELLUNG) < new Date(b.DATUMERSTELLUNG)) {
              return -1;
            } else if (
              new Date(a.DATUMERSTELLUNG) > new Date(b.DATUMERSTELLUNG)
            ) {
              return 1;
            } else return 0;
          }),
        ),
        tap((comments) => {
          this.length = comments.length;
          if (this.length > comments.length) {
            this.length = comments.length;
          }

          if (this.pageSize > this.length) {
            this.pageSize = this.length;
          }
        }),
      );
    this.displayedComments$ = this.comments$.pipe(
      map((comments) => {
        return comments.slice(this.pageSize * -1);
      }),
    );
  }

  loadDocuments() {
    this.documents$ = this.incident$.pipe(
      exhaustMap((incident) => {
        if (incident.DOKCONTAINER) {
          return this.documentsSerivce.getDocumentsInContainer(
            incident.DOKCONTAINER,
          );
        }
      }),
    );
  }

  expandList() {
    if (this.length > this.pageSize + this.incrementStep)
      this.pageSize += this.incrementStep;
    else this.pageSize = this.length;
    this.displayedComments$ = this.comments$.pipe(
      map((comments) => comments.slice(this.pageSize * -1)),
    );
  }

  displayAllComments() {
    this.displayedComments$ = this.comments$;
    this.displayedComments$.subscribe((comments) => {
      this.length = comments.length;
      this.pageSize = this.length;
    });
  }

  redirect(objectId: string) {
    this.redirectService.redirectToInfoPanel(objectId);
  }

  getAuthorClass(comment: ProleisObject, user: ProleisObject) {
    if (comment.ERSTELLER_ID === user.OBJECT_ID) {
      return 'comment-self';
    } else {
      return 'comment-other';
    }
  }

  getAvatarText(comment: ProleisObject) {
    const userName = comment.USERNAME;
    return userName
      .split(' ')
      .map((fragment) => fragment[0])
      .join('');
  }

  createComment() {
    const params: RequestParam = {
      COMMENT: this.newComment,
    };
    this.coreService
      .executeAction(
        this.activatedRoute.snapshot.params.id,
        '§b2005ef0-2b1a-11b2-80e7-d585c8e606e4',
        params,
      )
      .subscribe((result) => {
        this.loadComments();
        this.newComment = '';
      });
  }

  deleteComment(commentId: string) {
    this.coreService.deleteObject(commentId).subscribe((result) => {
      this.loadComments();
    });
  }

  editIncident() {
    this.router
      .navigate([
        '/general/incident/' + this.activatedRoute.snapshot.params.id + '/edit',
      ])
      .then();
  }
}
