<p4m-expandable-card-component [titleClass]="getTaskStatusClass(task)">
  <ng-container *ngIf="getProgress() > 0" class="card-progress">
    <mat-progress-bar
      mode="determinate"
      [value]="getProgress()"></mat-progress-bar>
  </ng-container>

  <ng-container class="card-title">
    <div style="display: grid; grid-template-columns: auto 1fr">
      <div>
        <mat-icon
          class="afo-icon icon-todo-green"
          *ngIf="ppsService.isReady(task)"
          >circle
        </mat-icon>
        <mat-icon
          class="afo-icon icon-todo-red"
          *ngIf="ppsService.isNotReleased(task)"
          >offline_bolt
        </mat-icon>
        <mat-icon
          class="afo-icon icon-todo-yellow"
          *ngIf="ppsService.isPartiallyReleased(task)"
          >circle
        </mat-icon>
        <mat-icon
          class="afo-icon icon-in-progress"
          *ngIf="ppsService.isInProgress(task)"
          >circle
        </mat-icon>
        <mat-icon
          class="afo-icon icon-finished"
          *ngIf="ppsService.isFinished(task)"
          >check_circle
        </mat-icon>
      </div>
      <div class="task-header">
        <div style="font-weight: bold">
          {{ task.NAME }} {{ task.AFOARTNAME }} {{ task.ROOTNAME }}
          {{ task.PROJEKTNR }}
        </div>
        <div>
          {{ task.STARTPLAN | date: 'short' }} -
          {{ task.ENDEPLAN | date: 'short' }}
        </div>
      </div>
      <div
        style="
          grid-column: auto / span 2;
          white-space: pre-line;
          overflow: hidden;
          text-overflow: ellipsis;
        ">
        {{ task.AFOBEZ }}
      </div>
    </div>
  </ng-container>

  <div class="card-content">
    <div style="display: grid; grid-template-columns: auto 1fr; grid-gap: 8px">
      <div style="font-weight: bold">
        {{ 'planning.task.infoText' | translate }}
      </div>
      <a (click)="onRedirect(task)">{{ task.INFOTEXT }}</a>

      <ng-container *ngIf="task.AFOBEZ">
        <div style="font-weight: bold">
          {{ 'planning.task.description' | translate }}
        </div>
        <div>{{ task.AFOBEZ }}</div>
      </ng-container>

      <div style="font-weight: bold">
        {{ 'planning.task.type' | translate }}
      </div>
      <div>{{ task.AFOARTNAME }}</div>

      <ng-container *ngIf="task.ORDERNAME">
        <div style="font-weight: bold">Auftrag</div>
        <div>{{ task.ORDERNAME }}</div>
      </ng-container>

      <ng-container *ngIf="task.LINKHEAD !== task.LINKROOT && task.POSTEXT">
        <div style="font-weight: bold">
          {{ 'planning.task.pos' | translate }}
        </div>
        <a (click)="onRedirectPos(task)">{{ task.POSTEXT }}</a>
      </ng-container>

      <ng-container *ngIf="task.ROOTTEXT">
        <div style="font-weight: bold">
          {{ 'planning.task.tool' | translate }}
        </div>
        <a (click)="onRedirectTool(task)">{{ task.ROOTTEXT }}</a>
      </ng-container>

      <ng-container *ngIf="task.PARTNAME">
        <div style="font-weight: bold">
          {{ 'planning.task.part' | translate }}
        </div>
        <div>{{ task.PARTNAME }}</div>
      </ng-container>

      <ng-container *ngIf="task.PROJECTNAME">
        <div style="font-weight: bold">
          {{ 'planning.task.project' | translate }}
        </div>
        <div>{{ task.PROJECTNAME }}</div>
      </ng-container>
    </div>
  </div>
  <div class="card-actions">
    <button
      mat-button
      color="accent"
      routerLink="/general/info-panels/task/{{ task.OBJECT_ID }}/track">
      {{ 'planning.task.track' | translate }}
    </button>
    <button
      *ngIf="allowStartTracking"
      mat-button
      color="accent"
      (click)="startTracking(task)">
      {{ 'planning.task.start' | translate }}
    </button>
    <button
      *ngIf="
        !ppsService.isInProgress(task) &&
        !ppsService.isFinished(task) &&
        (ppsService.isReady(task) || allowSetUnapprovedTasksInProgress)
      "
      mat-button
      color="accent"
      (click)="ppsService.setTaskInProgress(task.OBJECT_ID)">
      {{ 'planning.task.inProgress' | translate }}
    </button>
    <button
      [matMenuTriggerFor]="menu"
      mat-button
      color="accent"
      aria-label="Aktionen">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        (click)="ppsService.setTaskComplete(task.OBJECT_ID)">
        <span>{{ 'planning.board.reportFinished' | translate }}</span>
      </button>
      <button mat-menu-item routerLink="/incidents/{{ task.OBJECT_ID }}/create">
        <span>{{ 'planning.board.createIncident' | translate }}</span>
      </button>
      <button
        *ngIf="ppsService.isInProgress(task)"
        mat-menu-item
        (click)="ppsService.interruptTask(task.OBJECT_ID)">
        <span>{{ 'planning.task.interrupt' | translate }}</span>
      </button>
    </mat-menu>
  </div>
</p4m-expandable-card-component>
