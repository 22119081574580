<pw-view-title *ngIf="currentUser">{{ currentUser.TEXT }}</pw-view-title>

<pw-view-actions>
  <button mat-button>
    <mat-icon>vpn_key</mat-icon>
  </button>
</pw-view-actions>
<div class="p4m-grid-4-col">
  <div *ngIf="currentUser">
    <mat-card>
      <mat-card-header>
        <mat-card-title> Benutzer </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div>
          <mat-form-field>
            <input
              matInput
              readonly
              placeholder="Name"
              value="{{ currentUser.TEXT }}" />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              readonly
              placeholder="Login"
              value="{{ currentUser.LOGIN }}" />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div *ngIf="currentUser">
    <mat-card>
      <mat-card-header>
        <mat-card-title> Organisation </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div gdColumns="auto">
          <mat-form-field *ngIf="area">
            <input
              matInput
              readonly
              placeholder="Fachbereich"
              value="{{ area.TEXT }}" />
          </mat-form-field>
          <mat-form-field *ngIf="location">
            <input
              matInput
              readonly
              placeholder="Standort"
              value="{{ location.TEXT }}" />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              readonly
              placeholder="Abteilung"
              value="{{ currentUser.ABTEILUNG }}" />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div *ngIf="currentUser">
    <mat-card>
      <mat-card-header>
        <mat-card-title> Kommunikation </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div gdColumns="auto">
          <mat-form-field *ngIf="area">
            <input
              matInput
              placeholder="Telefonnummer"
              value="{{ currentUser.TELEFONNUMMER }}" />
          </mat-form-field>
          <mat-form-field *ngIf="area">
            <input
              matInput
              placeholder="E-Mail"
              value="{{ currentUser.EMAIL }}" />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
