<mat-card *ngIf="incidents$">
  <mat-card-title>
    {{ 'dashboard.widgets.incidents.title' | translate }}
  </mat-card-title>
  <mat-card-content>
    <highcharts-chart
      [Highcharts]="Highcharts"
      *ngIf="chartOptions"
      [oneToOne]="true"
      [options]="chartOptions"></highcharts-chart>
  </mat-card-content>
  <mat-card-actions align="end">
    <button
      mat-button
      color="primary"
      *ngIf="ds.isDashboardEditable() | async"
      (click)="delete()">
      <mat-icon>remove</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
