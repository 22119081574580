import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  DashboardItemOptionsComponent,
  DashboardService,
} from 'proleis-web-dashboard';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { CoreService, ProleisObject } from 'proleis-rest-client';
import { GUID_PATH_VIEW } from '../../../../../p4m/guids';

@Component({
  selector: 'p4m-dashboard-capacity-workload-options',
  templateUrl: 'capacity.workload.options.component.html',
})
export class CapacityWorkloadOptionsComponent implements OnInit {
  @ViewChild(DashboardItemOptionsComponent, { static: false })
  itemOptions: DashboardItemOptionsComponent;

  allowedTypes: string[] = [
    'b40ef99a-295d-11b2-80bb-e344dc12dd97',
    'da37a758-27fe-11b2-80f0-f32fdb3f5985',
    'da37a4e2-27fe-11b2-80f0-f32fdb3f5985',
  ];

  groupFields: TaskGroupingFields[] = [
    { name: 'RES_ID', description: 'Ressource' },
    { name: 'RES_GRP_ID', description: 'Ressourcengruppe' },
    { name: 'RES_PLAN_ID', description: 'Ressourcenbereich' },
    { name: 'AFO_ART_ID', description: 'Vorgangsart' },
    { name: 'AUFTRAGSNR', description: 'Auftrag' },
    { name: 'RES_ID.KOSTENSTELLE_ID', description: 'Kostenstelle' },
    { name: 'KUNDE_NAME', description: 'Kunde' },
    { name: 'PROJEKTNR', description: 'Projekt' },
    { name: 'TEILENR', description: 'Teil' },
    { name: 'BEMINR', description: 'Werkzeug' },
    { name: 'VC_STRANG_ID', description: 'Planungsstrang' },
    { name: 'VC_CONTAINERART_ID', description: 'Containerart' },
  ];

  timeRange: number[] = [1, 2, 4, 8, 12];

  timeUnits: ProleisObject[];

  includePr: boolean;
  includeVp: boolean;
  onlyCapaActive: boolean;
  excludeFinished: boolean;
  timeUnit: ProleisObject;
  groupField: TaskGroupingFields;
  queryParent: ProleisObject;
  start: number;
  end: number;
  resIds: ProleisObject[] = [];

  constructor(
    private bottomSheetRef: MatBottomSheetRef,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data,
    private ds: DashboardService,
    private cs: CoreService,
  ) {}

  ngOnInit(): void {
    this.cs
      .getObjectByGuid<ProleisObject>('a685dac6-2b35-11b2-8030-cd0cfe6022e3')
      .subscribe((timeUnit) => {
        this.cs
          .getChildren<
            ProleisObject[]
          >(timeUnit.OBJECT_ID, '§' + GUID_PATH_VIEW)
          .subscribe((result) => {
            this.timeUnits = result;
          });
      });

    this.includePr = this.data.item.options.includePr;
    this.includeVp = this.data.item.options.includeVp;
    this.resIds = this.data.item.options.resIds;
    this.onlyCapaActive = this.data.item.options.onlyCapaActive;
    this.excludeFinished = this.data.item.options.excludeFinished;
    this.start = this.data.item.options.start;
    this.end = this.data.item.options.end;
  }

  dismiss() {
    this.bottomSheetRef.dismiss();
  }

  update() {
    this.ds.editDashboardItem(this.data.item.dashboardName, {
      itemSize: this.itemOptions.selectedItemSize,
      itemName: this.itemOptions.selectedName,
      dashboardName: this.data.item.dashboardName,
      componentTypeName: this.itemOptions.selectedComponentType,
      options: {
        includePr: this.includePr,
        includeVp: this.includeVp,
        excludeFinished: this.excludeFinished,
        onlyCapaActive: this.onlyCapaActive,
        groupField: this.groupField,
        resIds: this.resIds,
        timeUnit: this.timeUnit,
        start: this.start,
        end: this.end,
      },
    });
  }

  onResSelectionChange(event) {
    this.resIds = event;
  }
}

export interface TaskGroupingFields {
  name: string;
  description: string;
}
