import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CoreService,
  CreateTimetrackingBody,
  ProleisObject,
  UserService,
} from 'proleis-rest-client';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from 'proleis-web-app';

@Component({
  selector: 'p4m-multi-tracking-component',
  templateUrl: 'multi.tracking.component.html',
})
export class MultiTrackingComponent implements OnInit {
  preliminaryTrackings: CreateTimetrackingBody[] = [];
  @Input()
  trackingData: CreateTimetrackingBody;
  @Output()
  preliminaryTrackingsChanged: BehaviorSubject<CreateTimetrackingBody[]> =
    new BehaviorSubject<CreateTimetrackingBody[]>(this.preliminaryTrackings);
  displayedColumns: string[] = [
    'date',
    'work',
    'resId',
    'ratingTypeId',
    'serviceTypeId',
  ];
  resNames: Map<string, string> = new Map();
  ratingTypeNames: Map<string, string> = new Map();
  serviceTypeNames: Map<string, string> = new Map();

  constructor(
    private us: UserService,
    private ar: ActivatedRoute,
    private ns: NotificationService,
    private cs: CoreService,
  ) {}

  ngOnInit() {
    this.getTrackingNames();
  }

  createPreliminaryTracking() {
    const createTimetrackingBody: CreateTimetrackingBody = {
      workingTime: this.trackingData.workingTime,
      date: this.trackingData.date,
      posId: this.trackingData.posId,
      incidentId: this.trackingData.incidentId,
      resId: this.trackingData.resId,
      ratingTypeId: this.trackingData.ratingTypeId,
      serviceTypeId: this.trackingData.serviceTypeId,
      taskId: this.ar.snapshot.params.id,
      machineTracking: this.trackingData.machineTracking,
      comment: this.trackingData.comment,
    };

    this.us
      .checkTimetrackingParams(createTimetrackingBody)
      .subscribe((result) => {
        if (result.checkResults.length === 0) {
          this.preliminaryTrackings.push(createTimetrackingBody);
          this.preliminaryTrackings = [...this.preliminaryTrackings];
          this.preliminaryTrackingsChanged.next(this.preliminaryTrackings);
        } else {
          const notificationMessage = result.checkResults
            .map((check) => check.errorMessage)
            .join(',');
          this.ns.showNotification(notificationMessage);
        }
      });
  }

  getTrackingNames() {
    let requests = [];
    this.preliminaryTrackings.forEach((tracking) => {
      if (tracking.resId) {
        requests.push(this.cs.getObjectById<ProleisObject>(tracking.resId));
      }
    });

    forkJoin(requests)
      .pipe(
        map((ressources) => {
          ressources.forEach((res) =>
            this.resNames.set(res.OBJECT_ID, res.TEXT),
          );
        }),
      )
      .subscribe();

    requests = [];
    this.preliminaryTrackings.forEach((tracking) => {
      if (tracking.ratingTypeId) {
        requests.push(
          this.cs.getObjectById<ProleisObject>(tracking.ratingTypeId),
        );
      }
    });

    forkJoin(requests)
      .pipe(
        map((ratingTypes) => {
          ratingTypes.forEach((ratingType) =>
            this.ratingTypeNames.set(ratingType.OBJECT_ID, ratingType.TEXT),
          );
        }),
      )
      .subscribe();

    requests = [];
    this.preliminaryTrackings.forEach((tracking) => {
      if (tracking.serviceTypeId) {
        requests.push(
          this.cs.getObjectById<ProleisObject>(tracking.serviceTypeId),
        );
      }
    });

    forkJoin(requests)
      .pipe(
        map((serviceTypes) => {
          serviceTypes.forEach((serviceType) =>
            this.serviceTypeNames.set(serviceType.OBJECT_ID, serviceType.TEXT),
          );
        }),
      )
      .subscribe();
  }

  getDisplayedColumns() {
    let displayed = [...this.displayedColumns];

    if (this.resNames.size === 0) {
      displayed.splice(displayed.indexOf('resId'), 1);
    }

    if (this.serviceTypeNames.size === 0) {
      displayed.splice(displayed.indexOf('serviceTypeId'), 1);
    }

    if (this.ratingTypeNames.size === 0) {
      displayed.splice(displayed.indexOf('ratingTypeId'), 1);
    }
    return displayed;
  }
}
