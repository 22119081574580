import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProleisForMobileState } from '../../../store/store.index';
import { FormControl } from '@angular/forms';
import { Param, ProleisObject, Types } from 'proleis-rest-client';
import {
  createIncident,
  loadMainCategories,
} from '../../../store/quality/quality.actions';
import { selectMainCategories } from '../../../store/quality/quality.selectors';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { GUID_PATH_VIEW, GUID_TYPE_INCIDENT } from '../../../../p4m/guids';

@Component({
  selector: 'p4m-create-incident',
  templateUrl: 'create.incident.component.html',
})
export class CreateIncidentComponent implements OnInit {
  nameControl: FormControl = new FormControl();
  descriptionControl: FormControl = new FormControl();
  mainCategoryControl: FormControl = new FormControl();

  mainCategories: ProleisObject[];
  files: FileList;

  constructor(
    private store: Store<ProleisForMobileState>,
    private bsRef: MatBottomSheetRef,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(loadMainCategories());
    this.store.select(selectMainCategories).subscribe((mainCategories) => {
      this.mainCategories = mainCategories;
    });
  }

  createIncident() {
    const params: Param[] = [
      {
        name: 'PATH_ID',
        value: '§' + GUID_PATH_VIEW,
        type: Types.TEXT,
      },
      {
        name: 'TYP_ID',
        value: '§' + GUID_TYPE_INCIDENT,
        type: Types.TEXT,
      },
      {
        name: 'NAME',
        value: this.nameControl.value,
        type: Types.TEXT,
      },
      {
        name: 'DESCRIPTION',
        value: this.descriptionControl.value,
        type: Types.TEXT,
      },
      {
        name: 'HAUPTKATEGORIE_ID',
        value: this.mainCategoryControl.value.OBJECT_ID,
        type: Types.IDENT,
      },
    ];

    if (this?.data?.linkId) {
      params.push({
        name: 'BEZUG_ID',
        value: this.data.linkId,
        type: Types.IDENT,
      });
    }

    if (this.files) {
      this.store.dispatch(
        createIncident({ params, files: Array.from(this.files) }),
      );
    } else {
      this.store.dispatch(createIncident({ params }));
    }

    this.bsRef.dismiss();
  }

  closeBottomSheet() {
    this.bsRef.dismiss();
  }

  onFileSelected(event) {
    this.files = event;
    console.log(this.files);
  }
}
