<pw-view-title>{{ (incident$ | async)?.TEXT }}</pw-view-title>

<pw-view-actions>
  <button mat-button [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>
</pw-view-actions>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="editIncident()">Editieren</button>
  <button
    mat-menu-item
    routerLink="/general/qr-code/{{ (incident$ | async)?.OBJECT_ID }}">
    QR-Code
  </button>
</mat-menu>

<div class="p4m-grid-3-col mw-70" *ngIf="incident$ | async as incident">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Allgemein</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div
        style="display: grid; grid-template-columns: auto 1fr; grid-gap: 8px">
        <div style="font-weight: bold">Bezug</div>
        <a (click)="redirect(incident.LINK_ID)">{{ incident.BEZUG }}</a>
        <div style="font-weight: bold">Hauptkategorie</div>
        <div>{{ incident.HAUPTKATEGORIE }}</div>
        <div style="font-weight: bold">Kategorie</div>
        <div>{{ incident.KATEGORIE }}</div>
        <div style="font-weight: bold">Status</div>
        <div>{{ incident.STATUSTEXT }}</div>
        <div style="font-weight: bold">Termin</div>
        <div>{{ incident.DATUM_TERMIN | date: 'shortDate' }}</div>
        <div style="font-weight: bold">Erledigt am</div>
        <div>{{ incident.DATUM_FERTIG | date: 'shortDate' }}</div>
        <div style="font-weight: bold">Beschreibung</div>
        <div>{{ incident.PROBLEM_BESCHREIBUNG }}</div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Zuordnung</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div
        style="display: grid; grid-template-columns: auto 1fr; grid-gap: 8px">
        <div style="font-weight: bold">Verantwortlicher</div>
        <div>{{ incident.BEARBEITER }}</div>
        <div style="font-weight: bold">Verursacher extern</div>
        <div>{{ incident.VERURSACHER_EXT }}</div>
        <div style="font-weight: bold">Verursacher intern</div>
        <div>{{ incident.VERURSACHER_INT }}</div>
        <div style="font-weight: bold">Ersteller</div>
        <div>{{ incident.ERSTELLER }}</div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Dokumente</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <app-document-list [documents]="documents$ | async"></app-document-list>
    </mat-card-content>
  </mat-card>

  <mat-card
    [ngClass]="{
      small: bps.isSmall() | async,
      large: !(bps.isSmall() | async),
    }">
    <mat-card-header>
      <mat-card-title>
        <mat-card-title>Kommentare</mat-card-title>
        <mat-card-subtitle>
          <div>
            <span>{{ pageSize }}/{{ length }} angezeigt</span>
            <button
              [disabled]="!(pageSize < length)"
              mat-button
              color="accent"
              (click)="expandList()">
              WEITERE {{ incrementStep }} LADEN
            </button>
            <button
              [disabled]="pageSize === length"
              mat-button
              color="accent"
              (click)="displayAllComments()">
              ALLE LADEN
            </button>
          </div>
        </mat-card-subtitle>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content *ngIf="user$ | async as user">
      <ng-container *ngIf="comments$ | async as allComments">
        <ng-container *ngIf="displayedComments$ | async as comments">
          <div class="comments-container" style="margin-top: 8px">
            <div
              *ngFor="let comment of comments"
              class="comment"
              [class]="getAuthorClass(comment, user)">
              <div class="comment-author">
                <div>{{ comment.USERNAME }}</div>
                <div style="flex: 1 0 auto"></div>
                <button mat-button (click)="deleteComment(comment.OBJECT_ID)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
              <mat-divider style="margin: 8px"></mat-divider>
              <div class="comment-text">{{ comment.COMMENT }}</div>
              <div class="comment-time">
                {{ comment.DATUMERSTELLUNG | date: 'short' }}
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <mat-divider></mat-divider>

      <div
        style="
          padding-top: 1rem;
          display: flex;
          justify-content: center;
          justify-items: center;
        ">
        <mat-form-field style="width: 90%">
          <mat-label>Kommentar eingeben</mat-label>
          <textarea [(ngModel)]="newComment" matInput> </textarea>
          <button matSuffix mat-icon-button (click)="createComment()">
            <mat-icon>send</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>
</div>
