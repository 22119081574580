import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[DurationInput]',
})
export class DurationInputDirective {
  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    console.log('keydown', e);
  }

  @HostListener('change', ['$event'])
  onChange(e: any) {
    this.parseDuration(e.target.value);
  }

  private parseDuration(input: string) {
    if (!input) {
      return 0;
    }

    const mRegEx = new RegExp(/([0-9][0-9]?)[ ]?m/);
    const hRegEx = new RegExp(/([0-9][0-9]?)[ ]?h/);
    const dRegEx = new RegExp(/([0-9])[ ]?d/);

    let days = 0;
    let hours = 0;
    let minutes = 0;

    if (dRegEx.test(input)) {
      days = Number(dRegEx.exec(input)[1]);
    }
    if (hRegEx.test(input)) {
      hours = Number(hRegEx.exec(input)[1]);
    }
    if (mRegEx.test(input)) {
      minutes = Number(mRegEx.exec(input)[1]);
    }

    console.log('toMinutes', this.toMinutes(days, hours, minutes));
  }

  private toMinutes(days?: number, hours?: number, minutes?: number): Number {
    let addedMinutes = 0;
    if (days > 0) {
      addedMinutes += days * 24 * 60;
    }
    if (hours > 0) {
      addedMinutes += hours * 60;
    }

    if (minutes > 0) {
      addedMinutes += minutes;
    }

    return addedMinutes;
  }
}
