import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { concat, forkJoin, iif, Observable, of, switchMap } from 'rxjs';
import {
  CoreService,
  CreateTimetrackingBody,
  ProleisObject,
  RequestParam,
  Task,
  UserService,
} from 'proleis-rest-client';
import { ActivatedRoute } from '@angular/router';
import { MultiTrackingComponent } from './multi.tracking.component';
import { DurationPipe } from './duration.pipe';
import { Store } from '@ngrx/store';
import { ProleisForMobileState } from '../../../store/store.index';
import { DurationValidator } from './duration.validator';
import { PlanningActions } from '../../../store/planning/planning.index';
import { map } from 'rxjs/operators';
import { Location } from '@angular/common';
import { NotificationService } from 'proleis-web-app';

@Component({
  selector: 'new-time-tracking-component',
  templateUrl: 'new-time-tracking.component.html',
  styleUrls: ['new-time-tracking.component.scss'],
})
export class NewTimeTrackingComponent implements OnInit {
  multiTracking = false;
  preliminaryTrackings: CreateTimetrackingBody[];

  @ViewChild(MultiTrackingComponent) trackingRef: MultiTrackingComponent;

  form: FormGroup;

  task$: Observable<Task>;
  res$: Observable<ProleisObject[]>;
  activities$: Observable<ProleisObject[]>;
  positions$: Observable<ProleisObject[]>;
  incidents$: Observable<ProleisObject[]>;
  serviceTypes$: Observable<ProleisObject[]>;
  ratingTypes$: Observable<ProleisObject[]>;
  workDone$: Observable<number>;

  constructor(
    private fb: FormBuilder,
    private cs: CoreService,
    private ar: ActivatedRoute,
    private store: Store<ProleisForMobileState>,
    private us: UserService,
    private location: Location,
    private ns: NotificationService,
  ) {}

  ngOnInit() {
    this.loadTask();
    this.loadResources();
    this.loadActivities();
    this.loadIncidents();
    this.loadPositions();
    this.loadServiceTypes();
    this.loadValuationTypes();
    this.getWorkDonePercentage();
    this.initForm();

    this.preliminaryTrackings = [];
  }

  private initForm() {
    forkJoin({
      task: this.task$,
      resources: this.res$,
    }).subscribe((result) => {
      this.form = this.fb.group({
        base: this.fb.group({
          machineTracking: new FormControl(false),
          date: new FormControl(new Date()),
          workingTime: new FormControl(undefined, {
            validators: [
              Validators.required,
              DurationValidator.validateDuration,
            ],
          }),
          description: new FormControl(''),
          incidentId: new FormControl(result?.task?.PROBLEM_ID),
          resId: new FormControl(0),
        }),
        additional: this.fb.group({
          posId: new FormControl(result?.task?.POSITION_OBJECT_ID),
          serviceTypeId: new FormControl(0),
          ratingTypeId: new FormControl(0),
          activityId: new FormControl(0),
        }),
      });
    });
  }

  private loadTask() {
    const aliasFields =
      'RESMACHINEBOOKING=RES_ID.MACHINE_BOOKING_MANUAL;POSITION_OBJECT_ID=HEAD_ID.OBJECT_ID';
    this.task$ = this.cs.getObjectById(this.ar.snapshot.params.id, aliasFields);
  }

  private loadResources() {
    this.res$ = this.task$.pipe(
      switchMap((task) => {
        const params: RequestParam = {
          TASK_ID: task.OBJECT_ID,
          ELEMENT: 'RESOURCE_OBJECT_ID',
          BOOKING_TYPE: task.RESMACHINEBOOKING,
        };
        return iif(
          () => task.RES_ID > 0,
          of(undefined),
          this.cs.getObjectsQuery<ProleisObject[]>('getBDEPicklist', params),
        );
      }),
    );
  }

  private loadActivities() {
    this.activities$ = this.task$.pipe(
      switchMap((task) => {
        const params: RequestParam = {
          TASK_ID: task.OBJECT_ID,
          ELEMENT: 'OPERATION_OBJECT_ID',
          BOOKING_TYPE: task.RESMACHINEBOOKING,
        };
        return this.cs.getObjectsQuery<ProleisObject[]>(
          'getBDEPicklist',
          params,
        );
      }),
    );
  }

  private loadPositions() {
    this.positions$ = this.task$.pipe(
      switchMap((task) => {
        const params: RequestParam = {
          TASK_ID: task.OBJECT_ID,
          ELEMENT: 'POSITION_OBJECT_ID',
          BOOKING_TYPE: task.RESMACHINEBOOKING,
        };
        return this.cs.getObjectsQuery<ProleisObject[]>(
          'getBDEPicklist',
          params,
        );
      }),
    );
  }

  private loadIncidents() {
    this.incidents$ = this.task$.pipe(
      switchMap((task) => {
        const params: RequestParam = {
          TASK_ID: task.OBJECT_ID,
          ELEMENT: 'INCIDENT_OBJECT_ID',
          BOOKING_TYPE: task.RESMACHINEBOOKING,
        };
        return this.cs.getObjectsQuery<ProleisObject[]>(
          'getBDEPicklist',
          params,
        );
      }),
    );
  }

  private loadServiceTypes() {
    this.serviceTypes$ = this.task$.pipe(
      switchMap((task) => {
        const params: RequestParam = {
          TASK_ID: task.OBJECT_ID,
          ELEMENT: 'SERVICE_TYPE_OBJECT_ID',
          BOOKING_TYPE: task.RESMACHINEBOOKING,
        };
        return this.cs.getObjectsQuery<ProleisObject[]>(
          'getBDEPicklist',
          params,
        );
      }),
    );
  }

  private loadValuationTypes() {
    this.ratingTypes$ = this.task$.pipe(
      switchMap((task) => {
        const params: RequestParam = {
          TASK_ID: task.OBJECT_ID,
          ELEMENT: 'TASK_OBJECT_ID',
          BOOKING_TYPE: task.RESMACHINEBOOKING,
        };
        return this.cs.getObjectsQuery<ProleisObject[]>(
          'getBDEPicklist',
          params,
        );
      }),
    );
  }

  onRatingTypeChanged(event: boolean) {
    this.loadServiceTypes();
  }

  getTrackingData(): CreateTimetrackingBody | null {
    const base = this.form.get('base');
    if (!base.valid) {
      return null;
    }
    let data: CreateTimetrackingBody = {
      workingTime: DurationPipe.toMinutes(
        base.get('workingTime').getRawValue(),
      ),
      date: base.get('date').getRawValue(),
      incidentId: base.get('incidentId').getRawValue(),
      resId: base.get('resId').getRawValue(),
      taskId: this.ar.snapshot.params.id,
      machineTracking: Boolean(base.get('machineTracking').getRawValue()),
      comment: base.get('description').getRawValue(),
    };
    const additional = this.form.get('additional');
    if (additional.valid) {
      data.posId = additional.get('posId').getRawValue();
      data.ratingTypeId = additional.get('ratingTypeId').getRawValue();
      data.serviceTypeId = additional.get('serviceTypeId').getRawValue();
    }
    return data;
  }

  createPreliminaryTracking() {
    if (!this.multiTracking) {
      this.multiTracking = true;
      setTimeout(() => {
        this.trackingRef.createPreliminaryTracking();
      }, 500);
    } else {
      this.trackingRef.createPreliminaryTracking();
    }
  }

  onTrackingsChanged(preliminaryTrackings: CreateTimetrackingBody[]) {
    this.preliminaryTrackings = preliminaryTrackings;
  }

  createTrackings() {
    if (this.multiTracking) {
      const requests = [];
      this.preliminaryTrackings.forEach((tracking) => {
        requests.push(this.us.createTimeTracking(tracking));
      });
      concat(...requests).subscribe((result) => {
        this.location.back();
        this.ns.showNotification('Buchungen angelegt');
      });
    } else {
      this.store.dispatch(
        PlanningActions.createTimeTracking({
          body: this.getTrackingData(),
        }),
      );
    }
  }

  getWorkDonePercentage() {
    this.workDone$ = this.task$.pipe(
      map((task) => (task.ARBEITIST / task.ARBEITPLAN) * 100),
    );
  }

  onMachineTrackingChanged(event) {
    this.loadPositions();
  }
}
