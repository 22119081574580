import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoreService, UserService } from 'proleis-rest-client';
import { forkJoin } from 'rxjs';
import { ProleisObject } from 'proleis-rest-client/lib/models/proleis-object-models/proleis.object';

@Component({
  templateUrl: 'user.info.panel.component.html',
  selector: 'p4m-user-info-panel',
})
export class UserInfoPanelComponent implements OnInit {
  currentUser: ProleisObject;
  area: ProleisObject;
  location: ProleisObject;
  costCentre: ProleisObject;
  supplier: ProleisObject;

  constructor(
    private route: ActivatedRoute,
    private us: UserService,
    private cs: CoreService,
  ) {}

  ngOnInit() {
    this.us.getCurrentUser().subscribe((user) => {
      this.currentUser = user;
      forkJoin({
        area: this.cs.getObjectById<ProleisObject>(user.FBEREICH_ID),
        location: this.cs.getObjectById<ProleisObject>(user.STANDORT_ID),
        costCentre: this.cs.getObjectById<ProleisObject>(user.KOSTENSTELLE_ID),
        supplier: this.cs.getObjectById<ProleisObject>(user.SUPPLIER_ID),
      }).subscribe((result) => {
        this.supplier = result.supplier;
        this.location = result.location;
        this.costCentre = result.costCentre;
        this.area = result.area;
      });
    });
  }
}
