<mat-card>
  <mat-card-title> Meine Vorgänge </mat-card-title>
  <mat-card-content *ngIf="displayedTasks">
    <mat-list style="max-width: 100%">
      <mat-list-item *ngFor="let task of displayedTasks">
        <img matListIcon src="{{ task.ICON_URL }}" />
        <span matLine>{{ task.TEXT }}</span>
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
      </mat-list-item>
    </mat-list>
    <mat-paginator
      #taskPaginator
      pageSize="5"
      [pageSizeOptions]="[5, 10]"
      [length]="tasks.length"
      (page)="onTaskPaginatorEvent($event)"></mat-paginator>
  </mat-card-content>
  <mat-card-actions align="end">
    <button
      mat-button
      color="accent"
      *ngIf="ds.isDashboardEditable() | async"
      (click)="openOptionsBottomSheet()">
      EDIT
    </button>
    <button
      mat-button
      color="primary"
      *ngIf="ds.isDashboardEditable() | async"
      (click)="delete()">
      <mat-icon>remove</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>

<mat-menu #menu>
  <button mat-menu-item>
    <mat-icon>play_arrow</mat-icon>
    <span>Buchung starten</span>
  </button>
  <button mat-menu-item>
    <mat-icon>stop</mat-icon>
    <span>Buchung stoppen</span>
  </button>
  <button mat-menu-item>
    <mat-icon>report</mat-icon>
    <span>Vorfall anlegen</span>
  </button>
</mat-menu>
