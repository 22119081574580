import { Component, OnInit } from '@angular/core';
import { DashboardItemsService } from '../../../services/dashboard.items.service';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DashboardService, DashboardTemplate } from 'proleis-web-dashboard';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'proleis-web-app';

@Component({
  selector: 'p4m-create-dashboard',
  templateUrl: 'create.dashboard.component.html',
})
export class CreateDashboardComponent implements OnInit {
  icon: string;
  name: string;
  description: string;
  selectedTemplate: string;
  templates: string[];

  constructor(
    private dis: DashboardItemsService,
    private ds: DashboardService,
    private ref: MatBottomSheetRef,
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
  ) {}

  ngOnInit() {
    const config: any = this.appConfigService.appConfig;
    this.templates = config.dashboard.templates;
  }

  createDashboard() {
    this.dis.addDashboard({
      name: this.name,
      description: this.description,
      icon: this.icon,
    });

    this.ds.addDashboardModel(this.name, {
      items: [],
    });

    if (this.selectedTemplate) {
      this.httpClient
        .get<DashboardTemplate>(
          '/assets/dashboard-templates/' + this.selectedTemplate,
        )
        .subscribe((template) => {
          template.items.forEach((item) => {
            item.dashboardName = this.name;
            item.options = {};
          });
          this.ds.applyTemplate(this.name, template);
        });
    }

    this.ref.dismiss();
  }

  dismiss() {
    this.ref.dismiss();
  }
}
