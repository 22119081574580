import { NgModule } from '@angular/core';
import { PpsBoardComponent } from './components/pps/board/pps.board.component';
import { Route, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ProleisWebAppModule } from 'proleis-web-app';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserBookingsComponent } from './components/pps/bookings/user.bookings.component';
import { ProleisWebSearchModule } from 'proleis-web-search';
import { TranslateModule } from '@ngx-translate/core';
import { AuthGuard } from '../../p4m/auth.guard';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ScanPartComponent } from './components/pps/overlays/scan-part.component';
import { ScannerModule } from 'proleis-web-scanner';
import { ViewTasksComponent } from './components/pps/view-tasks/view-tasks.component';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { PpsService } from './components/pps/board/pps.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DurationInputDirective } from './components/duration/duration-input.directive';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { GeneralModule } from '../general/general.module';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { BookingComponent } from './components/pps/bookings/booking.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { EditBookingComponent } from './components/pps/bookings/edit-booking/edit-booking.component';
import { EditFilterBottomsheetComponent } from './components/pps/bookings/edit-filter.bottomsheet/edit-filter.bottomsheet.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatPaginatorModule } from '@angular/material/paginator';
import { LaneComponent } from './components/pps/board/lanes/lane.component';

const routes: Route[] = [
  {
    path: '',
    component: PpsBoardComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [{ name: 'P4_SHOW_MY_PROLEIS_TASKS' }],
    },
  },
  {
    path: 'partscan',
    component: ScanPartComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [{ name: 'P4_SHOW_MY_PROLEIS_TASKS' }],
    },
  },
  {
    path: 'bookings',
    component: UserBookingsComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [{ name: 'P4_SHOW_MY_PROLEIS_TASKS' }],
    },
  },
  {
    path: 'bookings/:id/edit',
    component: EditBookingComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [{ name: 'P4_SHOW_MY_PROLEIS_TASKS' }],
    },
  },
  {
    path: ':partId/tasks',
    component: ViewTasksComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: [{ name: 'P4_SHOW_MY_PROLEIS_TASKS' }],
    },
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatCardModule,
    MatButtonModule,
    MatBottomSheetModule,
    MatInputModule,
    MatIconModule,
    MatTabsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    FormsModule,
    ProleisWebAppModule,
    MatSelectModule,
    MatMenuModule,
    MatListModule,
    MatCheckboxModule,
    ProleisWebSearchModule,
    TranslateModule,
    ReactiveFormsModule,
    ScannerModule,
    MatTableModule,
    MatExpansionModule,
    MatTooltipModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonToggleModule,
    HighchartsChartModule,
    GeneralModule,
    MatRadioModule,
    MatPaginatorModule,
  ],
  exports: [
    PpsBoardComponent,
    ScanPartComponent,
    ViewTasksComponent,
    BookingComponent,
    LaneComponent,
  ],
  declarations: [
    PpsBoardComponent,
    UserBookingsComponent,
    ScanPartComponent,
    ViewTasksComponent,
    DurationInputDirective,
    BookingComponent,
    EditBookingComponent,
    EditFilterBottomsheetComponent,
    LaneComponent,
  ],
  providers: [PpsService],
})
export class PlanningModule {}
