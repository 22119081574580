import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { of } from 'rxjs';
import { ClientAuthService, NotificationService } from 'proleis-web-app';
import { mergeMap, tap } from 'rxjs/operators';
import { RestoreUrlService } from './restore.url.service';

export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const clientAuth = inject(ClientAuthService);
  const router = inject(Router);
  const urlService = inject(RestoreUrlService);
  const notificationService = inject(NotificationService);

  return clientAuth.isAuthenticated$.pipe(
    tap((isAuthenticated) => console.log('is authenticated:', isAuthenticated)),
    mergeMap((isAuthenticated) => {
      if (route.data.permissions) {
        return clientAuth.checkPermissions(route.data.permissions);
      }
      return of(true);
    }),
    tap((checkedScopes) => {
      if (!checkedScopes) {
        notificationService.showNotification(
          'Sie sind nicht berechtigt diesen Inhalt aufzurufen!',
        );
        urlService.addUrl(route.url);
        router.navigate(['home']).then();
      }
    }),
  );
};
