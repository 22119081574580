import { Injectable } from '@angular/core';
import { Router, UrlSegment } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RestoreUrlService {
  private url: string;

  constructor(private router: Router) {}

  addUrl(segments: UrlSegment[]) {
    this.url = this.getUrlFromSegments(segments);
    localStorage.setItem('p4m_restoreUrl', this.url);
  }

  private getUrlFromSegments(segments: UrlSegment[]): string {
    return segments.map((s) => s.path).join('/');
  }

  private removeUrl() {
    this.url = undefined;
  }

  hasStoredUrl(): boolean {
    return !!this.url || !!localStorage.getItem('p4m_restoreUrl');
  }

  restoreUrl() {
    if (this.hasStoredUrl()) {
      this.router.navigate([this.url]).then((navigated) => {
        this.removeUrl();
      });
    }
  }
}
