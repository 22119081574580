import { createAction, props } from '@ngrx/store';
import { Incident, Param, ProleisObject } from 'proleis-rest-client';

export const loadIncidents = createAction(
  '[Incident List] load incidents',
  props<{
    start?: Date;
    end?: Date;
    category?: string;
    link?: string;
    description?: string;
    status?: string;
  }>(),
);

export const onLoadIncidentsSuccess = createAction(
  '[Incident List] on incidents loaded',
  props<{ incidents: Incident[] }>(),
);

export const onSelectIncident = createAction('[Incident List] select incident');

export const onIncidentSelected = createAction(
  '[Incident List] on incident selected',
);

export const createIncident = createAction(
  '[Incident List] create incident',
  props<{
    params: Param[];
    files?: File[];
  }>(),
);

export const onCreateIncidentSuccess = createAction(
  '[Incident List] on create incident success',
  props<Incident>(),
);

export const deleteIncident = createAction(
  '[Incident Details] delete incident',
  props<{ incidentId: number }>(),
);

export const onIncidentDeletedSuccess = createAction(
  '[Incident Details] on incident deleted success',
);

export const loadMainCategories = createAction(
  '[Create Incident] load main categories',
);

export const onLoadMainCategoriesSuccess = createAction(
  '[Create Incident] on load main categories success',
  props<{ mainCategories: ProleisObject[] }>(),
);
