import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DashboardItemsService } from './modules/dashboard/services/dashboard.items.service';
import { Dashboard } from './modules/dashboard/models/dashboard';
import { DashboardService } from 'proleis-web-dashboard';
import { MachineMainIdleTimesComponent } from './modules/dashboard/components/widgets/machine-main-idle-times/machine.main.idle.times.component';
import { FavoritesComponent } from './modules/dashboard/components/widgets/favorites/favorites.component';
import { DashboardTaskComponent } from './modules/dashboard/components/widgets/tasks/dashboard.task.component';
import { CapacityHeatmapComponent } from './modules/dashboard/components/widgets/capacity-heatmap/capacity.heatmap.component';
import { DashboardIncidentsComponent } from './modules/dashboard/components/widgets/incidents/dashboard.incidents.component';
import { DashboardDeliveryInfoComponent } from './modules/dashboard/components/widgets/deliveryinfo/dashboard.delivery.info.component';
import { LogisticsActionsComponent } from './modules/dashboard/components/widgets/logistics/logistics.actions.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CreateDashboardComponent } from './modules/dashboard/components/views/create-dashboard/create.dashboard.component';
import { MatSidenav } from '@angular/material/sidenav';
import {
  AppLayoutComponent,
  BreakpointService,
  ClientAuthService,
  CurrentViewService,
  LanguageService,
  SidenavData,
} from 'proleis-web-app';
import { ProleisObject } from 'proleis-rest-client';
import { TranslateService } from '@ngx-translate/core';
import { CapacityWorkloadComponent } from './modules/dashboard/components/widgets/capacity-workload/capacity.workload.component';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { RestoreUrlService } from './p4m/restore.url.service';

@Component({
  selector: 'p4m-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  dashboards: Map<string, Dashboard>;
  layoutMode: string = 'sales';
  isSmall: Observable<boolean>;

  sidenavData: SidenavData[] = undefined;

  @ViewChild('menuSidenav') sidenav: MatSidenav;
  @ViewChild('layout') appLayout: AppLayoutComponent;

  user: ProleisObject | null;

  constructor(
    private dsi: DashboardItemsService,
    private ds: DashboardService,
    private bs: MatBottomSheet,
    public clientAuth: ClientAuthService,
    private translate: TranslateService,
    private cvs: CurrentViewService,
    public breakPointService: BreakpointService,
    private router: Router,
    private languageService: LanguageService,
    private restoreUrlService: RestoreUrlService,
  ) {
    this.cvs.setMenuToggled(false);
  }

  ngOnInit(): void {
    this.setupLang();
    this.registerDashboards();
    this.clientAuth.validateAuth();
    this.configureLayout();
    this.setupMenu();

    this.clientAuth.isAuthenticated$.subscribe((isAuthenticated) => {
      if (this.restoreUrlService.hasStoredUrl()) {
        this.restoreUrlService.restoreUrl();
      }
    });
  }

  configureLayout() {
    this.isSmall = this.breakPointService.isSmall();
    this.breakPointService.isSmall().subscribe((isSmall) => {
      this.layoutMode = isSmall ? 'default' : 'sales';
    });
  }

  createDashboard() {
    this.bs.open(CreateDashboardComponent);
    this.translate.setDefaultLang('de');
  }

  logout() {
    this.router.navigate(['index']).then();
    this.clientAuth.logout();
  }

  setupLang() {
    this.translate.use('de');
    this.languageService.loadLanguagesFromConfig();
    this.languageService.getSelectedLanguage().subscribe((lang) => {
      this.translate.use(lang.code);
    });
  }

  registerDashboards() {
    this.dsi
      .getDashboards()
      .subscribe((dashboards) => (this.dashboards = dashboards));
    this.ds.registerDashboardComponent(
      'Maschinen Haupt- und Nebenzeiten',
      MachineMainIdleTimesComponent,
    );
    this.ds.registerDashboardComponent('Favoriten', FavoritesComponent);
    this.ds.registerDashboardComponent(
      'Meine Vorgänge',
      DashboardTaskComponent,
    );
    this.ds.registerDashboardComponent(
      'Heatmap Ressourcenauslastung',
      CapacityHeatmapComponent,
    );
    this.ds.registerDashboardComponent(
      'Vorfälle im aktuellen Monat',
      DashboardIncidentsComponent,
    );
    this.ds.registerDashboardComponent(
      'Lieferzustand',
      DashboardDeliveryInfoComponent,
    );
    this.ds.registerDashboardComponent(
      'Logistik Aktionen',
      LogisticsActionsComponent,
    );
    this.ds.registerDashboardComponent(
      'Kapzitätsauslastung',
      CapacityWorkloadComponent,
    );
  }

  setupMenu() {
    this.translate.onLangChange.subscribe((result) => {
      const keys: string[] = [
        'home.tasks',
        'home.tasksDescription',
        'home.bookings',
        'home.bookingsDescription',
        'home.qualityManagement',
        'home.qualityManagementDescription',
        'home.production',
        'home.productionDescription',
        'home.logistics',
        'home.logisticsDescription',
        'home.dashboard',
        'home.dashboardDescription',
      ];
      const translation$ = this.translate.get(keys);
      translation$.subscribe((result) => {
        this.sidenavData = [
          {
            name: result['home.tasks'],
            description: result['home.tasksDescription'],
            permission: 'P4M_SHOW_MY_TASKS',
            route: 'planning',
            icon: 'schedule',
          },
          {
            name: result['home.bookings'],
            description: result['home.bookingsDescription'],
            permission: 'P4M_SHOW_MY_BOOKINGS',
            route: 'planning/bookings',
            icon: 'schedule',
          },
          {
            name: result['home.qualityManagement'],
            description: result['home.qualityManagementDescription'],
            permission: 'P4M_SHOW_QUALITY_APP_LIST',
            route: 'quality',
            icon: 'report_problem',
          },
          {
            name: result['home.production'],
            description: result['home.productionDescription'],
            permission: 'P4M_SHOW_FPE',
            route: 'fpe',
            icon: 'folder',
          },
          {
            name: result['home.logistics'],
            description: result['home.logisticsDescription'],
            permission: 'P4M_SHOW_LOGISTICS_APP_LIST',
            route: 'logistics',
            icon: 'local_shipping',
          },
          /**
           {
           name: result['home.dashboard'],
           description: result['home.dashboardDescription'],
           permission: 'P4M_SHOW_DASHBOARDS',
           route: 'dashboard',
           icon: 'dashboard'
           }
           */
        ];
      });
    });
  }
}
