import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Dashboard } from '../models/dashboard';

@Injectable({
  providedIn: 'root',
})
export class DashboardItemsService {
  dashboards: BehaviorSubject<Map<string, Dashboard>>;

  constructor() {
    if (localStorage.getItem('p4m-dashboards')) {
      this.dashboards = new BehaviorSubject<Map<string, Dashboard>>(
        this.loadDashboards(),
      );
    } else {
      this.dashboards = new BehaviorSubject<Map<string, Dashboard>>(new Map());
    }
  }

  addDashboard(item: Dashboard) {
    this.dashboards.getValue().set(item.name, item);
    this.dashboards.next(this.dashboards.getValue());
    this.persist();
  }

  removeDashboard(name: string) {
    this.dashboards.getValue().delete(name);
    this.dashboards.next(this.dashboards.getValue());
    this.persist();
  }

  getDashboards(): Observable<Map<string, Dashboard>> {
    return this.dashboards.asObservable();
  }

  private persist() {
    const entries = Array.from(this.dashboards.getValue().entries());
    const json = JSON.stringify(entries);
    localStorage.setItem('p4m-dashboards', json);
  }

  private loadDashboards(): Map<string, Dashboard> {
    return new Map<string, Dashboard>(
      JSON.parse(localStorage.getItem('p4m-dashboards')),
    );
  }
}
