import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { PseInfoPanelComponent } from './components/info-panels/pse/pse.info.panel.component';
import { TaskInfoPanelComponent } from './components/info-panels/task/task.info.panel.component';
import { ProleisWebAppModule } from 'proleis-web-app';
import { CommonModule, DatePipe } from '@angular/common';
import { HighchartsChartModule } from 'highcharts-angular';
import { ProleisWebDirectivesModule } from 'proleis-web-directives';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatListModule } from '@angular/material/list';
import { IncidentInfoPanelComponent } from './components/info-panels/incident/incident.info.panel.component';
import { MatButtonModule } from '@angular/material/button';
import { RootPseInfoPanelComponent } from './components/info-panels/pse/root.pse.info.panel.component';
import { PositionInfoPanelComponent } from './components/info-panels/pse/position.info.panel.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MsInfoPanelComponent } from './components/info-panels/ms/ms.info.panel.component';
import { CartInfoPanelComponent } from './components/info-panels/cart/cart.info.panel.component';
import { BookingInfoPanelComponent } from './components/info-panels/booking/booking.info.panel.component';
import { UserInfoPanelComponent } from './components/info-panels/user/user.info.panel.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ProleisWebGalleryModule } from 'proleis-web-gallery';
import { TranslateModule } from '@ngx-translate/core';
import { AuthGuard } from '../../p4m/auth.guard';
import { MatExpansionModule } from '@angular/material/expansion';
import { ListInfoCardComponent } from './components/card/list.info.card.component';
import { NewTimeTrackingComponent } from './components/create-timetracking/new-time-tracking.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MultiTrackingComponent } from './components/create-timetracking/multi.tracking.component';
import { AdditionalTrackingComponent } from './components/create-timetracking/additional.tracking.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DurationPipe } from './components/create-timetracking/duration.pipe';
import { ExpandableCardComponent } from './components/expandable-card/expandable-card.component';
import { VcInfopanelComponent } from './components/info-panels/vc/vc-infopanel.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MobileTaskComponent } from './components/mobile-task/mobile-task.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OrderInfoPanelComponent } from './components/info-panels/order/order.info.panel.component';
import { ClampingComponent } from './components/info-panels/clamping/clamping.component';
import { DocumentListComponent } from './components/document-list/document-list.component';
import { CreateIncidentComponent } from './components/create-incident/create-incident.component';
import { FileInputModule } from 'proleis-web-file-input';
import { P4mIncidentsService } from './services/incidents/p4mIncidentsService';
import { IncidentsRestProleisService } from './services/incidents/incidents-rest-proleis.service';
import { EditIncidentComponent } from './components/info-panels/incident/edit-incident/edit-incident.component';
import { QrCodeComponent } from './components/qr-code/qr-code.component';
import { QRCodeModule } from 'angularx-qrcode';

const routes: Route[] = [
  {
    path: 'general/info-panels/task/:id',
    component: TaskInfoPanelComponent,
    canActivate: [AuthGuard],
    data: { permissions: [{ name: 'P4M_SHOW_INFOVIEW_TASK' }] },
  },
  {
    path: 'general/info-panels/task/:id/track',
    component: NewTimeTrackingComponent,
    canActivate: [AuthGuard],
    data: { permissions: [{ name: 'P4M_SHOW_INFOVIEW_TASK' }] },
  },
  {
    path: 'general/info-panels/pse/:id',
    component: RootPseInfoPanelComponent,
    canActivate: [AuthGuard],
    data: { permissions: [{ name: 'P4M_SHOW_INFOVIEW_PSE' }] },
  },
  {
    path: 'general/info-panels/position/:id',
    component: PositionInfoPanelComponent,
    canActivate: [AuthGuard],
    data: { permissions: [{ name: 'P4M_SHOW_INFOVIEW_POSITION' }] },
  },
  {
    path: 'general/info-panels/cart/:id',
    component: CartInfoPanelComponent,
    canActivate: [AuthGuard],
    data: { permissions: [{ name: 'P4M_SHOW_INFOVIEW_CART' }] },
  },
  {
    path: 'general/info-panels/incident/:id',
    component: IncidentInfoPanelComponent,
    canActivate: [AuthGuard],
    data: { permissions: [{ name: 'P4M_SHOW_INFOVIEW_INCIDENT' }] },
  },
  {
    path: 'general/incident/:id/edit',
    component: EditIncidentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'general/info-panels/ms/:id',
    component: MsInfoPanelComponent,
    canActivate: [AuthGuard],
    data: { permissions: [{ name: 'P4M_SHOW_INFOVIEW_MS' }] },
  },
  {
    path: 'general/info-panels/booking/:objectId/:bookingId',
    component: BookingInfoPanelComponent,
    canActivate: [AuthGuard],
    data: { permissions: [{ name: 'P4M_SHOW_INFOVIEW_BOOKING' }] },
  },
  {
    path: 'general/info-panels/current-user/',
    component: UserInfoPanelComponent,
    canActivate: [AuthGuard],
    data: { permissions: [{ name: 'P4M_SHOW_INFOVIEW_USER' }] },
  },
  {
    path: 'general/info-panels/vc/:vcId',
    component: VcInfopanelComponent,
    canActivate: [AuthGuard],
    data: { permissions: [{ name: 'P4M_SHOW_INFOVIEW_TASK' }] },
  },
  {
    path: 'general/info-panels/order/:orderId',
    component: OrderInfoPanelComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'general/info-panels/clamping/:clampingId',
    component: ClampingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'incidents/:referenceId/create',
    component: CreateIncidentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'general/qr-code/:objectId',
    component: QrCodeComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    ProleisWebAppModule,
    MatCardModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    HighchartsChartModule,
    ProleisWebDirectivesModule,
    MatListModule,
    FormsModule,
    MatTabsModule,
    MatCheckboxModule,
    MatChipsModule,
    MatMenuModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatChipsModule,
    ProleisWebGalleryModule,
    TranslateModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    FileInputModule,
    QRCodeModule,
  ],
  declarations: [
    PseInfoPanelComponent,
    TaskInfoPanelComponent,
    IncidentInfoPanelComponent,
    RootPseInfoPanelComponent,
    PositionInfoPanelComponent,
    MsInfoPanelComponent,
    CartInfoPanelComponent,
    BookingInfoPanelComponent,
    UserInfoPanelComponent,
    ListInfoCardComponent,
    NewTimeTrackingComponent,
    MultiTrackingComponent,
    AdditionalTrackingComponent,
    DurationPipe,
    ExpandableCardComponent,
    VcInfopanelComponent,
    ConfirmDialogComponent,
    MobileTaskComponent,
    OrderInfoPanelComponent,
    ClampingComponent,
    DocumentListComponent,
    CreateIncidentComponent,
    EditIncidentComponent,
    QrCodeComponent,
  ],
  exports: [
    PseInfoPanelComponent,
    TaskInfoPanelComponent,
    IncidentInfoPanelComponent,
    RootPseInfoPanelComponent,
    PositionInfoPanelComponent,
    MsInfoPanelComponent,
    CartInfoPanelComponent,
    BookingInfoPanelComponent,
    UserInfoPanelComponent,
    ListInfoCardComponent,
    NewTimeTrackingComponent,
    MultiTrackingComponent,
    AdditionalTrackingComponent,
    DurationPipe,
    ExpandableCardComponent,
    VcInfopanelComponent,
    MobileTaskComponent,
    OrderInfoPanelComponent,
  ],
  providers: [
    DatePipe,
    {
      provide: P4mIncidentsService,
      useClass: IncidentsRestProleisService,
      multi: false,
    },
  ],
})
export class GeneralModule {}
