<div class="logo-container">
  <img class="logo" src="assets/tebis_logo.svg" (click)="onLogoClicked()" />
  <div style="flex: 1 0 auto"></div>
  <button mat-button (click)="closeMenu()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-nav-list *ngIf="clientAuth.isAuthenticated$ | async">
  <span mat-subheader [innerHTML]="'home.applications' | translate"></span>
  <mat-list-item
    routerLink="planning"
    (click)="closeMenu()"
    *checkPermission="[{ name: 'P4M_SHOW_MY_TASKS' }]">
    <mat-icon matListItemAvatar>schedule</mat-icon>
    <span matListItemTitle [innerHTML]="'home.tasks' | translate"></span>
    <span
      matListItemLine
      [innerHTML]="'home.tasksDescription' | translate"></span>
  </mat-list-item>
  <mat-list-item
    routerLink="planning/bookings"
    (click)="closeMenu()"
    *checkPermission="[{ name: 'P4M_SHOW_MY_BOOKINGS' }]">
    <mat-icon matListItemAvatar>schedule</mat-icon>
    <span matListItemTitle [innerHTML]="'home.bookings' | translate"></span>
    <span
      matListItemLine
      [innerHTML]="'home.bookingsDescription' | translate"></span>
  </mat-list-item>
  <mat-list-item
    routerLink="quality"
    (click)="closeMenu()"
    *checkPermission="[{ name: 'P4M_SHOW_QUALITY_APP_LIST' }]">
    <mat-icon matListItemAvatar>report_problem</mat-icon>
    <span
      matListItemTitle
      [innerHTML]="'home.qualityManagement' | translate"></span>
    <span
      matListItemLine
      [innerHTML]="'home.qualityManagementDescription' | translate"></span>
  </mat-list-item>
  <mat-list-item
    routerLink="fpe"
    (click)="closeMenu()"
    *checkPermission="[{ name: 'P4M_SHOW_FPE' }]">
    <mat-icon matListItemAvatar>folder</mat-icon>
    <span matListItemTitle [innerHTML]="'home.production' | translate"></span>
    <span matListItemLine [innerHTML]="'home.productionDescription' | translate"
      >Scanner/Suche</span
    >
  </mat-list-item>
  <mat-list-item
    routerLink="logistics"
    (click)="closeMenu()"
    *checkPermission="[{ name: 'P4M_SHOW_LOGISTICS_APP_LIST' }]">
    <mat-icon matListItemAvatar>local_shipping</mat-icon>
    <span matListItemTitle [innerHTML]="'home.logistics' | translate"></span>
    <span
      matListItemLine
      [innerHTML]="'home.logisticsDescription' | translate"></span>
  </mat-list-item>
</mat-nav-list>

<div
  style="
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
  ">
  <mat-divider></mat-divider>
  <button mat-icon-button (click)="clientAuth.logout()">
    <mat-icon>vpn_key</mat-icon>
  </button>
</div>
