import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { qualityFeatureKey, qualityReducer } from './quality.reducer';
import { EffectsModule } from '@ngrx/effects';
import { QualityEffects } from './quality.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(qualityFeatureKey, qualityReducer),
    EffectsModule.forFeature([QualityEffects]),
  ],
})
export class QualityStateModule {}
