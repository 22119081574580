import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProleisForMobileState } from '../../../../store/store.index';
import { ActivatedRoute } from '@angular/router';
import { GeneralActions } from '../../../../store/general/general.index';
import { selectCartViewModel } from '../../../../store/general/general.selectors';
import { CartViewModel } from '../../../../store/general/general.state';
import { DeliveryInfo } from '../../../../store/dashboard/dashboard.state';
import { selectDashboardDeliveryInfo } from '../../../../store/dashboard/dashboard.selectors';
import { DashboardActions } from '../../../../store/dashboard/dashboard.index';

@Component({
  selector: 'p4m-cart-info-panel',
  templateUrl: 'cart.info.panel.component.html',
})
export class CartInfoPanelComponent implements OnInit {
  viewModel: CartViewModel;
  deliveryInfo: DeliveryInfo;

  constructor(
    private route: ActivatedRoute,
    private store: Store<ProleisForMobileState>,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const id = params.id;

      if (id) {
        this.store.dispatch(GeneralActions.onLoadCartViewModel({ cartId: id }));
        this.store.dispatch(DashboardActions.loadDeliveryState({ id }));
      }

      this.store
        .select(selectDashboardDeliveryInfo, id)
        .subscribe((deliveryInfo) => {
          this.deliveryInfo = deliveryInfo;
        });
    });

    this.store.select(selectCartViewModel).subscribe((model) => {
      this.viewModel = model;
    });
  }
}
