<div>
  <mat-form-field style="width: 100%">
    <input
      [formControl]="nameControl"
      matInput
      [placeholder]="'quality.createIncident.createName' | translate"
      style="width: 100%" />
  </mat-form-field>
</div>

<div>
  <mat-form-field style="width: 100%">
    <textarea
      [formControl]="descriptionControl"
      matInput
      [placeholder]="
        'quality.createIncident.createDescription' | translate
      "></textarea>
  </mat-form-field>
</div>

<div>
  <mat-form-field style="width: 100%">
    <mat-select
      [formControl]="mainCategoryControl"
      [placeholder]="'quality.createIncident.createCategory' | translate">
      <mat-option *ngFor="let category of mainCategories" [value]="category">
        {{ category.NAME }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div>
  <pw-file-input
    [multiple]="true"
    (filesChanged)="onFileSelected($event)"></pw-file-input>
</div>

<mat-divider></mat-divider>

<div style="display: flex; justify-content: flex-end; margin: 20px">
  <button mat-button color="primary" (click)="closeBottomSheet()">
    <span
      [innerHTML]="'quality.createIncident.createDiscard' | translate"></span>
  </button>
  <button mat-raised-button color="primary" (click)="createIncident()">
    <span [innerHTML]="'quality.createIncident.createSave' | translate"></span>
  </button>
</div>
