import { createAction, props } from '@ngrx/store';
import {
  CartViewModel,
  IncidentViewModel,
  PositionViewModel,
  PseViewModel,
  RootPseViewModel,
  TaskViewModel,
} from './general.state';
import { Milestone } from 'proleis-rest-client';

export const initializePseView = createAction(
  '[PSE Info] initialize pse view',
  props<{ pseId: string }>(),
);

export const onInitializePseViewSuccess = createAction(
  '[PSE Info] initialize pse view success',
  props<{ viewModel: PseViewModel }>(),
);

export const initializeIncidentView = createAction(
  '[Incident Info] initialize incident view',
  props<{ incidentId: string }>(),
);

export const onInitializeIncidentViewSuccess = createAction(
  '[Incident Info] initialize incident view success',
  props<{ viewModel: IncidentViewModel }>(),
);

export const onLoadRootPseView = createAction(
  '[ROOT PSE INFO] load root pse information',
  props<{ pseId: string }>(),
);

export const onRootPseViewLoaded = createAction(
  '[ROOT PSE INFO] root pse view loaded',
  props<{ viewModel: RootPseViewModel }>(),
);

export const onLoadPositionViewModel = createAction(
  '[POS INFO] position view model loaded',
  props<{ posId: string }>(),
);

export const onPositionViewModelLoaded = createAction(
  '[POS INFO] position info view loaded',
  props<{ viewModel: PositionViewModel }>(),
);

export const onLoadTaskViewModel = createAction(
  '[TASK INFO] load task viewmodel',
  props<{ taskId: string }>(),
);

export const onTaskViewModelLoaded = createAction(
  '[TASK INFO] task viewmodel loaded',
  props<{ viewModel: TaskViewModel }>(),
);

export const onLoadMs = createAction(
  '[MS INFO] load ms info',
  props<{ msId: string }>(),
);

export const onMsLoaded = createAction(
  '[MS INFO] ms loaded',
  props<{ ms: Milestone }>(),
);

export const onLoadCartViewModel = createAction(
  '[CART INFO] load cart info',
  props<{ cartId: string }>(),
);

export const onCartViewModelLoaded = createAction(
  '[CART INFO] cart info loaded',
  props<{ viewModel: CartViewModel }>(),
);
