import { Pipe, PipeTransform } from '@angular/core';
import { minHourFormat, stringFormat } from './duration.validator';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(value: number, ...args: any[]) {
    return DurationPipe.toText(value);
  }

  static toMinutes(duration: string): number {
    if (duration.includes(',')) {
      duration = duration.replace(',', '.');
    }
    let matched = false;
    if (minHourFormat.test(duration)) {
      const split = duration.split(':');
      const hours = Number(split[0]);
      const minutes = Number(split[1]);
      return hours * 60 + minutes;
    }

    if (stringFormat.test(duration)) {
      const mRegEx = new RegExp(/([0-9][0-9]?)[ ]?m/);
      const hRegEx = new RegExp(/([0-9][0-9]?)[ ]?h/);
      let minutes = 0;
      let hours = 0;
      if (mRegEx.test(duration)) {
        minutes = Number(mRegEx.exec(duration)[1]);
      }
      if (hRegEx.test(duration)) {
        hours = Number(hRegEx.exec(duration)[1]);
      }
      return hours * 60 + minutes;
    }

    return 60 * Number(duration);
  }

  static toText(duration: number): string {
    const minutes = duration % 60;
    const hours = (duration - minutes) / 60;

    let result = '';
    if (hours > 0) {
      result += hours + 'h';
    }

    result += ' ' + minutes + 'm';
    return result;
  }
}
