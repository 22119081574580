<div class="p4m-flex-container">
  <mat-form-field>
    <mat-label>Template</mat-label>
    <mat-select [(value)]="selectedTemplate">
      <mat-option [value]="template" *ngFor="let template of templates">
        {{ template }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'dashboard.createDashboard.name' | translate }}</mat-label>
    <input matInput [(ngModel)]="name" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{
      'dashboard.createDashboard.description' | translate
    }}</mat-label>
    <input matInput [(ngModel)]="description" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'dashboard.createDashboard.icon' | translate }}</mat-label>
    <input matInput [(ngModel)]="icon" />
  </mat-form-field>
  <div style="display: flex; justify-content: flex-end; margin: 20px">
    <button mat-button (click)="dismiss()" color="primary">VERWERFEN</button>
    <button mat-raised-button (click)="createDashboard()" color="primary">
      SPEICHERN
    </button>
  </div>
</div>
