<mat-card>
  <mat-card-title>{{
    'dashboard.widgets.favorites.title' | translate
  }}</mat-card-title>
  <mat-card-content>
    <mat-nav-list>
      <mat-list-item
        *ngFor="let fav of favorites | async"
        (click)="onClick(fav)">
        <img matListAvatar src="{{ fav.ICON_URL }}" alt="{{ fav.ICON_URL }}" />
        <p matLine>{{ fav.NAME }}</p>
        <p matLine>{{ fav.DESCRIPTION }}</p>
      </mat-list-item>
    </mat-nav-list>
  </mat-card-content>
  <mat-card-actions align="end">
    <button
      mat-button
      color="primary"
      *ngIf="ds.isDashboardEditable() | async"
      (click)="delete()">
      <mat-icon>remove</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
