<pw-view-title>Vorfall editieren</pw-view-title>

<div class="p4m-grid-2-col mw-70">
  <mat-form-field>
    <mat-label>Status</mat-label>
    <mat-select [(ngModel)]="selectedStatus">
      <mat-option *ngFor="let obj of status$ | async" [value]="obj.id">
        {{ obj.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Priorität</mat-label>
    <mat-select [(ngModel)]="selectedPriority">
      <mat-option *ngFor="let obj of priorities$ | async" [value]="obj.id">
        {{ obj.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Verantwortlicher</mat-label>
    <mat-select [(ngModel)]="selectedResponsible">
      <mat-option *ngFor="let obj of responsibles$ | async" [value]="obj.id">
        {{ obj.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div></div>

  <mat-form-field>
    <mat-label>Hauptkategorie</mat-label>
    <mat-select
      [(ngModel)]="selectedCategory"
      (selectionChange)="loadSubcategories(selectedCategory)">
      <mat-option *ngFor="let obj of categories$ | async" [value]="obj.id">
        {{ obj.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Kategorie</mat-label>
    <mat-select [(ngModel)]="selectedSubCategory">
      <mat-option *ngFor="let obj of subcategories$ | async" [value]="obj.id">
        {{ obj.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div
    style="
      display: flex;
      flex-direction: row;
      grid-column: auto / span 2;
      justify-content: end;
    ">
    <button mat-button color="accent" (click)="discard()">
      {{ 'general.editTracking.discard' | translate }}
    </button>
    <button mat-button color="accent" (click)="save()">
      {{ 'general.editTracking.save' | translate }}
    </button>
  </div>
</div>
