<ng-container *ngIf="part$ | async as part">
  <pw-view-title>
    {{ part.TEXT }}
  </pw-view-title>

  <div class="mw-70">
    <div>
      <div *ngIf="tasks$ | async as tasks">
        <div
          [ngClass]="
            (isPhone$ | async) ? 'search-container-mobile' : 'search-container'
          ">
          <mat-slide-toggle [formControl]="onlyAssignedTasks"
            >Meine Vorgänge</mat-slide-toggle
          >
          <mat-form-field style="width: 100%">
            <input matInput placeholder="Filter" />
          </mat-form-field>
        </div>
        <div class="mat-elevation-z6" *ngIf="tasks.length > 0; else noTasks">
          <ng-container *ngFor="let task of tasks$ | async">
            <mat-divider></mat-divider>
            <p4m-mobile-task-component
              [task]="task"
              (onStartTracking)="startBooking($event)"
              (taskUpdated)="onTaskUpdated()"></p4m-mobile-task-component>
          </ng-container>
        </div>
      </div>

      <ng-template #noTasks>
        <div *ngIf="!(ls.isLoading | async); else loadingTasks">
          Keine Vorgänge für {{ part.TEXT }} vorhanden.
        </div>
      </ng-template>
      <ng-template #loadingTasks>
        <div>Vorgänge werden geladen.</div>
      </ng-template>
    </div>
  </div>
</ng-container>
