import { Component, OnInit } from '@angular/core';
import { GeneralActions } from '../../../../store/general/general.index';
import { selectPositionViewModel } from '../../../../store/general/general.selectors';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ProleisForMobileState } from '../../../../store/store.index';
import { BreakpointService } from 'proleis-web-app';
import { PositionViewModel } from '../../../../store/general/general.state';
import { RedirectService } from '../../../services/redirect.service';

@Component({
  selector: 'p4m-position-info-panel',
  templateUrl: 'position.info.panel.component.html',
})
export class PositionInfoPanelComponent implements OnInit {
  viewModel: PositionViewModel;

  constructor(
    private route: ActivatedRoute,
    private store: Store<ProleisForMobileState>,
    public breakpointService: BreakpointService,
    private rs: RedirectService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.store.dispatch(
        GeneralActions.onLoadPositionViewModel({
          posId: params.id,
        }),
      );
    });

    this.store.select(selectPositionViewModel).subscribe((model) => {
      this.viewModel = model;
    });
  }

  redirect(objectId: string) {
    this.rs.redirectToInfoPanel(objectId);
  }
}
