import { Observable } from 'rxjs';
import {
  P4mCategory,
  P4mCauser,
  P4mIncident,
  P4mIncidentStatus,
  P4mPriority,
  P4mReferenceObject,
  P4mResponsible,
} from './incident.models';

export abstract class P4mIncidentsService {
  abstract getIncidents(
    start?: Date,
    end?: Date,
    linkId?: string,
    categoryId?: string,
    statusId?: string,
    description?: string,
    mode?: string,
    field?: string,
  ): Observable<P4mIncident[]>;

  abstract createIncident(
    referenceId: string,
    incident: P4mIncident,
    files?: File[],
  ): Observable<P4mIncident>;

  abstract deleteIncident(incidentId: string): Observable<any>;

  abstract loadCategories(): Observable<P4mCategory[]>;

  abstract loadSubcategories(categoryId: string): Observable<P4mCategory[]>;

  abstract loadPriorities(): Observable<P4mPriority[]>;

  abstract loadCausersInternal(): Observable<P4mCauser[]>;

  abstract loadCausersExternal(): Observable<P4mCauser[]>;

  abstract loadReferenceObject(
    objectId: string,
  ): Observable<P4mReferenceObject>;

  abstract loadStatus(): Observable<P4mIncidentStatus[]>;

  abstract loadResponsibles(incidentId): Observable<P4mResponsible[]>;
}
