<pw-view-title>{{ 'general.editTracking.title' | translate }}</pw-view-title>

<div class="p4m-grid-2-col mw-70" *ngIf="form" [formGroup]="form">
  <mat-form-field>
    <mat-label>{{ 'general.editTracking.date' | translate }}</mat-label>
    <input matInput formControlName="date" [matDatepicker]="pickerDate" />
    <mat-datepicker-toggle
      matIconSuffix
      [for]="pickerDate"></mat-datepicker-toggle>
    <mat-datepicker #pickerDate></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'general.editTracking.time' | translate }}</mat-label>
    <input matInput formControlName="workingTime" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'general.editTracking.ratingType' | translate }}</mat-label>
    <mat-select formControlName="ratingTypeId">
      <mat-option
        *ngFor="let obj of ratingTypes$ | async"
        [value]="obj.OBJECT_ID">
        {{ obj.TEXT }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'general.editTracking.serviceType' | translate }}</mat-label>
    <mat-select formControlName="serviceTypeId">
      <mat-option
        *ngFor="let obj of serviceTypes$ | async"
        [value]="obj.OBJECT_ID">
        {{ obj.TEXT }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'general.editTracking.incident' | translate }}</mat-label>
    <mat-select formControlName="incidentId">
      <mat-option
        *ngFor="let obj of incidents$ | async"
        [value]="obj.OBJECT_ID">
        {{ obj.TEXT }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'general.editTracking.comment' | translate }}</mat-label>
    <textarea matInput formControlName="description"></textarea>
  </mat-form-field>

  <div
    style="
      display: flex;
      flex-direction: row;
      grid-column: auto / span 2;
      justify-content: end;
    ">
    <button mat-button color="accent" (click)="discard()">
      {{ 'general.editTracking.discard' | translate }}
    </button>
    <button mat-button color="accent" (click)="save()">
      {{ 'general.editTracking.save' | translate }}
    </button>
  </div>
</div>
