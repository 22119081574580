<pw-view-title *ngIf="ms$ | async as ms">
  {{ ms.TEXT }}
</pw-view-title>

<div class="p4m-grid-2-col">
  <mat-card *ngIf="ms$ | async as ms">
    <mat-card-header>
      <mat-card-title>{{
        'general.infoPanels.ms.title' | translate
      }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div
        style="display: grid; grid-template-columns: auto 1fr; grid-gap: 8px">
        <div style="font-weight: bold">Name</div>
        <div>{{ ms.NAME }}</div>

        <div style="font-weight: bold">Beschreibung</div>
        <div>{{ ms.DESCRIPTION }}</div>

        <div style="font-weight: bold">Typ</div>
        <div>{{ ms.TYP_NAME }}</div>

        <div style="font-weight: bold">Art</div>
        <div>{{ ms.ART_NAME }}</div>

        <div style="font-weight: bold">Soll Termin</div>
        <div>{{ ms.MS_DATUM | date }}</div>

        <div style="font-weight: bold">Ist Termin</div>
        <div>{{ ms.MS_DATUM_FERTIG | date }}</div>

        <div style="font-weight: bold">Ursprünglicher Termin</div>
        <div>{{ ms.INITIAL_DATE | date }}</div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="ms$ | async as ms">
    <mat-card-header>
      <mat-card-title>Status</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div style="display: grid; grid-template-columns: auto 1fr">
        <div style="font-weight: bold">Fertig</div>
        <mat-checkbox disabled [checked]="ms.ISTBEARBEITET"></mat-checkbox>

        <div style="font-weight: bold">Geplant</div>
        <mat-checkbox disabled [checked]="ms.ISTGEPLANT"></mat-checkbox>

        <div style="font-weight: bold">Fixiert</div>
        <mat-checkbox disabled [checked]="ms.ISTFIXIERT"></mat-checkbox>

        <div style="font-weight: bold">Teilentlastet</div>
        <mat-checkbox disabled [checked]="ms.ISTTEILENTLASTET"></mat-checkbox>

        <div style="font-weight: bold">Entlastet</div>
        <mat-checkbox disabled [checked]="ms.ISTENTLASTET"></mat-checkbox>
      </div>
    </mat-card-content>
  </mat-card>
</div>
