<div class="task-card-container">
  <ng-content select=".card-progress"></ng-content>
  <div class="task-card-title {{ titleClass }}">
    <div>
      <ng-content select=".card-title"></ng-content>
    </div>
    <div style="flex: 1 0 auto"></div>
    <div class="task-card-title-actions">
      <button *ngIf="!expanded" mat-icon-button (click)="toggleExpanded()">
        <mat-icon>expand_more</mat-icon>
      </button>
      <button *ngIf="expanded" mat-icon-button (click)="toggleExpanded()">
        <mat-icon>expand_less</mat-icon>
      </button>
    </div>
  </div>

  <mat-divider
    *ngIf="expanded"
    style="padding-top: 8px; padding-bottom: 8px"></mat-divider>

  <div
    class="task-card-content"
    [@expansionTrigger]="expanded ? 'expanded' : 'collapsed'">
    <ng-content select=".card-content"></ng-content>

    <div class="task-card-actions">
      <ng-content select=".card-actions"></ng-content>
    </div>
  </div>
</div>
