import { createAction, props } from '@ngrx/store';
import { ProleisObject, Task } from 'proleis-rest-client';
import { CapacityData, DeliveryInfo, MdeInfo } from './dashboard.state';

export const loadeMdeData = createAction(
  '[Dashboard] load mde data',
  props<{ machineId: string }>(),
);

export const onMdeDataLoaded = createAction(
  '[Dashboard] mde data loaded',
  props<{ mdeInfo: MdeInfo }>(),
);

export const loadFavorites = createAction('[Dashboard] load favorites');

export const onFavoritesLoaded = createAction(
  '[Dashboard] favorites loaded',
  props<{ favorites: ProleisObject[] }>(),
);

export const loadUserResources = createAction(
  '[Dashboard] load user resources',
);

export const onUserResourcesLoaded = createAction(
  '[Dashboard] user resources loaded',
  props<{ resources: ProleisObject[] }>(),
);

export const loadTasks = createAction(
  '[Dashboard] load tasks',
  props<{ resId: string }>(),
);

export const onTasksLoaded = createAction(
  '[Dashboard] tasks loaded',
  props<{ tasks: Task[] }>(),
);

export const loadCapacityHeatmap = createAction(
  '[Dashboard] load capacity heatmap',
  props<{
    startDate: Date;
    endDate: Date;
    queryParentId: string;
    groupField: string;
    filterIds: string;
    includePR: boolean;
    includeVP: boolean;
    onlyCapacityActive: boolean;
    excludeFinished: boolean;
    timeUnit: string;
    calendarId: string;
  }>(),
);

export const onCapacityHeatmapLoaded = createAction(
  '[Dasboard] capacity heatmap loaded',
  props<{ heatmapData: ProleisObject[] }>(),
);

export const loadCapacityWorkload = createAction(
  '[Dashboard] load capacity workload',
  props<{
    start?: Date;
    end?: Date;
    resIds?: string;
    includePr?: boolean;
    includeVp?: boolean;
    onlyCapaActive?: boolean;
    excludeFinished?: boolean;
    timeUnit?: string;
    groupField?: string;
  }>(),
);

export const onCapacityWorkloadLoaded = createAction(
  '[Dashboard] loaded capacity workload',
  props<{ capacityData: CapacityData }>(),
);

export const loadIncidentInfo = createAction(
  '[Dashboard] load incident info',
  props<{
    startDate: Date;
    endDate: Date;
    timeInterval: string;
    groupBy: string;
  }>(),
);

export const onIncidentInfoLoaded = createAction(
  '[Dashboard] on incident info loaded',
  props<{ incidents: ProleisObject[] }>(),
);

export const loadDeliveryState = createAction(
  '[Dashboard] load delivery state',
  props<{ id: string }>(),
);

export const onDeliveryStateLoaded = createAction(
  '[Dashboard] delivery state loaded',
  props<{ deliveryInfo: DeliveryInfo }>(),
);
