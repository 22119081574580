import { QualityState } from './quality.state';
import { createReducer, on } from '@ngrx/store';
import {
  loadIncidents,
  onLoadIncidentsSuccess,
  onLoadMainCategoriesSuccess,
} from './quality.actions';

export const qualityFeatureKey = 'quality';

export const initialState: QualityState = {
  mainCategories: null,
  incidents: null,
  selectedIncident: null,
};

const reducer = createReducer(
  initialState,
  on(loadIncidents, (state) => state),
  on(onLoadIncidentsSuccess, (state, action) => ({
    ...state,
    incidents: action.incidents,
  })),
  on(onLoadMainCategoriesSuccess, (state, action) => ({
    ...state,
    mainCategories: action.mainCategories,
  })),
);

export function qualityReducer(state: QualityState, action) {
  return reducer(state, action);
}
