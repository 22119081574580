import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  AnalyticsService,
  CoreService,
  DocumentService,
  Milestone,
  Param,
  PreviewService,
  ProleisObject,
  Resource,
  ResourceGroup,
  Task,
  Types,
  UrlService,
} from 'proleis-rest-client';
import { Store } from '@ngrx/store';
import { ProleisForMobileState } from '../store.index';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { GeneralActions } from './general.index';
import { forkJoin, Observable, of } from 'rxjs';
import { UiActions } from '../ui/ui.index';
import { CurrentViewService } from 'proleis-web-app';
import { DeliveryInfo } from '../dashboard/dashboard.state';
import { Image } from 'proleis-web-gallery';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import {
  GUID_PATH_OPERATIONS,
  GUID_PATH_PREDECESSORS,
  GUID_PATH_SUCCESSORS,
  GUID_PATH_VIEW,
  GUID_VIEWDEF_AFOS,
  GUID_VIEWDEF_MS,
} from '../../../p4m/guids';

@Injectable()
export class GeneralEffects {
  constructor(
    private actions: Actions,
    private coreService: CoreService,
    private store: Store<ProleisForMobileState>,
    private analyticsService: AnalyticsService,
    private cvs: CurrentViewService,
    private documentService: DocumentService,
    private previewService: PreviewService,
    private http: HttpClient,
    private urlService: UrlService,
    private sanitizer: DomSanitizer,
  ) {}

  initializePseView$ = createEffect(() => {
    return this.actions.pipe(
      ofType(GeneralActions.initializePseView),
      exhaustMap((action) => {
        this.store.dispatch(
          UiActions.setLoading({
            isLoading: true,
          }),
        );
        const params$ = forkJoin(
          this.coreService.getObjectById<Pse>(action.pseId),
          this.coreService.getObjectByGuid<ProleisObject>(GUID_PATH_VIEW),
        );
        return params$.pipe(
          exhaustMap(([pse, viewPath]) => {
            const params: Param[] = [
              {
                name: 'GROUPVIEWDATA_TASKS_LINK.QUERY_PARENT_ID',
                type: Types.DOUBLE,
                value: pse.OBJECT_ID,
              },
            ];
            return forkJoin([
              this.coreService.getObjectById<ProleisObject>(pse.PSETYP_ID),
              this.coreService.getObjectById<ProleisObject>(pse.PR_STATUS_ID),
              this.coreService.getObjectById<ProleisObject>(
                pse.PLANNING_STATUS_ID,
              ),
              this.coreService.getObjectsByGroupView<Task[]>(
                GUID_VIEWDEF_AFOS,
                pse.OBJECT_ID,
                '',
              ),
              this.coreService.getObjectsByGroupView<Milestone[]>(
                GUID_VIEWDEF_MS,
                pse.OBJECT_ID,
                '',
              ),
              this.analyticsService.executeReport(
                'TASK_CONTAINER_COMPARISON_WORKLOAD',
                params,
              ),
              this.coreService.getChildren<ProleisObject[]>(
                pse.OBJECT_ID,
                '§7358c03b-2c95-11b2-807f-cdcf1cc34545',
              ),
            ]).pipe(
              map(
                ([
                  pseType,
                  projectState,
                  planState,
                  tasks,
                  milestones,
                  workloadComparison,
                ]) => {
                  return GeneralActions.onInitializePseViewSuccess({
                    viewModel: {
                      pseType,
                      planState,
                      projectState,
                      milestones,
                      tasks,
                      pse,
                      workloadComparison,
                    },
                  });
                },
              ),
            );
          }),
        );
      }),
    );
  });

  onInitializePseViewSuccess$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(GeneralActions.onInitializePseViewSuccess),
        tap(() =>
          this.store.dispatch(
            UiActions.setLoading({
              isLoading: false,
            }),
          ),
        ),
      );
    },
    {
      dispatch: false,
    },
  );

  onLoadRootPseViewModel$ = createEffect(() => {
    return this.actions.pipe(
      ofType(GeneralActions.onLoadRootPseView),
      exhaustMap((action) => {
        this.cvs.setLoading(true);
        const aliasFields =
          'LAGERORT_NAME=LAGERORT_ID.TEXT;LAGERFACH_NAME=LAGERFACH_ID.TEXT;PRSTATUS=PR_STATUS_ID.DESCRIPTION;' +
          'KONSTRUKTUR=KONSTRUKTEUR_ID.TEXT;MEISTER=MEISTER_ID.TEXT;PLANER=PLANER_ID.TEXT;WERKER=WERKER_ID.TEXT';
        return this.coreService
          .getObjectById<Pse>(action.pseId, aliasFields)
          .pipe(
            exhaustMap((rootPse) => {
              return forkJoin({
                pseType: this.coreService.getObjectById<ProleisObject>(
                  rootPse.PSETYP_ID,
                ),
                milestones: this.getMilestones(rootPse.OBJECT_ID),
                project: this.getProject(rootPse.OBJECT_ID),
                module: this.getModule(rootPse.OBJECT_ID),
                //part: this.getPart(rootPse.OBJECT_ID),
                customer: this.getCustomer(rootPse.OBJECT_ID),
                positions: this.getPositions(rootPse.OBJECT_ID),
                positionsAAA: this.getAAAPositions(rootPse.OBJECT_ID),
                workloadComparison: this.getWorkloadComparison(
                  rootPse.OBJECT_ID,
                ),
                officeDocuments: this.getOfficeDocuments(rootPse.OBJECT_ID),
                caxDocuments: this.getCaxDocuments(rootPse.OBJECT_ID),
                deliveryInfo: this.getDeliveryInfo(rootPse.OBJECT_ID),
                orders: this.coreService.getChildren<ProleisObject[]>(
                  rootPse.OBJECT_ID,
                  '§7358c03b-2c95-11b2-807f-cdcf1cc34545',
                ),
                previewImages: this.previewService.getItems(rootPse.OBJECT_ID),
                clampings: this.getClampingsPse(rootPse.OBJECT_ID),
              }).pipe(
                map((result) => {
                  return GeneralActions.onRootPseViewLoaded({
                    viewModel: {
                      pseType: result.pseType,
                      object: rootPse,
                      project: result.project,
                      module: result.module,
                      // part: result.part,
                      customer: result.customer,
                      milestones: result.milestones,
                      positions: result.positions,
                      positionsAAA: result.positionsAAA,
                      workloadComparison: result.workloadComparison,
                      documentsCax: result.caxDocuments,
                      documentsOffice: result.officeDocuments,
                      deliveryInfo: result.deliveryInfo,
                      orders: result.orders,
                      previewImages: result.previewImages,
                    },
                  });
                }),
              );
            }),
          );
      }),
    );
  });

  onPseViewModelLoaded$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(GeneralActions.onRootPseViewLoaded),
        tap((action) => this.cvs.setLoading(false)),
      );
    },
    { dispatch: false },
  );

  onLoadPositionViewModel = createEffect(() => {
    return this.actions.pipe(
      ofType(GeneralActions.onLoadPositionViewModel),
      exhaustMap((action) => {
        this.cvs.setLoading(true);
        return this.coreService.getObjectById<ProleisObject>(action.posId).pipe(
          exhaustMap((position) => {
            return forkJoin({
              project: this.getProject(position.OBJECT_ID),
              part: this.getPart(position.OBJECT_ID),
              module: this.getModule(position.OBJECT_ID),
              documents: this.getOfficeDocuments(position.OBJECT_ID),
              tasks: this.getTasks(position.OBJECT_ID, false),
              deliveryInfo: this.getDeliveryInfo(position.OBJECT_ID),
              root: this.getRootPse(position.OBJECT_ID),
              customer: this.getCustomer(position.OBJECT_ID),
              positions: this.getPositions(position.OBJECT_ID),
              clampings: this.getClampingsPse(position.OBJECT_ID),
            }).pipe(
              map((result) => {
                return GeneralActions.onPositionViewModelLoaded({
                  viewModel: {
                    position,
                    positions: result.positions,
                    customer: result.customer,
                    project: result.project,
                    part: result.part,
                    module: result.module,
                    deliveryInfo: result.deliveryInfo,
                    rootPse: result.root,
                    tasks: result.tasks,
                    documents: result.documents,
                    clampings: result.clampings,
                  },
                });
              }),
            );
          }),
        );
      }),
    );
  });

  onLoadCartViewModel$ = createEffect(() => {
    return this.actions.pipe(
      ofType(GeneralActions.onLoadCartViewModel),
      exhaustMap((action) => {
        this.cvs.setLoading(true);
        const id = action.cartId;
        return forkJoin({
          object: this.coreService.getObjectById<ProleisObject>(id),
          tasks: this.getTasks(id, false),
          customer: of(null),
          project: of(null),
          part: of(null),
          module: this.getModule(id),
          rootPse: this.getRootPse(id),
          positions: this.getAAAPositions(id),
          officeDocuments: this.getOfficeDocuments(id),
          caxDocuments: this.getCaxDocuments(id),
          previewDocuments: this.getPreviews(id),
        }).pipe(
          map((result) => {
            return GeneralActions.onCartViewModelLoaded({
              viewModel: {
                object: result.object,
                positions: result.positions,
                customer: result.customer,
                module: result.module,
                part: result.part,
                project: result.project,
                rootPse: result.rootPse,
                tasks: result.tasks,
                officeDocuments: result.officeDocuments,
                caxDocuments: result.caxDocuments,
                previewDocuments: result.previewDocuments,
              },
            });
          }),
        );
      }),
    );
  });

  onCartViewModelLoaded$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(GeneralActions.onCartViewModelLoaded),
        tap((action) => this.cvs.setLoading(false)),
      );
    },
    { dispatch: false },
  );

  onLoadTaskViewModel$ = createEffect(() => {
    const afoAliasFields = 'AFO_ART_TEXT=AFO_ART_ID.TEXT;PROJECT_NAME=';
    return this.actions.pipe(
      ofType(GeneralActions.onLoadTaskViewModel),
      exhaustMap((action) => {
        this.cvs.setLoading(true);
        return forkJoin({
          task: this.coreService.getObjectById<Task>(
            action.taskId,
            afoAliasFields,
          ),
          resource: this.getResource(action.taskId),
          resourceGroup: this.getResourceGroup(action.taskId),
          predecessors: this.getPredecessors(action.taskId),
          successors: this.getSuccessors(action.taskId),
          activities: this.getActivities(action.taskId),
          taskContainer: this.getTaskContainer(action.taskId),
          clampings: this.getClampings(action.taskId),
        }).pipe(
          map((result) => {
            return GeneralActions.onTaskViewModelLoaded({
              viewModel: {
                activities: result.activities,
                resource: result.resource,
                resourceArea: null,
                resourceGroup: result.resourceGroup,
                task: result.task,
                predecessors: result.predecessors,
                successors: result.successors,
                taskContainer: result.taskContainer,
                clampings: result.clampings,
              },
            });
          }),
        );
      }),
    );
  });

  onTaskViewModelLoaded$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(GeneralActions.onTaskViewModelLoaded),
        tap((action) => this.cvs.setLoading(false)),
      );
    },
    { dispatch: false },
  );

  onPositionViewModelLoaded$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(GeneralActions.onPositionViewModelLoaded),
        tap((action) => this.cvs.setLoading(false)),
      );
    },
    { dispatch: false },
  );

  onLoadMs$ = createEffect(() => {
    return this.actions.pipe(
      ofType(GeneralActions.onLoadMs),
      exhaustMap((action) => {
        this.cvs.setLoading(true);
        return this.coreService
          .getObjectById<Milestone>(
            action.msId,
            'TYP_NAME=MEILENSTEIN_TYP_ID.NAME;ART_NAME=MS_ART_ID.NAME',
          )
          .pipe(map((ms) => GeneralActions.onMsLoaded({ ms })));
      }),
    );
  });

  onMsLoaded$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(GeneralActions.onMsLoaded),
        tap((action) => this.cvs.setLoading(false)),
      );
    },
    { dispatch: false },
  );

  getMilestones(id: string): Observable<Milestone[]> {
    return this.coreService.getObjectsByGroupView<Milestone[]>(
      GUID_VIEWDEF_MS,
      id,
      '',
    );
  }

  getTasks(id: string, withChildren?: boolean): Observable<Task[]> {
    if (withChildren) {
      return this.coreService.getObjectsByGroupView<Task[]>(
        GUID_VIEWDEF_AFOS,
        id,
        '',
      );
    } else {
      return this.coreService.getObjectById<ProleisObject>(id).pipe(
        exhaustMap((position) => {
          const where =
            'STRUKTUREOWNER.OBJECT_ID IN (' + position.HEAD_ID + ')';
          return this.coreService.getObjectsByGroupView<Task[]>(
            GUID_VIEWDEF_AFOS,
            id,
            where,
          );
        }),
      );
    }
  }

  getPart(id: string): Observable<ProleisObject> {
    return this.coreService
      .getObjectById<ProleisObject>(
        id,
        'PART=PARENTANDMYSELF[TYP_ID.GUID=b514dea4-27fa-11b2-800a-b390cd5bbe84]',
      )
      .pipe(
        exhaustMap((pse) => {
          if (pse.PART) {
            return this.coreService.getObjectById<ProleisObject>(pse.PART);
          } else {
            return of(null);
          }
        }),
      );
  }

  getAAAPositions(id: string): Observable<ProleisObject[]> {
    return this.coreService.getChildren(id, '103061');
  }

  getPositions(id: string): Observable<ProleisObject[]> {
    return this.coreService.getChildren(id, '1474');
  }

  getProject(id: string): Observable<ProleisObject> {
    return this.coreService
      .getObjectById<ProleisObject>(
        id,
        'PROJECT=PARENTANDMYSELF[TYP_ID.GUID=b514d7ec-27fa-11b2-800a-b390cd5bbe84]',
      )
      .pipe(
        exhaustMap((pse) => {
          if (pse.PROJECT) {
            return this.coreService.getObjectById<ProleisObject>(pse.PROJECT);
          } else {
            return of(null);
          }
        }),
      );
  }

  getClampings(id: string): Observable<ProleisObject[]> {
    return this.coreService.getChildren<ProleisObject[]>(
      id,
      '§f2ece790-2add-11b2-80eb-09bb4ba6e692',
    );
  }

  getClampingsPse(id: string): Observable<ProleisObject[]> {
    return this.coreService
      .getChildren<ProleisObject[]>(id, '§' + GUID_PATH_VIEW)
      .pipe(
        map((children) => {
          return children.filter((c) => c.TYP_ID === 1417);
        }),
      );
  }

  getRootPse(id: string): Observable<ProleisObject> {
    return this.coreService
      .getObjectById<ProleisObject>(id, 'ROOT=ROOT_ID.HEAD_ID')
      .pipe(
        exhaustMap((pos) => {
          if (pos.ROOT) {
            return this.coreService.getObjectById<ProleisObject>(pos.ROOT);
          } else {
            return of(null);
          }
        }),
      );
  }

  getPseType(id: string): Observable<ProleisObject> {
    return this.coreService
      .getObjectById<ProleisObject>(id, 'PSETYP=OBJECT_ID.PSETYP_ID')
      .pipe(
        exhaustMap((pse) => {
          if (pse.PSETYPE) {
            return this.coreService.getObjectById<ProleisObject>(pse.PSETYP);
          } else {
            return of(null);
          }
        }),
      );
  }

  getModule(id: string): Observable<ProleisObject> {
    return this.coreService
      .getObjectById<ProleisObject>(
        id,
        'MODULE=PARENTANDMYSELF[TYP_ID.GUID=b514db98-27fa-11b2-800a-b390cd5bbe84]',
      )
      .pipe(
        exhaustMap((pse) => {
          if (pse.MODULE) {
            return this.coreService.getObjectById<ProleisObject>(pse.MODULE);
          }
          return of(null);
        }),
      );
  }

  getCustomer(id: string): Observable<ProleisObject> {
    return this.coreService
      .getObjectById<ProleisObject>(
        id,
        'CUSTOMER=PARENTANDMYSELF[TYP_ID.GUID=2c822196-2813-11b2-800f-09da23dd9627]',
      )
      .pipe(
        exhaustMap((pse) => {
          if (pse.CUSTOMER) {
            return this.coreService.getObjectById<ProleisObject>(pse.CUSTOMER);
          } else {
            return of(null);
          }
        }),
      );
  }

  getResource(id: string): Observable<Resource> {
    return this.coreService.getObjectById<Task>(id).pipe(
      exhaustMap((task) => {
        return this.coreService.getObjectById<Resource>(task.RES_ID);
      }),
    );
  }

  getResourceGroup(id: string): Observable<ResourceGroup> {
    return this.coreService.getObjectById<Task>(id).pipe(
      exhaustMap((task) => {
        return this.coreService.getObjectById<ResourceGroup>(task.RES_GRP_ID);
      }),
    );
  }

  getTaskContainer(id: string): Observable<ProleisObject> {
    return this.coreService
      .getObjectById<ProleisObject>(
        id,
        'TASKCONTAINER=PARENT[TYP_ID.GUID=0199d0bc-285b-11b2-8045-95e4fda6b4dc]',
      )
      .pipe(
        exhaustMap((task) => {
          if (task.TASKCONTAINER) {
            return this.coreService.getObjectById<ProleisObject>(
              task.TASKCONTAINER,
            );
          } else {
            return of(null);
          }
        }),
      );
  }

  getWorkloadComparison(id: string): Observable<ProleisObject[]> {
    const params: Param[] = [
      {
        name: 'GROUPVIEWDATA_TASKS_LINK.QUERY_PARENT_ID',
        type: Types.DOUBLE,
        value: id,
      },
    ];
    return this.analyticsService.executeReport(
      'TASK_CONTAINER_COMPARISON_WORKLOAD',
      params,
    );
  }

  getOfficeDocuments(id: string): Observable<ProleisObject[]> {
    return this.coreService.getObjectById<ProleisObject>(id).pipe(
      exhaustMap((object) => {
        return this.documentService.getDocumentsInContainer(
          object.DOKUMENTCONTAINER_ID,
        );
      }),
    );
  }

  getCaxDocuments(id: string): Observable<ProleisObject[]> {
    return this.documentService.getDocumentsInContainer(id);
  }

  getPredecessors(id: string): Observable<ProleisObject[]> {
    return this.coreService
      .getObjectByGuid<ProleisObject>(GUID_PATH_PREDECESSORS)
      .pipe(
        exhaustMap((path) => {
          return this.coreService.getChildren<ProleisObject[]>(
            id,
            path.OBJECT_ID,
          );
        }),
      );
  }

  getSuccessors(id: string): Observable<ProleisObject[]> {
    return this.coreService
      .getObjectByGuid<ProleisObject>(GUID_PATH_SUCCESSORS)
      .pipe(
        exhaustMap((path) => {
          return this.coreService.getChildren<ProleisObject[]>(
            id,
            path.OBJECT_ID,
          );
        }),
      );
  }

  getDeliveryInfo(id: string): Observable<DeliveryInfo> {
    const aliasFields =
      'COUNT_ALL=OBJECT_ID.CALC_PSETREE_CNT_ALL;' +
      'COUNT_REQUESTED=OBJECT_ID.CALC_PSETREE_CNT_REQUESTED;' +
      'COUNT_ORDERED=OBJECT_ID.CALC_PSETREE_CNT_ORDERED;' +
      'COUNT_DELIVERED=OBJECT_ID.CALC_PSETREE_CNT_DELIVERED;' +
      'COUNT_ASSEMBLY_READY=OBJECT_ID.CALC_PSETREE_CNT_ASSEMBLY_READY;' +
      'PERCENT_ORDERED=OBJECT_ID.CALC_PSETREE_PERCENT_ORDERED;' +
      'PERCENT_DELIVERED=OBJECT_ID.CALC_PSETREE_PERCENT_DELIVERED;' +
      'PERCENT_ASSEMBLY_READY=OBJECT_ID.CALC_PSETREE_PERCENT_ASSEMBLY_READY;' +
      'AFOS_CNT_PLANNED=OBJECT_ID.CALC_AFOS_CNT_PLANNED;' +
      'AFOS_SUM_PLANNEDTIME=OBJECT_ID.CALC_AFOS_SUM_PLANNEDTIME;' +
      'AFOS_SUM_WORKINGTIME=OBJECT_ID.CALC_AFOS_SUM_WORKINGTIME;' +
      'AFOS_SUM_OVERTIME=OBJECT_ID.CALC_AFOS_SUM_OVERTIME;' +
      'AFOS_PERCENT_CNT_UNFINISHED_FINISHED=OBJECT_ID.CALC_AFOS_PERCENT_CNT_UNFINISHED_FINISHED;' +
      'AFOS_CNT_FINISHED=OBJECT_ID.CALC_AFOS_CNT_FINISHED;' +
      'AFOS_PERCENT_PLANNEDTIME_UNFINISHED_FINISHED=OBJECT_ID.CALC_AFOS_PERCENT_PLANNEDTIME_UNFINISHED_FINISHED;' +
      'AFOS_SUM_PLANNEDTIME_FINISHED=OBJECT_ID.CALC_AFOS_SUM_PLANNEDTIME_FINISHED;' +
      'AFOS_PERCENT_WORKINGTIME_PLANNEDTIME=OBJECT_ID.CALC_AFOS_PERCENT_WORKINGTIME_PLANNEDTIME;' +
      'AFOS_SUM_WORKINGTIME=OBJECT_ID.CALC_AFOS_SUM_WORKINGTIME;';
    return this.coreService.getObjectById<ProleisObject>(id, aliasFields).pipe(
      exhaustMap((result) => {
        const deliveryInfo: DeliveryInfo = {
          countAll: result.COUNT_ALL,
          countAssemblyReady: result.COUNT_ASSEMBLY_READY,
          countDelivered: result.COUNT_DELIVERED,
          countOrdered: result.COUNT_ORDERED,
          countRequested: result.COUNT_REQUESTED,
          percentAssemblyReady: result.PERCENT_ASSEMBLY_READY,
          percentDelivered: result.PERCENT_DELIVERED,
          percentOrdered: result.PERCENT_ORDERED,
          afoCountFinished: result.AFOS_CNT_FINISHED,
          afoCountPlanned: result.AFOS_CNT_PLANNED,
          afoPercentCountUnfinishedFinished:
            result.AFOS_PERCENT_CNT_UNFINISHED_FINISHED,
          afoPercentPlannedTime:
            result.AFOS_PERCENT_PLANNEDTIME_UNFINISHED_FINISHED,
          afoPercentWorkingtime: result.AFOS_PERCENT_WORKINGTIME_PLANNEDTIME,
          afoSumOvertime: result.AFOS_SUM_OVERTIME,
          afoSumPlanned: result.AFOS_SUM_PLANNEDTIME,
          afoSumPlannedTime: result.AFOS_SUM_PLANNEDTIME_FINISHED,
          afoSumWorkingtime: result.AFOS_SUM_WORKINGTIME,
          pse: result,
        };
        return of(deliveryInfo);
      }),
    );
  }

  getActivities(id: string): Observable<ProleisObject[]> {
    return this.coreService
      .getObjectByGuid<ProleisObject>(GUID_PATH_OPERATIONS)
      .pipe(
        exhaustMap((path) => {
          return this.coreService.getChildren<ProleisObject[]>(
            id,
            path.OBJECT_ID,
          );
        }),
      );
  }

  getPreviews(objId: string): Observable<Image[]> {
    return this.previewService.getItems(objId).pipe(
      exhaustMap((result) => {
        const prevRequests: Observable<Blob>[] = [];
        result.forEach((preview) => {
          prevRequests.push(
            this.http.get(
              this.urlService.getUrl(
                '/preview/' + objId + '/items/' + preview.id,
              ),
              {
                responseType: 'blob',
              },
            ),
          );
        });

        return forkJoin(prevRequests).pipe(
          exhaustMap((results) => {
            const previewImages: Image[] = [];
            results.forEach((blob) => {
              const url = URL.createObjectURL(blob);
              const img: Image = {
                alt: 'bla',
                path: this.sanitizer.bypassSecurityTrustUrl(url) as string,
              };
              previewImages.push(img);
            });
            return of(previewImages);
          }),
        );
      }),
    );
  }
}

export interface Pse extends ProleisObject {
  PSETYP_ID: string;
  PR_STATUS_ID: string;
  PLANNING_STATUS_ID: string;
}
