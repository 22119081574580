<pw-view-title>Error</pw-view-title>

<div id="error-wrapper">
  <div *ngIf="error" class="error-name-container">
    <div class="error-name">{{ error.name }}</div>
  </div>

  <div class="error-message">
    {{ error.message }}
  </div>
</div>
