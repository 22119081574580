import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProleisForMobileState } from '../../../../store/store.index';
import { GeneralActions } from '../../../../store/general/general.index';
import { ActivatedRoute } from '@angular/router';
import { selectRootPseViewModel } from '../../../../store/general/general.selectors';
import { RootPseViewModel } from '../../../../store/general/general.state';
import * as Highcharts from 'highcharts';
import { Options } from 'highcharts';
import {
  DocumentService,
  Milestone,
  PreviewService,
  ProleisObject,
} from 'proleis-rest-client';
import { PageEvent } from '@angular/material/paginator';
import { selectDashboardDeliveryInfo } from '../../../../store/dashboard/dashboard.selectors';
import { DeliveryInfo } from '../../../../store/dashboard/dashboard.state';
import { Image, ProleisWebGalleryComponent } from 'proleis-web-gallery';
import { ListInfoCardComponent } from '../../card/list.info.card.component';
import { forkJoin, Observable, of, shareReplay } from 'rxjs';
import { SafePipe } from '../../../../../p4m/safe.pipe';
import { map } from 'rxjs/operators';
import { FileResponse } from '../../../../../../../../proleis-suppliers/src/app/models/file.response';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CreateIncidentComponent } from '../../../../quality/components/create-incident/create.incident.component';
import { saveAs } from 'file-saver';
import { RedirectService } from '../../../services/redirect.service';

@Component({
  selector: 'p4m-root-pse-info-panel',
  templateUrl: 'root.pse.info.panel.component.html',
  styleUrls: ['root.pse.info.panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RootPseInfoPanelComponent implements OnInit {
  @ViewChild('gallery') gallery: ProleisWebGalleryComponent;

  @ViewChild('msList') msList: ListInfoCardComponent;

  viewModel: RootPseViewModel;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Options;
  deliveryInfo: DeliveryInfo;

  filteredMs: Milestone[];
  displayedMs: Milestone[];
  previewFiles: Observable<Image[]>;

  constructor(
    private route: ActivatedRoute,
    private store: Store<ProleisForMobileState>,
    private ps: PreviewService,
    private safePipe: SafePipe,
    private bs: MatBottomSheet,
    private ds: DocumentService,
    private rs: RedirectService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.store.dispatch(
        GeneralActions.onLoadRootPseView({
          pseId: params.id,
        }),
      );

      this.store
        .select(selectDashboardDeliveryInfo, params.id)
        .subscribe((deliveryInfo) => {
          this.deliveryInfo = deliveryInfo;
        });
    });

    this.store.select(selectRootPseViewModel).subscribe((model) => {
      this.viewModel = model;
      if (model) {
        this.buildChart();
        this.filteredMs = model.milestones;
        this.displayedMs = model.milestones.slice(0, 5);
      }

      this.previewFiles = this.getPreviewFiles();
    });
  }

  buildChart() {
    this.chartOptions = {
      chart: {
        type: 'column',
      },
      credits: {
        enabled: false,
      },
      title: {
        text: 'Übersicht VP/PR/IST',
      },
      xAxis: {
        categories: this.viewModel.workloadComparison.map(
          (elem) => elem.GROUPNAME,
        ),
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Arbeit (h)',
        },
      },
      series: [
        {
          type: 'column',
          name: 'VP',
          data: this.viewModel.workloadComparison.map((elem) =>
            Number(elem.VP_ARBEIT),
          ),
        },
        {
          type: 'column',
          name: 'PR',
          data: this.viewModel.workloadComparison.map((elem) =>
            Number(elem.PR_ARBEIT),
          ),
        },
        {
          type: 'column',
          name: 'Ist Mann',
          data: this.viewModel.workloadComparison.map((elem) =>
            Number(elem.IST_ARBEIT_PERS),
          ),
        },
        {
          type: 'column',
          name: 'Ist Maschine',
          data: this.viewModel.workloadComparison.map((elem) =>
            Number(elem.IST_ARBEIT_MANNLOS),
          ),
        },
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 400,
            },
            chartOptions: {
              yAxis: {
                labels: {
                  formatter() {
                    return String(this.value).substring(0, 3);
                  },
                },
              },
            },
          },
        ],
      },
    };
  }

  getStatusIcon(ms: ProleisObject): string {
    if (ms.ISTGEPLANT && ms.ISTBEARBEITET) {
      return 'done';
    } else if (ms.ISTGEPLANT) {
      return 'schedule';
    }
    return 'warning';
  }

  scrollTo(el: HTMLElement) {
    el.scrollIntoView({
      behavior: 'smooth',
    });
  }

  filterMs(event) {
    this.filteredMs = this.viewModel.milestones.filter(
      (ms) =>
        ms.NAME.toUpperCase().includes(event.target.value.toUpperCase()) ||
        ms.TEXT.toUpperCase().includes(event.target.value.toUpperCase()),
    );
    this.displayedMs = this.filteredMs.slice(0, 5);
  }

  sortMs() {
    this.displayedMs = this.displayedMs.sort((a, b) => {
      if (a.NAME < b.NAME) {
        return -1;
      } else if (a.NAME > b.NAME) {
        return 1;
      }
      return 0;
    });
  }

  onMsPagination(event: PageEvent) {
    console.log('###event####', event);
    const from = event.pageIndex * event.pageSize;
    const to = from + event.pageSize;
    this.displayedMs = this.filteredMs.slice(from, to);
  }

  switchViewMode() {
    this.gallery.switchViewMode();
  }

  getPreviewFiles(): Observable<Image[]> {
    if (this.viewModel?.previewImages) {
      const requests = this.viewModel?.previewImages?.map((img) =>
        this.ps.getItem(this.route.snapshot.params.id, img.id),
      );

      return forkJoin(requests).pipe(
        map((result) => {
          return result.map((resp) => {
            const fileResponse: FileResponse = FileResponse.parseResponse(resp);
            return {
              alt: fileResponse.fileName,
              path: this.safePipe.transform(
                URL.createObjectURL(fileResponse.content),
              ),
            };
          });
        }, shareReplay(1)),
      );
    }
    return of(undefined);
  }

  createIncident() {
    this.bs.open(CreateIncidentComponent, {
      data: {
        linkId: this.route.snapshot.params.id,
      },
    });
  }

  downloadDocument(docId: string) {
    this.ds.getDocumentFile(docId).subscribe((response) => {
      const fileResponse: FileResponse = FileResponse.parseResponse(response);
      saveAs(fileResponse.content, fileResponse.fileName);
    });
  }

  redirect(objectId: string) {
    this.rs.redirectToInfoPanel(objectId);
  }
}
