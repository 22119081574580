import { AbstractControl, ValidatorFn } from '@angular/forms';

// 03:00 // 3:00 // 0:30 // 00:30
export const minHourFormat = new RegExp(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/);

// 3h 20m // 20m // 3h
export const stringFormat = new RegExp(
  /^([0-9]+([.][0-9]+)?[wdhm])([ ][0-9]+([.][0-9]+)?[wdhm])*$/,
);

export class DurationValidator {
  constructor() {}

  static validateDuration: ValidatorFn = (c: AbstractControl) => {
    let input: string = c.getRawValue();
    if (!input) {
      return null;
    }
    if (input.includes(',')) {
      input = input.replace(',', '.');
    }
    if (!input) {
      return null;
    }
    let isValid = false;
    if (!isNaN(Number(input))) {
      isValid = true;
    } else if (minHourFormat.test(input)) {
      isValid = true;
    } else if (stringFormat.test(input)) {
      isValid = true;
    }
    return isValid ? null : { durationFormat: true };
  };
}
