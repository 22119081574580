import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CoreService, ProleisObject } from 'proleis-rest-client';
import { NotificationService } from 'proleis-web-app';
import { forkJoin } from 'rxjs';
import {
  GUID_TYPE_CART,
  GUID_TYPE_CLAMPING,
  GUID_TYPE_INCIDENT,
  GUID_TYPE_MS,
  GUID_TYPE_PSE,
  GUID_TYPE_TASK,
  GUID_TYPE_VC,
} from '../../../p4m/guids';

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  types: ProleisType[];
  typeGuids: string[] = [
    GUID_TYPE_CART,
    GUID_TYPE_CLAMPING,
    GUID_TYPE_PSE,
    GUID_TYPE_INCIDENT,
    GUID_TYPE_VC,
    GUID_TYPE_MS,
    GUID_TYPE_TASK,
  ];

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private coreService: CoreService,
  ) {}

  loadTypes() {
    const requests = this.typeGuids.map((guid) =>
      this.coreService.getObjectByGuid<ProleisObject>(guid, 'TYPGUID=GUID'),
    );
    forkJoin(requests).subscribe((result) => {
      this.types = result.map((typeObj) => {
        return {
          guid: typeObj.TYPGUID,
          id: typeObj.OBJECT_ID,
        };
      });
    });
  }

  validTypeGuid(guid: string) {
    return this.typeGuids.includes(guid);
  }

  redirectToInfoPanel(objectId: string) {
    if (!this.types) {
      this.loadTypes();
    }
    const prefix = '/general/info-panels/';

    this.coreService
      .getObjectById<ProleisObject>(objectId)
      .subscribe((object) => {
        const typeId = object.TYP_ID;
        const typGuid = this.types.find((type) => type.id === typeId).guid;

        switch (typGuid) {
          case GUID_TYPE_PSE:
            this.getPseTypeInfoPanel(object);
            break;
          case GUID_TYPE_TASK:
            this.router.navigate([`${prefix}/task/${object.OBJECT_ID}`]).then();
            break;
          case GUID_TYPE_MS:
            this.router.navigate([`${prefix}/ms/${object.OBJECT_ID}`]).then();
            break;
          case GUID_TYPE_INCIDENT:
            this.router
              .navigate([`${prefix}/incident/${object.OBJECT_ID}`])
              .then();
            break;
          case GUID_TYPE_VC:
            this.router.navigate([`${prefix}/vc/${object.OBJECT_ID}`]).then();
            break;
          case GUID_TYPE_CLAMPING:
            this.router
              .navigate([`${prefix}/clamping/${object.OBJECT_ID}`])
              .then();
            break;
          default:
            this.notificationService.showError(
              new Error('Zu diesem Typ existiert noch kein Infopanel!'),
            );
            break;
        }
      });
  }

  getPseTypeInfoPanel(object: ProleisObject) {
    const prefix = '/general/info-panels/';
    if (object.PSETYP_ID === '47389') {
      this.router.navigate([prefix + '/cart/' + object.OBJECT_ID]).then();
    } else if (object.ROOT_ID === object.OBJECT_ID) {
      this.router.navigate([prefix + '/pse/' + object.OBJECT_ID]).then();
    } else {
      this.router.navigate([prefix + '/position/' + object.OBJECT_ID]).then();
    }
  }
}

export type ProleisType = {
  id?: string;
  guid?: string;
};
