<table
  *ngIf="preliminaryTrackings"
  mat-table
  [dataSource]="preliminaryTrackings"
  (contentChanged)="getTrackingNames()">
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Datum</th>
    <td mat-cell *matCellDef="let element">{{ element.date | date }}</td>
  </ng-container>

  <ng-container matColumnDef="work">
    <th mat-header-cell *matHeaderCellDef>Arbeit</th>
    <td mat-cell *matCellDef="let element">
      {{ element.workingTime | duration }}
    </td>
  </ng-container>

  <ng-container matColumnDef="resId">
    <th mat-header-cell *matHeaderCellDef>Ressource</th>
    <td mat-cell *matCellDef="let element">
      {{ resNames.get(element?.resId) }}
    </td>
  </ng-container>

  <ng-container matColumnDef="ratingTypeId">
    <th mat-header-cell *matHeaderCellDef>Wertungsart</th>
    <td mat-cell *matCellDef="let element">
      {{ ratingTypeNames.get(element?.ratingTypeId) }}
    </td>
  </ng-container>

  <ng-container matColumnDef="serviceTypeId">
    <th mat-header-cell *matHeaderCellDef>Leistungsart</th>
    <td mat-cell *matCellDef="let element">
      {{ ratingTypeNames.get(element?.serviceTypeId) }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
  <tr mat-row *matRowDef="let row; columns: getDisplayedColumns()"></tr>
</table>
