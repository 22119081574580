import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { planningFeatureKey } from './planning.index';
import { planningReducer } from './planning.reducer';
import { EffectsModule } from '@ngrx/effects';
import { PlanningEffects } from './planning.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(planningFeatureKey, planningReducer),
    EffectsModule.forFeature([PlanningEffects]),
  ],
})
export class PlanningStateModule {}
