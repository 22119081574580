import { Component, OnInit } from '@angular/core';
import {
  DashboardItemComponent,
  DashboardService,
} from 'proleis-web-dashboard';
import { Store } from '@ngrx/store';
import { ProleisForMobileState } from '../../../../store/store.index';
import { DashboardActions } from '../../../../store/dashboard/dashboard.index';
import { selectCapacityWorkload } from '../../../../store/dashboard/dashboard.selectors';
import * as Highcharts from 'highcharts';
import { Options, SeriesOptionsType } from 'highcharts';
import { CapacityData } from '../../../../store/dashboard/dashboard.state';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CapacityWorkloadOptionsComponent } from './capacity.workload.options.component';
import { ChartColorService } from '../../../../../p4m/chart.color.service';

@Component({
  selector: 'p4m-dashboard-capacity-workload',
  templateUrl: 'capacity.workload.component.html',
})
export class CapacityWorkloadComponent
  extends DashboardItemComponent
  implements OnInit
{
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Options;
  data: CapacityData;

  constructor(
    private store: Store<ProleisForMobileState>,
    public ds: DashboardService,
    private bottomSheet: MatBottomSheet,
    private colorService: ChartColorService,
  ) {
    super();
  }

  ngOnInit() {
    const resIds = this.item.options.resIds
      .map((value) => value.OBJECT_ID)
      .join(',');
    this.store.dispatch(
      DashboardActions.loadCapacityWorkload({
        resIds,
        start: this.getRelativeDate(new Date(), this.item.options.start),
        end: this.getRelativeDate(new Date(), this.item.options.end * -1),
        groupField: this.item.options.groupField,
        includePr: this.item.options.includePr,
        includeVp: this.item.options.includeVp,
        excludeFinished: this.item.options.excludeFinished,
        onlyCapaActive: this.item.options.onlyCapaActive,
        timeUnit: this.item.options.timeUnit.OBJECT_ID,
      }),
    );

    this.store.select(selectCapacityWorkload, resIds).subscribe((data) => {
      if (data) {
        this.data = data;
        this.buildChartOptions();
      }
    });
  }

  buildChartOptions() {
    const capacity = this.data.capacityData.filter((d) => d.DATAKEY === 'CAPA');
    const work = this.data.capacityData.filter((d) => d.DATAKEY === 'WORK');

    const timeLabels = [
      ...new Set(this.data.capacityData.map((value) => value.TIMELABEL)),
    ];
    const groupLabels = [...new Set(work.map((value) => value.GROUP))];

    const series: SeriesOptionsType[] = [];
    series.push({
      name: 'Kapazität',
      data: capacity.map((d) => Number(d.CAPACITY)),
      color: this.colorService.getColorForId('Kapazität'),
      type: 'line',
    });

    groupLabels.forEach((label) =>
      series.push({
        name: label,
        data: this.getGroupData(label, capacity),
        type: 'column',
        color: this.colorService.getColorForId(label),
        stack: 'work',
      }),
    );

    this.chartOptions = {
      chart: {
        type: 'column',
      },
      xAxis: {
        categories: timeLabels,
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Stunden',
        },
      },
      series,
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      title: {
        text: '',
      },
    };
  }

  getGroupData(group: string, capacity: any[]) {
    const dataSets: any[] = [];
    const filteredData = this.data.capacityData.filter(
      (d) => d.GROUP === group,
    );
    filteredData.forEach((d) => {
      dataSets.push({
        y: Number(d.WORK),
        x: capacity.findIndex((c) => c.TIMELABEL === d.TIMELABEL),
      });
    });
    return dataSets;
  }

  openOptionsBottomSheet() {
    this.bottomSheet.open(CapacityWorkloadOptionsComponent, {
      data: {
        item: this.item,
        dashboard: this.item.dashboardName,
      },
    });
  }

  delete() {
    this.ds.removeDashboardItem(this.item.dashboardName, this.item);
  }

  getRelativeDate(d: Date, offset: number): Date {
    let result;
    switch (this.item.options.timeUnit.NAME) {
      case 'DAY':
        result = d.setDate(d.getDay() - offset);
        break;
      case 'WEEK':
        result = d.setDate(d.getDay() - offset * 7);
        break;
      case 'MONTH':
        result = d.setMonth(d.getMonth() - offset);
        break;
      case 'YEAR':
        result = d.setMonth(d.getMonth() - offset * 12);
        break;
      default:
        result = d.setDate(d.getDay() - offset);
        break;
    }
    return new Date(result);
  }
}
