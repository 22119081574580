import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-edit-filter.bottomsheet',
  templateUrl: './edit-filter.bottomsheet.component.html',
  styleUrls: ['./edit-filter.bottomsheet.component.scss'],
})
export class EditFilterBottomsheetComponent {
  start: Date;
  end: Date;

  constructor(private bsRef: MatBottomSheetRef) {}

  save() {
    this.bsRef.dismiss({
      start: this.start,
      end: this.end,
    });
  }

  dismiss() {
    this.bsRef.dismiss();
  }
}
