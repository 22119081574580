import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from 'proleis-web-app';
import { RestoreUrlService } from './restore.url.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private errorService: NotificationService,
    private router: Router,
    private ls: LoadingService,
    private activatedRoute: ActivatedRoute,
    private restoreUrl: RestoreUrlService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.errorService.showError(
            new Error('Session abgelaufen, bitte erneut anmelden'),
          );
          this.restoreUrl.addUrl(this.activatedRoute.snapshot.url);
          this.router.navigate(['home']).then();
          this.ls.setLoading(false, req.url);
        } else if (error.status === 404) {
          return next.handle(req);
        } else {
          const err = error.error;
          this.errorService.showNotification(err?.message, 'error');
          return throwError({
            name: err.status,
            message: err.message,
          });
        }
      }),
    );
  }
}
