import { createFeatureSelector, createSelector } from '@ngrx/store';
import { QualityState } from './quality.state';
import { qualityFeatureKey } from './quality.reducer';

const qualityFeatureSelector =
  createFeatureSelector<QualityState>(qualityFeatureKey);

export const selectIncidents = createSelector(
  qualityFeatureSelector,
  (state) => state.incidents,
);

export const selectMainCategories = createSelector(
  qualityFeatureSelector,
  (state) => state.mainCategories,
);
