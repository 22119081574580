<pw-dashboard-item-options
  style="grid-column: span 2"
  [item]="data.item"></pw-dashboard-item-options>

<div class="p4m-grid-2-col">
  <pw-search
    style="grid-column: span 2"
    [placeholder]="'Ressourcen'"
    [allowedTypes]="allowedTypes"
    [minCharacters]="3"
    multiple="true"
    (selectionChanged)="onResSelectionChange($event)"
    [debounceTime]="250"></pw-search>
  <mat-checkbox [(ngModel)]="includePr">{{
    'dashboard.widgets.capacityWorkload.productive' | translate
  }}</mat-checkbox>
  <mat-checkbox [(ngModel)]="includeVp">{{
    'dashboard.widgets.capacityWorkload.preplanning' | translate
  }}</mat-checkbox>
  <mat-checkbox [(ngModel)]="onlyCapaActive">{{
    'dashboard.widgets.capacityWorkload.onlyCapaActive' | translate
  }}</mat-checkbox>
  <mat-checkbox [(ngModel)]="excludeFinished">{{
    'dashboard.widgets.capacityWorkload.excludeFinished' | translate
  }}</mat-checkbox>

  <mat-form-field style="grid-column: span 2" appearance="fill">
    <mat-label>{{
      'dashboard.widgets.capacityWorkload.groupingField' | translate
    }}</mat-label>
    <mat-select [(ngModel)]="groupField" name="car">
      <mat-option *ngFor="let field of groupFields" [value]="field.name">
        {{ field.description }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field style="grid-column: span 2" appearance="fill">
    <mat-label>{{
      'dashboard.widgets.capacityWorkload.timeunit' | translate
    }}</mat-label>
    <mat-select [(ngModel)]="timeUnit" name="car">
      <mat-option *ngFor="let unit of timeUnits" [value]="unit">
        {{ unit.DESCRIPTION }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>{{
      'dashboard.widgets.capacityWorkload.pre' | translate
    }}</mat-label>
    <mat-select [(ngModel)]="start">
      <mat-option *ngFor="let unit of timeRange" [value]="unit">
        {{ unit }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>{{
      'dashboard.widgets.capacityWorkload.post' | translate
    }}</mat-label>
    <mat-select [(ngModel)]="end">
      <mat-option *ngFor="let unit of timeRange" [value]="unit">
        {{ unit }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div style="justify-self: end">
    <button mat-button color="primary" (click)="dismiss()">
      {{ 'dashboard.widgets.capacityWorkload.discard' | translate }}
    </button>
    <button mat-raised-button color="primary" (click)="update()">
      {{ 'dashboard.widgets.capacityWorkload.save' | translate }}
    </button>
  </div>
</div>
