import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { logisticsFeatureKey, logisticsReducer } from './logistics.reducer';
import { EffectsModule } from '@ngrx/effects';
import { LogisticsEffects } from './logistics.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(logisticsFeatureKey, logisticsReducer),
    EffectsModule.forFeature([LogisticsEffects]),
  ],
})
export class LogisticsStateModule {}
