import {
  capacityDataAdapter,
  DashboardState,
  deliveryInfoAdapter,
  mdeInfoAdapter,
} from './dashboard.state';
import { createReducer, on } from '@ngrx/store';
import { DashboardActions } from './dashboard.index';

const initialState: DashboardState = {
  mdeInfo: mdeInfoAdapter.getInitialState(),
  favorites: [],
  userResources: [],
  tasks: [],
  heatmapData: [],
  incidents: [],
  deliveryInfo: deliveryInfoAdapter.getInitialState(),
  capacityData: capacityDataAdapter.getInitialState(),
};

const reducer = createReducer(
  initialState,
  on(DashboardActions.loadeMdeData, (state) => {
    return { ...state };
  }),
  on(DashboardActions.onMdeDataLoaded, (state, { mdeInfo }) => {
    return {
      ...state,
      mdeInfo: mdeInfoAdapter.addOne(mdeInfo, state.mdeInfo),
    };
  }),
  on(DashboardActions.loadFavorites, (state, action) => {
    return { ...state };
  }),
  on(DashboardActions.onFavoritesLoaded, (state, { favorites }) => {
    return {
      ...state,
      favorites,
    };
  }),
  on(DashboardActions.loadUserResources, (state, action) => {
    return { ...state };
  }),
  on(DashboardActions.onUserResourcesLoaded, (state, action) => {
    return {
      ...state,
      userResources: action.resources,
    };
  }),
  on(DashboardActions.loadTasks, (state, action) => {
    return { ...state };
  }),
  on(DashboardActions.onTasksLoaded, (state, { tasks }) => {
    return {
      ...state,
      tasks,
    };
  }),
  on(DashboardActions.loadCapacityHeatmap, (state, action) => {
    return { ...state };
  }),
  on(DashboardActions.onCapacityHeatmapLoaded, (state, action) => {
    return {
      ...state,
      heatmapData: action.heatmapData,
    };
  }),
  on(DashboardActions.loadIncidentInfo, (state, action) => {
    return { ...state };
  }),
  on(DashboardActions.onIncidentInfoLoaded, (state, action) => {
    return {
      ...state,
      incidents: action.incidents,
    };
  }),
  on(DashboardActions.loadDeliveryState, (state, action) => {
    return { ...state };
  }),
  on(DashboardActions.onDeliveryStateLoaded, (state, action) => {
    return {
      ...state,
      deliveryInfo: deliveryInfoAdapter.addOne(
        action.deliveryInfo,
        state.deliveryInfo,
      ),
    };
  }),
  on(DashboardActions.loadCapacityWorkload, (state, action) => {
    return { ...state };
  }),
  on(DashboardActions.onCapacityWorkloadLoaded, (state, action) => {
    return {
      ...state,
      capacityData: capacityDataAdapter.addOne(
        action.capacityData,
        state.capacityData,
      ),
    };
  }),
);

export function dashboardReducer(state: DashboardState, action) {
  return reducer(state, action);
}
