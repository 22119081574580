import { Component, Input, OnInit } from '@angular/core';
import {
  AppLayoutComponent,
  ClientAuthService,
  CurrentViewService,
} from 'proleis-web-app';
import { DashboardItemsService } from './modules/dashboard/services/dashboard.items.service';
import { DashboardService } from 'proleis-web-dashboard';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Dashboard } from './modules/dashboard/models/dashboard';
import { CreateDashboardComponent } from './modules/dashboard/components/views/create-dashboard/create.dashboard.component';
import { ProleisObject } from 'proleis-rest-client';
import { Router } from '@angular/router';

@Component({
  selector: 'p4m-app-menu',
  templateUrl: 'app.menu.component.html',
  styleUrls: ['app.menu.component.scss'],
})
export class AppMenuComponent implements OnInit {
  dashboards: Dashboard[];

  user: ProleisObject | null;

  @Input()
  layout: AppLayoutComponent;

  constructor(
    private cvs: CurrentViewService,
    private bs: MatBottomSheet,
    public clientAuth: ClientAuthService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.cvs.menuToggled.subscribe((menuToggled) =>
      console.log('menu toggled: ', menuToggled),
    );
  }

  closeMenu() {
    this.cvs.setMenuToggled(false);
  }

  onLogoClicked() {
    this.router.navigate(['/']).then();
    this.cvs.toggleMenu();
  }

  createDashboard() {
    this.bs.open(CreateDashboardComponent);
  }

  logout() {
    this.router.navigate(['/index']).then();
    this.clientAuth.logout();
  }
}
