import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProleisObject } from 'proleis-rest-client';
import { Observable } from 'rxjs';

@Component({
  selector: 'p4m-additional-tracking',
  templateUrl: 'additional.tracking.component.html',
})
export class AdditionalTrackingComponent implements OnInit {
  @Input()
  formGroup: FormGroup;

  @Input()
  activities: ProleisObject[];

  @Input()
  positions: ProleisObject[];

  @Input()
  serviceTypes: ProleisObject[];

  @Input()
  ratingTypes: ProleisObject[];

  @Output()
  ratingTypeChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  getValidationErrors() {
    const validationErrors = [];
    Object.keys(this.formGroup.controls).forEach((key) => {
      const errors = this.formGroup.get(key).errors;
      if (errors) {
        validationErrors.push(errors);
      }
    });
    return validationErrors;
  }

  onRatingTypeChanged() {
    this.ratingTypeChanged.emit(true);
  }
}
