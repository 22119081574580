<mat-card>
  <mat-card-title>{{
    'dashboard.widgets.capacityHeatmap.title' | translate
  }}</mat-card-title>
  <mat-card-content>
    <highcharts-chart
      [Highcharts]="Highcharts"
      [oneToOne]="true"
      [options]="chartOptions"
      *ngIf="data"></highcharts-chart>
  </mat-card-content>
  <mat-card-actions align="end">
    <button
      mat-button
      color="accent"
      *ngIf="ds.isDashboardEditable() | async"
      (click)="openOptionsBottomSheet()">
      EDIT
    </button>
    <button
      mat-button
      color="primary"
      *ngIf="ds.isDashboardEditable() | async"
      (click)="delete()">
      <mat-icon>remove</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
