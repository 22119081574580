<pw-view-title>{{ 'planning.scanOverlay.partScan' | translate }}</pw-view-title>
<div class="mw-70">
  <mat-tab-group
    (selectedTabChange)="onTabChanged($event)"
    [(selectedIndex)]="selectedIndex">
    <mat-tab label="{{ 'planning.scanOverlay.camera' | translate }}" #cameraTab>
      <div style="padding-top: 2rem">
        <pw-scanner
          (codeScanned)="onCodeScanned($event)"
          [enabled]="cameraTab.isActive"></pw-scanner>
      </div>
    </mat-tab>

    <mat-tab label="{{ 'planning.scanOverlay.scanner' | translate }}">
      <div
        style="
          display: flex;
          flex-direction: row;
          padding-top: 2rem;
          gap: 1rem;
        ">
        <mat-form-field style="flex: 1 0 auto">
          <mat-label>Code</mat-label>
          <input
            matInput
            type="text"
            autofocus
            [formControl]="idInputFormControl" />
        </mat-form-field>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
