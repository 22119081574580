<h3 mat-dialog-title>{{ title }}</h3>

<div mat-dialog-content>
  <p>{{ message }}</p>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="dismiss()">ABBRECHEN</button>
  <button mat-button (click)="confirm()">OK</button>
</div>
