<mat-card *ngIf="listItems">
  <mat-card-header>
    <mat-card-title>{{ cardTitle }}</mat-card-title>

    <div>
      <button mat-icon-button (click)="sortItems()">
        <mat-icon>sort</mat-icon>
      </button>
      <button mat-icon-button (click)="toggleSearch()">
        <mat-icon>search</mat-icon>
      </button>
      <mat-form-field *ngIf="search">
        <input matInput placeholder="Suche" (change)="onSearch($event)" />
      </mat-form-field>
    </div>
  </mat-card-header>

  <mat-card-content>
    <mat-nav-list>
      <mat-list-item
        *ngFor="let item of displayedListItems"
        routerLink="{{ routerLink }}">
        <mat-icon matListItemAvatar>{{ getIcon(item) }}</mat-icon>
        <span matListItemLine *ngFor="let prop of displayProperties">
          {{ item[prop] }}
        </span>
      </mat-list-item>
    </mat-nav-list>

    <mat-paginator
      #msPaginator
      [length]="filteredListItems.length"
      [pageSize]="5"
      [pageSizeOptions]="[5, 10, 15]"
      (page)="onPagination($event)">
    </mat-paginator>
  </mat-card-content>
</mat-card>
