import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ProleisForMobileState } from '../../../../store/store.index';
import { GeneralActions } from '../../../../store/general/general.index';
import { TaskViewModel } from '../../../../store/general/general.state';
import { selectTaskViewModel } from '../../../../store/general/general.selectors';
import { CoreService } from 'proleis-rest-client';
import { PpsService } from '../../../../planning/components/pps/board/pps.service';
import { RedirectService } from '../../../services/redirect.service';

@Component({
  selector: 'p4m-task-infopanel',
  templateUrl: 'task.info.panel.component.html',
})
export class TaskInfoPanelComponent implements OnInit {
  viewModel: TaskViewModel;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<ProleisForMobileState>,
    private cs: CoreService,
    public ppsService: PpsService,
    private rs: RedirectService,
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.store.dispatch(
        GeneralActions.onLoadTaskViewModel({ taskId: params.id }),
      );
      this.store
        .select(selectTaskViewModel)
        .subscribe((model) => (this.viewModel = model));
    });

    this.cs
      .getParents(
        this.activatedRoute.snapshot.params.id,
        '§b5159b3c-27fa-11b2-800a-b390cd5bbe84',
      )
      .subscribe((positions) => {
        console.log(positions);
      });
  }

  redirect(objectId: string) {
    this.rs.redirectToInfoPanel(objectId);
  }

  protected readonly Math = Math;
}
