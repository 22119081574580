import { LogisticsState } from './logistics.state';
import { createReducer, on } from '@ngrx/store';
import { LogisticsActions } from './logistics.index';

export const logisticsFeatureKey = 'logistics';

const initialState: LogisticsState = {
  scannedObject: null,
  sequenceResult: null,
};

const reducer = createReducer(
  initialState,
  on(LogisticsActions.scanObject, (state) => state),
  on(LogisticsActions.onScanObjectSuccess, (state, action) => ({
    ...state,
    scannedObject: action.object,
  })),
  on(LogisticsActions.onScanSequenceSuccess, (state, action) => ({
    ...state,
    sequenceResult: action.result,
  })),
  on(LogisticsActions.relocate, (state) => state),
);

export function logisticsReducer(state: LogisticsState, action) {
  return reducer(state, action);
}
