import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule,
} from '@angular/platform-browser';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import 'hammerjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {
  EndpointPermissionProvider,
  OidcAuth,
  PermissionProvider,
  ProleisWebAppModule,
  translateLoaderFactory,
} from 'proleis-web-app';
import { HomeViewComponent } from './home.view.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProleisRestClientModule } from 'proleis-rest-client';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProleisForMobileStoreModule } from './modules/store/proleis.for.mobile.store.module';
import { GeneralModule } from './modules/general/general.module';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ErrorPageComponent } from './p4m/error.page.component';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatLineModule, MatNativeDateModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatSortModule } from '@angular/material/sort';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {
  AppConfig,
  OidcLoginConfig,
} from 'proleis-web-app/lib/models/app.config';
import {
  AuthModule,
  OpenIdConfiguration,
  StsConfigHttpLoader,
  StsConfigLoader,
} from 'angular-auth-oidc-client';
import { map } from 'rxjs/operators';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { OidcAuthMethod } from './p4m/oidc.auth.method';
import { AppMenuComponent } from './app.menu.component';
import { HttpErrorInterceptor } from './p4m/http.error.interceptor';
import { ProleisWebSearchModule } from 'proleis-web-search';
import { SafePipe } from './p4m/safe.pipe';
import { MatTabsModule } from '@angular/material/tabs';
import { ScannerModule } from 'proleis-web-scanner';
import { PlanningModule } from './modules/planning/planning.module';
import { MatPaginatorModule } from '@angular/material/paginator';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export const CONFIG_LOCATION = './assets/config/config.json';

export function loadAuthConfig(http: HttpClient) {
  const config$ = http.get<AppConfig>(CONFIG_LOCATION).pipe(
    map((appConfig) => {
      const oidcConfig: OidcLoginConfig =
        appConfig.loginConfig.loginTypes.filter(
          (loginType) => loginType.loginType === 'OIDC',
        )[0];
      const openIdConfig: OpenIdConfiguration = {
        clientId: oidcConfig.clientId,
        authWellknownEndpointUrl: oidcConfig.authWellknownEndpoint,
        authority: oidcConfig.stsServer,
        responseType: oidcConfig.responseType,
        scope: oidcConfig.scope,
        postLogoutRedirectUri: oidcConfig.postLogoutRedirectUrl,
        redirectUrl: oidcConfig.redirectUrl,
        autoUserInfo: oidcConfig.autoUserInfo,
      };
      return openIdConfig;
    }),
  );
  return new StsConfigHttpLoader(config$);
}

@NgModule({
  declarations: [
    AppComponent,
    AppMenuComponent,
    HomeViewComponent,
    ErrorPageComponent,
  ],
  exports: [AppMenuComponent, HomeViewComponent, ErrorPageComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    CommonModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: loadAuthConfig,
        deps: [HttpClient],
      },
    }),
    ProleisRestClientModule.forRoot({
      configUrl: CONFIG_LOCATION,
    }),
    ProleisWebAppModule.forRoot({
      configUrl: CONFIG_LOCATION,
    }),
    ProleisForMobileStoreModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatSnackBarModule,
    MatSortModule,
    MatBottomSheetModule,
    MatIconModule,
    MatIconModule,
    GeneralModule,
    MatListModule,
    MatLineModule,
    ProleisWebSearchModule,
    MatTabsModule,
    ScannerModule,
    PlanningModule,
    MatPaginatorModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerGestureConfig,
    },
    {
      provide: OidcAuth,
      useClass: OidcAuthMethod,
    },
    {
      provide: PermissionProvider,
      useClass: EndpointPermissionProvider,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    SafePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
