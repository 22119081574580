import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  DashboardItemOptionsComponent,
  DashboardService,
} from 'proleis-web-dashboard';
import { MachineService, ProleisObject } from 'proleis-rest-client';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';

@Component({
  selector: 'p4m-machine-main-idle-times-options',
  templateUrl: 'machine.main.idle.times.options.component.html',
})
export class MachineMainIdleTimesOptionsComponent implements OnInit {
  @ViewChild(DashboardItemOptionsComponent, { static: false })
  itemOptions: DashboardItemOptionsComponent;

  machineId: number;
  machines: ProleisObject[];
  selectedMachine: ProleisObject;

  constructor(
    public ds: DashboardService,
    private ms: MachineService,
    private bottomSheetRef: MatBottomSheetRef,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data,
  ) {}

  ngOnInit(): void {
    this.machineId = this.data.item.options.machineId;
    this.ms.getMachines().subscribe((machines) => {
      this.machines = machines;
    });
  }

  dismiss() {
    this.bottomSheetRef.dismiss();
  }

  update() {
    this.ds.editDashboardItem(this.data.item.dashboardName, {
      itemSize: this.itemOptions.selectedItemSize,
      itemName: this.itemOptions.selectedName,
      dashboardName: this.data.item.dashboardName,
      componentTypeName: this.itemOptions.selectedComponentType,
      options: {
        machineId: this.selectedMachine.OBJECT_ID,
      },
    });
    this.bottomSheetRef.dismiss();
  }
}
