<form class="p4m-grid-4-col" [formGroup]="formGroup">
  <mat-form-field *ngIf="serviceTypes?.length > 0">
    <mat-label>Leistungsart</mat-label>
    <mat-select formControlName="serviceTypeId" id="serviceTypeId">
      <mat-option
        *ngFor="let option of serviceTypes"
        [value]="option.OBJECT_ID"
        >{{ option.TEXT }}</mat-option
      >
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="ratingTypes?.length > 0">
    <mat-label>Wertungsart</mat-label>
    <mat-select
      formControlName="ratingTypeId"
      id="ratingTypeId"
      (valueChange)="onRatingTypeChanged()">
      <mat-option
        *ngFor="let option of ratingTypes"
        [value]="option.OBJECT_ID"
        >{{ option.TEXT }}</mat-option
      >
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="positions?.length > 0">
    <mat-label>Position</mat-label>
    <mat-select formControlName="posId">
      <mat-option *ngFor="let option of positions" [value]="option.OBJECT_ID">{{
        option.TEXT
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="activities?.length > 0">
    <mat-label>Tätigkeit</mat-label>
    <mat-select formControlName="activityId">
      <mat-option
        *ngFor="let activity of activities"
        [value]="activity.OBJECT_ID"
        >{{ activity.TEXT }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</form>
