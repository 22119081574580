import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { RedirectService } from '../../../../general/services/redirect.service';
import { Router } from '@angular/router';
import { PpsService } from '../board/pps.service';
import { CoreService, ProleisObject } from 'proleis-rest-client';
import { debounceTime, exhaustMap, of, tap } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith } from 'rxjs/operators';
import { NotificationService } from '../../../../../p4m/notification.service';

export const TAB_INDEX = 'p4m-scan-part-tab';

@Component({
  selector: 'p4m-scan-part',
  templateUrl: 'scan-part.component.html',
})
export class ScanPartComponent implements OnInit {
  scannedObject: ProleisObject;
  objectId: string;
  selectedIndex: number;
  idInputFormControl: FormControl;

  constructor(
    private rds: RedirectService,
    private router: Router,
    private ppsService: PpsService,
    private cs: CoreService,
    private ns: NotificationService,
  ) {}

  ngOnInit() {
    this.initIdInput();
    if (localStorage.getItem(TAB_INDEX)) {
      this.selectedIndex = Number(localStorage.getItem(TAB_INDEX));
    }
  }

  initIdInput() {
    this.idInputFormControl = new FormControl<string>('');
    this.idInputFormControl.valueChanges
      .pipe(
        debounceTime(500),
        startWith(undefined),
        exhaustMap((id) => {
          if (id) {
            return this.cs.getObjectById<ProleisObject>(
              id,
              'TYP_GUID=TYP_ID.GUID',
            );
          } else {
            return of(undefined);
          }
        }),
        tap((obj: ProleisObject) => {
          this.scannedObject = obj;
          this.objectId = obj?.OBJECT_ID;
          this.redirect();
        }),
      )
      .subscribe();
  }

  onCodeScanned(event) {
    this.objectId = event;
    this.redirect();
  }

  onTabChanged(event: MatTabChangeEvent) {
    localStorage.setItem(TAB_INDEX, event.index.toString());
    this.selectedIndex = event.index;
  }

  redirect() {
    if (
      !this.scannedObject &&
      this.idInputFormControl.getRawValue().length > 0
    ) {
      this.ns.showNotification(
        `${this.idInputFormControl.getRawValue()} ist kein gültiges Objekt`,
        'error',
      );
    } else {
      this.ppsService.isTask(this.objectId).subscribe((isTask) => {
        if (isTask) {
          this.rds.redirectToInfoPanel(this.objectId);
        } else {
          if (this.rds.validTypeGuid(this.scannedObject.TYP_GUID)) {
            const route = `/planning/${this.objectId}/tasks`;
            this.router.navigate([route]).then();
          } else {
            this.ns.showNotification(
              `${this.scannedObject.TEXT} ist kein gültiges Objekt für Bauteilscan`,
              'warning',
            );
          }
        }
      });
    }
  }
}
