<pw-view-title>{{ viewModel?.object?.TEXT }}</pw-view-title>

<div class="p4m-grid-3-col" *ngIf="viewModel">
  <div #projectInfo>
    <mat-card *ngIf="viewModel">
      <mat-card-header>
        <mat-card-title>{{
          'general.infoPanels.cart.projectInformation' | translate
        }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-nav-list>
          <mat-list-item
            routerLink="/general/info-panels/pse/{{
              viewModel.rootPse.OBJECT_ID
            }}">
            <img matListItemAvatar [src]="viewModel.rootPse.ICON_URL" />
            <span matListItemLine>{{ viewModel.rootPse.TEXT }}</span>
          </mat-list-item>
          <mat-list-item>
            <img matListItemAvatar [src]="viewModel.object.ICON_URL" />
            <span matListItemLine>{{ viewModel.object.TEXT }}</span>
          </mat-list-item>
        </mat-nav-list>
      </mat-card-content>
    </mat-card>
  </div>

  <div>
    <mat-card *ngIf="deliveryInfo">
      <mat-card-header>
        <mat-card-title>
          {{ 'general.infoPanels.cart.deliveryStatus' | translate }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="p4m-grid-2-col">
          <div>
            {{ 'general.infoPanels.cart.ordered' | translate }}:
            {{ deliveryInfo.countOrdered }} / {{ deliveryInfo.countAll }}
          </div>
          <mat-progress-bar
            value="{{ deliveryInfo.percentOrdered }}"></mat-progress-bar>
          <div>
            {{ 'general.infoPanels.cart.delivered' | translate }}:
            {{ deliveryInfo.countDelivered }} / {{ deliveryInfo.countAll }}
          </div>
          <mat-progress-bar
            value="{{ deliveryInfo.percentDelivered }}"></mat-progress-bar>
          <div>
            {{ 'general.infoPanels.cart.assemblyReady' | translate }}:
            {{ deliveryInfo.countAssemblyReady }} / {{ deliveryInfo.countAll }}
          </div>
          <mat-progress-bar
            value="{{ deliveryInfo.percentAssemblyReady }}"></mat-progress-bar>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div #positions>
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{
          'general.infoPanels.cart.positions' | translate
        }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-nav-list>
          <mat-list-item
            *ngFor="let pos of viewModel.positions"
            routerLink="/general/info-panels/position/{{ pos.OBJECT_ID }}">
            <img mat-list-icon src="{{ pos.ICON_URL }}" />
            <span mat-line>{{ pos.TEXT }}</span>
          </mat-list-item>
        </mat-nav-list>
      </mat-card-content>
    </mat-card>
  </div>

  <div #tasks>
    <mat-card *ngIf="viewModel?.tasks">
      <mat-card-header>
        <mat-card-title>{{
          'general.infoPanels.cart.tasks' | translate
        }}</mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <mat-nav-list *ngIf="viewModel.tasks.length > 0">
          <mat-list-item
            *ngFor="let task of viewModel.tasks"
            routerLink="/general/info-panels/task/{{ task.OBJECT_ID }}">
            <img mat-list-icon src="{{ task.ICON_URL }}" />
            <span mat-line>{{ task.TEXT }}</span>
          </mat-list-item>
        </mat-nav-list>
        <div *ngIf="viewModel.tasks.length === 0">
          {{ 'general.infoPanels.cart.noTasks' | translate }}
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div #documents>
    <mat-card *ngIf="viewModel?.officeDocuments && viewModel?.caxDocuments">
      <mat-card-header>
        <mat-card-title>Dokumente</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-tab-group>
          <mat-tab label="Office">
            <mat-nav-list>
              <mat-list-item *ngFor="let doc of viewModel.officeDocuments">
                <div class="document">
                  <div class="document-title">{{ doc.TEXT }}</div>
                  <div style="flex: 1"></div>
                  <div class="document-version">{{ doc.VERSIONNR }}</div>
                </div>
              </mat-list-item>
            </mat-nav-list>
          </mat-tab>
          <mat-tab label="CAx">
            <mat-nav-list>
              <mat-list-item *ngFor="let doc of viewModel.caxDocuments">
                <div class="document">
                  <div class="document-title">{{ doc.TEXT }}</div>
                  <div style="flex: 1"></div>
                  <div class="document-version">{{ doc.VERSIONNR }}</div>
                </div>
              </mat-list-item>
            </mat-nav-list>
          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
    </mat-card>
  </div>

  <div #previews *ngIf="viewModel?.previewDocuments">
    <mat-card>
      <mat-card-title>Vorschau</mat-card-title>
      <mat-card-content>
        <pw-gallery [images]="viewModel.previewDocuments"></pw-gallery>
      </mat-card-content>
    </mat-card>
  </div>
</div>
