import { Component, OnInit } from '@angular/core';
import { P4mIncidentsService } from '../../services/incidents/p4mIncidentsService';
import { Observable, tap } from 'rxjs';
import {
  P4mCategory,
  P4mCauser,
  P4mIncident,
  P4mPriority,
  P4mReferenceObject,
} from '../../services/incidents/incident.models';
import { NotificationService } from '../../../../p4m/notification.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-create-incident',
  templateUrl: './create-incident.component.html',
  styleUrls: ['./create-incident.component.scss'],
})
export class CreateIncidentComponent implements OnInit {
  files: FileList;

  name: string;
  description: string;
  selectedCategory: P4mCategory;
  selectedSubcategory: P4mCategory;
  selectedPrio: P4mPriority;
  selectedCauserInternal: P4mCauser;
  selectedCauserExternal: P4mCauser;

  referenceObject$: Observable<P4mReferenceObject>;
  categories$: Observable<P4mCategory[]>;
  subcategories$: Observable<P4mCategory[]>;
  priorities$: Observable<P4mPriority[]>;
  causersInternal: Observable<P4mCauser[]>;
  causersExternal: Observable<P4mCauser[]>;

  constructor(
    private is: P4mIncidentsService,
    private ns: NotificationService,
    private ar: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.referenceObject$ = this.is.loadReferenceObject(
      this.ar.snapshot.params.referenceId,
    );
    this.categories$ = this.is.loadCategories();
    this.priorities$ = this.is.loadPriorities();
    this.causersExternal = this.is.loadCausersExternal();
    this.causersInternal = this.is.loadCausersInternal();
  }

  onCategoryChanged(event) {
    this.subcategories$ = this.is.loadSubcategories(event.value.id);
  }

  onFileSelected(event) {
    this.files = event;
    console.log(this.files);
  }

  createIncident() {
    const incident: P4mIncident = {
      name: this.name,
      priority: this.selectedPrio.id,
      description: this.description,
      subCategory: this.selectedSubcategory.id,
      mainCategory: this.selectedCategory.id,
      internalCauser: this.selectedCauserInternal.id,
      externalCauser: this.selectedCauserExternal.id,
    };
    let files: File[] = [];
    if (this.files) {
      files = Array.from(this.files);
    }
    this.is
      .createIncident(this.ar.snapshot.params.referenceId, incident, files)
      .pipe(
        tap((incident) => {
          this.ns.showNotification(
            'Vorgang angelegt: ' + incident.name,
            'success',
          );
          history.back();
        }),
      )
      .subscribe();
  }
}
