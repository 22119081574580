<ng-container *ngIf="order$ | async as order">
  <pw-view-title>{{ order.NAME }}</pw-view-title>

  <div class="p4m-grid-4-col">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Allgemein</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div
          style="display: grid; grid-template-columns: auto 1fr; grid-gap: 8px">
          <div style="font-weight: bold">Name</div>
          <div>{{ order.NAME }}</div>

          <div style="font-weight: bold">Benennung</div>
          <div>{{ order.DESCRIPTION }}</div>

          <div style="font-weight: bold">Anwendung</div>
          <div>{{ order.ANWENDUNG }}</div>

          <div style="font-weight: bold">Art</div>
          <div>{{ order.ART }}</div>

          <div style="font-weight: bold">Status</div>
          <div>{{ order.AAASTATUS }}</div>

          <div style="font-weight: bold">Termin</div>
          <div>{{ order.OUTDATE | date }}</div>

          <div style="font-weight: bold">Bemerkung</div>
          <div>{{ order.BEMERKUNG }}</div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="tools$ | async as tools">
      <mat-card-header>
        <mat-card-title>Zugewiesene Werkzeuge</mat-card-title>
      </mat-card-header>
      <mat-card-content *ngIf="tools.length > 0; else noTools">
        <mat-nav-list>
          <mat-list-item
            *ngFor="let tool of tools"
            (click)="rs.redirectToInfoPanel(tool.OBJECT_ID)">
            <span matListItemLine>{{ tool.TEXT }}</span>
          </mat-list-item>
        </mat-nav-list>
      </mat-card-content>
      <ng-template #noTools>
        <mat-card-content> Keine Werkzeuge zugewiesen. </mat-card-content>
      </ng-template>
    </mat-card>

    <mat-card *ngIf="vcContainers$ | async as vcContainers">
      <mat-card-header>
        <mat-card-title>Zugewiesene Vorgangscontainer</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-nav-list *ngIf="vcContainers.length > 0; else noVcs">
          <mat-list-item
            *ngFor="let vc of vcContainers"
            (click)="rs.redirectToInfoPanel(vc.OBJECT_ID)">
            <span matListItemLine>{{ vc.TEXT }}</span>
          </mat-list-item>
        </mat-nav-list>
      </mat-card-content>
      <ng-template #noVcs>
        <mat-card-content
          >Keine zugewiesenen Vorgangscontainer!</mat-card-content
        >
      </ng-template>
    </mat-card>

    <mat-card *ngIf="documents$ | async as documents">
      <mat-card-header>
        <mat-card-title>Dokumente</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <app-document-list [documents]="documents"></app-document-list>
      </mat-card-content>
      <ng-template #noDocuments>
        <mat-card-content>Keine Dokumente gefunden!</mat-card-content>
      </ng-template>
    </mat-card>
  </div>
</ng-container>
