import { Component, Input } from '@angular/core';
import { DocumentService, ProleisObject } from 'proleis-rest-client';
import { FileResponse } from '../../../../../../../proleis-suppliers/src/app/models/file.response';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
})
export class DocumentListComponent {
  @Input()
  documents: ProleisObject[];

  constructor(private ds: DocumentService) {}

  downloadDocument(docId: string) {
    this.ds.getDocumentFile(docId).subscribe((response) => {
      const fileResponse: FileResponse = FileResponse.parseResponse(response);
      saveAs(fileResponse.content, fileResponse.fileName);
    });
  }
}
