import { Component, OnInit } from '@angular/core';
import {
  CoreService,
  IncidentService,
  ProleisObject,
  RequestParam,
} from 'proleis-rest-client';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { combineLatest, Observable, tap } from 'rxjs';
import {
  P4mCategory,
  P4mIncidentStatus,
  P4mPriority,
  P4mResponsible,
} from '../../../../services/incidents/incident.models';
import { P4mIncidentsService } from '../../../../services/incidents/p4mIncidentsService';
import { NotificationService } from 'proleis-web-app';

@Component({
  selector: 'app-edit-incident',
  templateUrl: './edit-incident.component.html',
  styleUrls: ['./edit-incident.component.scss'],
})
export class EditIncidentComponent implements OnInit {
  form: FormGroup;
  incident$: Observable<ProleisObject>;
  categories$: Observable<P4mCategory[]>;
  selectedCategory: string;
  subcategories$: Observable<P4mCategory[]>;
  selectedSubCategory: string;
  priorities$: Observable<P4mPriority[]>;
  selectedPriority: string;
  responsibles$: Observable<P4mResponsible[]>;
  selectedResponsible: string;
  status$: Observable<P4mIncidentStatus[]>;
  selectedStatus: string;

  constructor(
    private ar: ActivatedRoute,
    private is: IncidentService,
    private incidentsService: P4mIncidentsService,
    private cs: CoreService,
    private ns: NotificationService,
  ) {}

  ngOnInit() {
    this.loadIncident();
    this.loadCategories();
    this.loadPriorities();
    this.loadResponsibles();
    this.loadStatus();
  }

  loadIncident() {
    this.incident$ = this.cs.getObjectById<ProleisObject>(
      this.ar.snapshot.params.id,
    );
  }

  loadCategories() {
    this.categories$ = this.incidentsService.loadCategories();

    combineLatest([this.incident$, this.categories$])
      .pipe(
        tap(([incident, categories]) => {
          this.selectedCategory = categories.find(
            (c) => c.id === incident.HAUPTKATEGORIE_ID,
          ).id;
          this.loadSubcategories(incident.HAUPTKATEGORIE_ID);
        }),
      )
      .subscribe();
  }

  loadSubcategories(categoryId: string) {
    this.subcategories$ = this.incidentsService.loadSubcategories(categoryId);

    combineLatest([this.incident$, this.subcategories$])
      .pipe(
        tap(([incident, categories]) => {
          this.selectedSubCategory = categories.find(
            (c) => c.id === incident.KATEGORIE_ID,
          ).id;
        }),
      )
      .subscribe();
  }

  loadPriorities() {
    this.priorities$ = this.incidentsService.loadPriorities();

    combineLatest([this.incident$, this.priorities$])
      .pipe(
        tap(([incident, priorities]) => {
          this.selectedPriority = priorities.find(
            (p) => p.id === incident.PRIO,
          ).id;
        }),
      )
      .subscribe();
  }

  loadResponsibles() {
    this.responsibles$ = this.incidentsService.loadResponsibles(
      this.ar.snapshot.params.id,
    );

    combineLatest([this.incident$, this.responsibles$])
      .pipe(
        tap(([incident, responsibles]) => {
          this.selectedResponsible = responsibles.find(
            (s) => s.id === incident.BEARBEITER_ID,
          ).id;
          console.log('responsible:', this.selectedResponsible);
        }),
      )
      .subscribe();
  }

  loadStatus() {
    this.status$ = this.incidentsService.loadStatus();

    combineLatest([this.incident$, this.status$])
      .pipe(
        tap(([incident, status]) => {
          this.selectedStatus = status.find(
            (s) => s.id === incident.PROBLEM_STATUS_ID,
          ).id;
        }),
      )
      .subscribe();
  }

  save() {
    const body: RequestParam = {
      KATEGORIE_ID: this.selectedSubCategory,
      HAUPTKATEGORIE_ID: this.selectedCategory,
      PRIO: this.selectedPriority,
      BEARBEITER_ID: this.selectedResponsible,
      PROBLEM_STATUS_ID: this.selectedStatus,
    };
    this.cs
      .updateObject(this.ar.snapshot.params.id, body)
      .subscribe((result) => {
        history.back();
        this.ns.showNotification('Vorfall akualisiert');
      });
  }

  discard() {
    history.back();
  }
}
