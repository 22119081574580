import { Injectable } from '@angular/core';
import {
  CoreService,
  IncidentService,
  ProleisObject,
} from 'proleis-rest-client';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { EMPTY, forkJoin } from 'rxjs';
import { Store } from '@ngrx/store';
import { ProleisForMobileState } from '../store.index';
import { UiActions } from '../ui/ui.index';
import { QualityActions } from './quality.index';
import {
  GUID_DIR_INCIDENT_MAINCATEGORIES,
  GUID_PATH_VIEW,
} from '../../../p4m/guids';

@Injectable()
export class QualityEffects {
  constructor(
    private cs: CoreService,
    private is: IncidentService,
    private actions: Actions,
    private store: Store<ProleisForMobileState>,
  ) {}

  loadIncidents$ = createEffect(() => {
    return this.actions.pipe(
      ofType(QualityActions.loadIncidents),
      exhaustMap((action) => {
        this.store.dispatch(UiActions.setLoading({ isLoading: true }));
        return this.is
          .getIncidents(
            action.start,
            action.end,
            action.category,
            action.status,
            action.link,
            action.description,
          )
          .pipe(
            map((incidents) =>
              QualityActions.onLoadIncidentsSuccess({ incidents }),
            ),
            catchError((error) => EMPTY),
          );
      }),
    );
  });

  onIncidentsLoadedSuccess$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(QualityActions.onLoadIncidentsSuccess),
        tap((action) =>
          this.store.dispatch(UiActions.setLoading({ isLoading: false })),
        ),
      );
    },
    {
      dispatch: false,
    },
  );

  loadMainCategories$ = createEffect(() => {
    return this.actions.pipe(
      ofType(QualityActions.loadMainCategories),
      exhaustMap((action) => {
        this.store.dispatch(UiActions.setLoading({ isLoading: true }));
        const params$ = forkJoin(
          this.cs.getObjectByGuid<ProleisObject>(
            GUID_DIR_INCIDENT_MAINCATEGORIES,
          ),
          this.cs.getObjectByGuid<ProleisObject>(GUID_PATH_VIEW),
        );

        return params$.pipe(
          exhaustMap(([catDir, viewPath]) => {
            return this.cs
              .getChildren<
                ProleisObject[]
              >(catDir.OBJECT_ID, viewPath.OBJECT_ID)
              .pipe(
                map((mainCategories) =>
                  QualityActions.onLoadMainCategoriesSuccess({
                    mainCategories,
                  }),
                ),
              );
          }),
        );
      }),
    );
  });

  onLoadMainCategoriesSuccess$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(QualityActions.onLoadMainCategoriesSuccess),
        tap((action) => {
          this.store.dispatch(UiActions.setLoading({ isLoading: false }));
          this.store.dispatch(QualityActions.loadIncidents({}));
        }),
      );
    },
    { dispatch: false },
  );
}
