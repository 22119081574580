import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Task } from 'proleis-rest-client';
import { Store } from '@ngrx/store';
import { ProleisForMobileState } from '../../../../../store/store.index';
import { PlanningActions } from '../../../../../store/planning/planning.index';

@Component({
  selector: 'p4m-pps-lane',
  templateUrl: 'lane.component.html',
})
export class LaneComponent implements OnInit {
  @Input() laneTitle: string;
  @Input() tasks: Observable<Task[]>;

  @Output() filterEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor(private store: Store<ProleisForMobileState>) {}

  ngOnInit() {}

  fireFilterEvent() {
    this.filterEvent.emit('');
  }

  onStartTracking(task: Task) {
    this.store.dispatch(
      PlanningActions.startTimeTracking({
        taskId: task.OBJECT_ID,
      }),
    );
  }
}
