import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoreService, ImageService, Task } from 'proleis-rest-client';
import { combineLatest, exhaustMap, iif, Observable, of } from 'rxjs';
import { ProleisObject } from 'proleis-rest-client/lib/models/proleis-object-models/proleis.object';
import { FormControl } from '@angular/forms';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'p4m-vc-info-panel',
  templateUrl: 'vc-infopanel.component.html',
})
export class VcInfopanelComponent implements OnInit {
  vc$: Observable<ProleisObject>;
  tasks$: Observable<Task[]>;
  searchFormControl: FormControl;

  constructor(
    private route: ActivatedRoute,
    private cs: CoreService,
    public is: ImageService,
  ) {
    this.searchFormControl = new FormControl();
  }

  ngOnInit() {
    const aliasFields =
      'CONTAINER_NAME=CONTAINERART_ID.NAME;CONTAINER_ICON=CONTAINERART_ID.CLIENTICON;' +
      'CONTAINER_STRANG=CONTAINER_STRANG_ID.TEXT;MS_CONT_NAME=MEILENSTEINCONT_ID.TEXT;TERM_ART_NAME=TERMINIERUNGSART_ID.TEXT' +
      'STD_BERECHNUNG=STD_BERECHNUNG_ID.TEXT';

    const aliasFieldsTask =
      'AFOARTNAME=AFO_ART_ID.NAME;ORDERNAME=PARENT.AUFTRAG_ID.TEXT;POSNAME=LINK_ID.TEXT;' +
      'PARTNAME=LINK_ID.PARENT[TYP_ID.GUID=b514dea4-27fa-11b2-800a-b390cd5bbe84].TEXT;' +
      'PROJECTNAME=LINK_ID.PARENT[TYP_ID.GUID=b514d7ec-27fa-11b2-800a-b390cd5bbe84].TEXT' +
      ';LINKROOT=LINK_ID.ROOT_ID;LINKHEAD=LINK_ID.HEAD_ID;LINKNAME=LINK_ID.TEXT;ROOTNAME=LINK_ID.ROOT_ID.TEXT;INFOTEXT=OBJECT_ID.INFOTEXT';
    this.vc$ = this.cs.getObjectById<ProleisObject>(
      this.route.snapshot.params.vcId,
      aliasFields,
    );
    this.tasks$ = this.vc$.pipe(
      exhaustMap((vc) =>
        this.cs.getChildren<Task[]>(vc.OBJECT_ID, '1474', aliasFieldsTask),
      ),
    );

    const filter$ = this.searchFormControl.valueChanges.pipe(startWith(''));

    this.tasks$ = combineLatest([this.tasks$, filter$]).pipe(
      exhaustMap(([tasks, filter]) => {
        return iif(
          () => filter === '',
          of(tasks),
          this.getFilteredTasks(filter, tasks),
        );
      }),
    );
  }

  getFilteredTasks(filter: string, tasks: Task[]): Observable<Task[]> {
    if (tasks) {
      return of(
        tasks.filter((t) => {
          const lowerCaseFilter = filter.toLowerCase();
          return (
            t.AFOBEZ.toLowerCase().includes(lowerCaseFilter) ||
            t.NAME.toLowerCase().includes(lowerCaseFilter) ||
            t.AUFTRAGSNR.toLowerCase().includes(lowerCaseFilter) ||
            t.PROJEKTNR.toLowerCase().includes(lowerCaseFilter)
          );
        }),
      );
    }
  }
}
