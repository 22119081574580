<pw-view-title>{{ 'planning.board.title' | translate }}</pw-view-title>

<pw-view-actions class="view-actions">
  <div class="view-actions">
    <button mat-icon-button (click)="toggleDrawer()">
      <mat-icon>filter_list</mat-icon>
    </button>
    <button mat-icon-button routerLink="partscan">
      <mat-icon>barcode_reader</mat-icon>
    </button>
  </div>
</pw-view-actions>

<pw-view-drawer>
  <div class="view-drawer">
    <mat-form-field>
      <input
        matInput
        [min]="filterStartRange"
        [max]="filterEndRange"
        [matDatepicker]="pickerStart"
        [(ngModel)]="filterStart" />
      <mat-label>{{ 'planning.board.filterStart' | translate }}</mat-label>
      <mat-datepicker-toggle
        matSuffix
        [for]="pickerStart"></mat-datepicker-toggle>
      <mat-datepicker #pickerStart></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <input matInput [matDatepicker]="pickerEnd" [(ngModel)]="filterEnd" />
      <mat-label i18nlabel="planning.board.filterEnd">{{ 'planning.board.filterEnd' | translate }}</mat-label>
      <mat-datepicker-toggle
        matSuffix
        [for]="pickerEnd"></mat-datepicker-toggle>
      <mat-datepicker #pickerEnd></mat-datepicker>
    </mat-form-field>

    <mat-form-field style="grid-column: span 2">
      <mat-label>{{ 'planning.board.filterForerun' | translate }}</mat-label>
      <input matInput [(ngModel)]="filterLead" />
    </mat-form-field>

    <mat-slide-toggle [(ngModel)]="filterFinished"
      >{{ 'planning.board.filterIncludeFinished' | translate }}
    </mat-slide-toggle>
    <mat-slide-toggle [(ngModel)]="filterOnlyReleased"
      >{{ 'planning.board.filterOnlyApproved' | translate }}
    </mat-slide-toggle>

    <div
      style="
        grid-column: auto / span 2;
        justify-content: end;
        display: flex;
        padding: 1rem;
      ">
      <button mat-button color="primary" (click)="cancel()">ABBRECHEN</button>
      <button
        mat-raised-button
        color="accent"
        (click)="setFilter()"
        style="grid-column: span 2">
        {{ 'planning.board.filterApply' | translate }}
      </button>
    </div>
  </div>
</pw-view-drawer>

<div *checkPermission="[{ name: 'P4_SHOW_MY_PROLEIS_TASKS' }]">
  <mat-tab-group
    *ngIf="ppsService.resources$ && !isMobile"
    (selectedTabChange)="onTabChanged($event)"
    animationDuration="0ms">
    <mat-tab
      *ngFor="let res of ppsService.resources$ | async"
      [label]="res.NAME">
      <ng-template matTabContent>
        <div class="p4m-flex-container">
          <mat-form-field appearance="fill" style="padding: 1rem">
            <mat-label>Filter</mat-label>
            <input
              type="text"
              matInput
              placeholder="Filter"
              [formControl]="searchFormControl" />
          </mat-form-field>
        </div>
        <div [ngClass]="isMobile ? 'mobile' : ''">
          <div class="board-container">
            <p4m-pps-lane
              laneTitle="Todo"
              (filterEvent)="toggleDrawer()"
              [tasks]="ppsService.todoTasks$"></p4m-pps-lane>
            <p4m-pps-lane
              laneTitle="In Arbeit"
              (filterEvent)="toggleDrawer()"
              [tasks]="ppsService.inProgressTasks$"></p4m-pps-lane>
            <p4m-pps-lane
              laneTitle="Fertig"
              (filterEvent)="toggleDrawer()"
              [tasks]="ppsService.finishedTasks$"></p4m-pps-lane>
          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

<div *ngIf="isMobile">
  <mat-form-field style="width: 100%">
    <mat-label>{{ 'planning.board.resources' | translate }}</mat-label>
    <mat-select
      (selectionChange)="onResourceChanged($event)"
      [value]="ppsService.selectedResource$ | async">
      <mat-option
        *ngFor="let res of ppsService.resources$ | async"
        [value]="res"
        >{{ res.TEXT }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="p4m-flex-container">
    <mat-form-field appearance="fill">
      <mat-label>Filter</mat-label>
      <input
        type="text"
        matInput
        placeholder="Filter"
        [formControl]="searchFormControl" />
    </mat-form-field>
  </div>
  <div class="mat-elevation-z6">
    <div
      class="mat-elevation-z6"
      *ngFor="let task of (ppsService.ppsResource$ | async)?.tasks">
      <p4m-mobile-task-component [task]="task"></p4m-mobile-task-component>
      <mat-divider vertical="false"></mat-divider>
    </div>
  </div>
</div>
