import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DashboardState } from './dashboard.state';
import { dashboardFeatureKey } from './dashboard.index';

const dashboardFeatureSelector =
  createFeatureSelector<DashboardState>(dashboardFeatureKey);

export const selectDashboardMdeInfo = createSelector(
  dashboardFeatureSelector,
  (state, id) => state.mdeInfo.entities[id],
);

export const selectDashboardFavorites = createSelector(
  dashboardFeatureSelector,
  (state) => state.favorites,
);

export const selectDashboardUserResources = createSelector(
  dashboardFeatureSelector,
  (state) => state.userResources,
);

export const selectDashboardTasks = createSelector(
  dashboardFeatureSelector,
  (state) => state.tasks,
);

export const selectDashboardHeatmapData = createSelector(
  dashboardFeatureSelector,
  (state) => state.heatmapData,
);

export const selectDashboardIncidentInfo = createSelector(
  dashboardFeatureSelector,
  (state) => state.incidents,
);

export const selectDashboardDeliveryInfo = createSelector(
  dashboardFeatureSelector,
  (state, id) => state.deliveryInfo.entities[id],
);

export const selectCapacityWorkload = createSelector(
  dashboardFeatureSelector,
  (state, id) => state.capacityData.entities[id],
);
