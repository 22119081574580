import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProleisObject } from 'proleis-rest-client';

@Component({
  selector: 'p4m-list-info-card',
  templateUrl: 'list.info.card.component.html',
})
export class ListInfoCardComponent implements OnInit {
  @Input()
  listItems: ProleisObject[];

  @Input()
  cardTitle: string;

  @Input()
  routerLink: string;

  @Input()
  displayProperties: string[];

  @Input()
  icon: string;

  search = false;

  icons: Map<ProleisObject, string> = new Map<ProleisObject, string>();

  sortMode = 1;

  @Output()
  onRequestIcon: EventEmitter<ProleisObject> =
    new EventEmitter<ProleisObject>();

  displayedListItems: ProleisObject[];
  filteredListItems: ProleisObject[];

  ngOnInit() {
    this.displayedListItems = this.listItems.slice(0, 5);
    this.filteredListItems = this.listItems;
  }

  addIcon(item, icon) {
    this.icons.set(item, icon);
  }

  getIcon(item: ProleisObject) {
    console.log('getting icon...', item);
    if (this.icon) {
      return this.icon;
    } else {
      this.onRequestIcon.emit(item);
      return this.icons.get(item);
    }
  }

  onPagination(event) {
    const from = event.pageIndex * event.pageSize;
    const to = from + event.pageSize;
    this.displayedListItems = this.filteredListItems.slice(from, to);
  }

  filterItems(event) {
    this.displayedListItems = this.displayedListItems.filter((item) =>
      item.NAME.toUpperCase().includes(event.target.value.toUpperCase()),
    );
    this.displayedListItems = this.displayedListItems.slice(0, 5);
  }

  sortItems() {
    this.displayedListItems = this.displayedListItems.sort((a, b) => {
      if (a.NAME < b.NAME) {
        return -1 * this.sortMode;
      } else if (a.NAME > b.NAME) {
        return this.sortMode;
      } else {
        return 0;
      }
    });
    this.sortMode = this.sortMode * -1;
  }

  updateDisplayedItems() {
    this.displayedListItems = this.displayedListItems.slice();
  }

  toggleSearch() {
    this.search = !this.search;
  }

  onSearch(event) {
    this.filteredListItems = this.listItems.filter(
      (a) =>
        a.NAME.toUpperCase().includes(event.srcElement.value.toUpperCase()) ||
        a.DESCRIPTION.toUpperCase().includes(
          event.srcElement.value.toUpperCase(),
        ),
    );
    this.displayedListItems = this.filteredListItems.slice(0, 5);
  }
}
